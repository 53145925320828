import React, { Component } from 'react';
import PropTypes from 'prop-types';

class MissingShopProductCard extends Component {
  constructor() {
    super();
    this.state = ({
    });
  }

  render() {
    const {
      missingShop, className,
      toggleAddProductSuggestionModal,
    } = this.props;

    if (!missingShop) return null;

    return (
      <div
        className={`d-flex ${className}`}
        style={{ minHeight: '320px' }}
      >
        <div
          className="d-flex flex-column justify-content-between
            shadow-sm rounded-3 align-items-center bg-white position-relative"
          // style={{ maxHeight: '320px' }}
        >
          <div
            className="d-flex pt-2 pe-1 col-11"
            style={{ height: '55px' }}
          >
            <div className="col-5 text-center d-flex align-items-center">
              <img
                src={missingShop?.image}
                alt={missingShop?.name}
                title={missingShop?.name}
                className="col-12"
              />
            </div>
          </div>
          <div className="col-6 p-1">
            <div
              className="border rounded-3 d-flex justify-content-center align-items-center h-100 fs-3 hover-light"
              role="button"
              onClick={() => toggleAddProductSuggestionModal({ missingShop })}
              tabIndex={0}
            >
              +
            </div>
          </div>
          <div
            className="d-flex flex-column align-items-center mt-1"
            style={{ maxHeight: '3.3em', overflow: 'hidden' }}
          >
            <div
              className="text-center font-montserrat px-1 text-break product-name-link fs-6 fw-bold"
            >
              Pievieno produktu salīdzināšanai!
            </div>
          </div>
        </div>
      </div>
    );
  }
}

MissingShopProductCard.defaultProps = {
  className: '',
};

MissingShopProductCard.propTypes = {
  missingShop: PropTypes.object.isRequired,
  className: PropTypes.string,
  toggleAddProductSuggestionModal: PropTypes.func.isRequired,
};

export default MissingShopProductCard;
