import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Floaty extends Component {
  constructor() {
    super();
    this.state = {
      isClosed: false,
    };
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      this.handleClose();
    }, 2500);
  }

  handleClose() {
    this.setState({ isClosed: true });
    const { onClose } = this.props;
    setTimeout(() => {
      onClose();
    }, 500);
  }

  render() {
    const { isClosed } = this.state;
    const { message, status } = this.props;
    return (
      <>
        <div
          className={`position-fixed end-0 bottom-0 me-5
          border-top border-start border-end
          mb-5 ${status === 'success' ? 'border-bottom-succes' : 'border-bottom-succes'}
          py-1 px-2 d-md-flex d-none align-items-center
          justify-content-between rounded-2 fadeIn text-dark-grey
          glass-container rounded-1
        ${isClosed ? 'fadeOut' : ''}
        `}
          style={{ zIndex: 2, minWidth: 20 }}
          tabIndex="-1"
        >
          <span className="me-2 small fw-bold">{message}</span>
          <button
            type="button"
            className="btn-close btn-sm small shadow-none"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={this.handleClose}
          />
        </div>
        <div
          className={`position-fixed end-0 mx-2 mt-5 fadeIn ${isClosed ? 'fadeOut' : ''}`}
          style={{ zIndex: 2 }}
          tabIndex="-1"
        >
          <div className="mt-5">
            <div className="pt-4">
              <div className={`${status === 'success' ? 'border-bottom-succes' : 'border-bottom-succes'}
              py-1 px-2 d-md-none d-flex align-items-center
              justify-content-between text-dark-grey
              border-top border-start border-end
              glass-container`}
              >
                <span className="small fw-bold me-2">{message}</span>
                <button
                  type="button"
                  className="btn-close btn-sm shadow-none"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={this.handleClose}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

Floaty.defaultProps = {
  status: 'success',
};

Floaty.propTypes = {
  message: PropTypes.string.isRequired,
  status: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default Floaty;
