import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ProductSort extends Component {
  constructor(props) {
    super(props);
    const { sort } = this.props;
    this.state = {
      sort,
      data: [
        {
          title: 'Populārākie',
          name: 'searchCount',
          value: 1,
        },
        {
          title: 'Lētākie',
          name: 'shops.lastPrice',
          value: 1,
        },
        {
          title: 'Dārgākie',
          name: 'shops.lastPrice',
          value: -1,
        },
        {
          title: 'No A līdz Z',
          name: 'name',
          value: 1,
        },
        {
          title: 'No Z līdz A',
          name: 'name',
          value: -1,
        },
      ],
    };
  }

  render() {
    const {
      data, sort,
    } = this.state;
    const {
      renderKey, className, onChange, parentStateKey,
    } = this.props;
    return (
      <div
        className={`${className}`}
      >
        {(data && data.length) ? data.map((item) => (
          <div
            className="d-flex p-2 align-items-center"
            key={`${item.name}${item.value}`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 24 24"
              color={`${sort[item.name] === item.value ? '#5d0018' : 'transparent'}`}
              className="p-1 border rounded"
              onClick={() => {
                if (sort[item.name] === item.value) {
                  delete sort[item.name];
                } else {
                  sort[item.name] = item.value;
                }
                this.setState({
                  sort,
                });
                onChange({ target: { name: parentStateKey, value: sort } });
              }}
              role="button"
            >
              <path
                d="M0 12.116l2.053-1.897c2.401 1.162 3.924 2.045 6.622 3.969 5.073-5.757 8.426-8.678 14.657-12.555l.668 1.536c-5.139 4.484-8.902 9.479-14.321 19.198-3.343-3.936-5.574-6.446-9.679-10.251z"
                fill="currentcolor"
              />
            </svg>
            <div
              role="button"
              onClick={() => {
                if (sort[item.name] === item.value) {
                  delete sort[item.name];
                } else {
                  sort[item.name] = item.value;
                }
                this.setState({
                  sort,
                });
                onChange({ target: { name: parentStateKey, value: sort } });
              }}
              tabIndex={0}
              className="ms-1 px-3 bg-light w-100 text-center rounded p-1 hover-light"
            >
              <span>
                {item[renderKey]}
              </span>
            </div>
          </div>
        )) : null}
      </div>
    );
  }
}

ProductSort.defaultProps = {
  className: '',
  sort: {},
};

ProductSort.propTypes = {
  renderKey: PropTypes.string.isRequired,
  parentStateKey: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  sort: PropTypes.object,
  className: PropTypes.string,
};

export default ProductSort;
