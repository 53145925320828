import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ListsFilter extends Component {
  constructor(props) {
    super(props);
    const { selectedItem } = this.props;
    this.state = {
      selectedItem,
    };

    this.onChange = this.onChange.bind(this);
    this.updateState = this.updateState.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { selectedItem } = this.props;
    const { selectedItem: prevSelectedItem } = prevProps;
    if (selectedItem !== prevSelectedItem) {
      this.updateState();
    }
  }

  onChange(e) {
    const { value } = e;
    const {
      onChange,
    } = this.props;

    this.setState({
      selectedItem: value,
    });
    onChange({ value });
  }

  updateState() {
    const { selectedItem } = this.props;
    this.setState({
      selectedItem,
    });
  }

  render() {
    const {
      selectedItem,
      newListName,
    } = this.state;

    const {
      renderKey, className, data,
      addNewList, onNewListSubmit,
      loading, removeNewList, listsPerUserExceeded,
    } = this.props;

    // čekot vai ir uzspiest uz newList,
    // lai nevarētu spiest vairākas reizes, kamēr nav pievienots jaunais grozs
    const isNewList = !!data.newList;
    return (
      <div
        className={`${className}`}
      >
        {loading ? (
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <form
            onSubmit={(e) => onNewListSubmit({ e, name: newListName })}
          >
            {listsPerUserExceeded ? null : (
              <div className="text-end">
                {isNewList ? (
                  <button
                    className="shadow-none bg-white text-dark border-0"
                    type="submit"
                    tabIndex={0}
                    // disabled={isNewList}
                    label="Pievienot sarakstu"
                  >
                    <i
                      className="fa fa-check"
                    />
                  </button>
                ) : null}
                {!isNewList
                  ? (
                    <button
                      className="shadow-none bg-white text-dark border-0"
                      type="button"
                      tabIndex={0}
                      onClick={addNewList}
                      label="Pievienot sarakstu"
                    >
                      <i
                        className="fa fa-plus"
                      />
                    </button>
                  )
                  : null}

              </div>
            )}

            {data ? Object.keys(data).map((key) => {
              const item = data[key];
              if (!item[renderKey]) {
                return (
                  <div
                    className="d-flex p-2 align-items-center"
                    key={item._id}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="28"
                      viewBox="0 0 24 24"
                      color={`${selectedItem === item?._id ? '#5d0018' : 'transparent'}`}
                      className="p-1 border rounded"
                      role="button"
                    >
                      <path
                        d="M0 12.116l2.053-1.897c2.401 1.162 3.924 2.045 6.622 3.969 5.073-5.757 8.426-8.678 14.657-12.555l.668 1.536c-5.139 4.484-8.902 9.479-14.321 19.198-3.343-3.936-5.574-6.446-9.679-10.251z"
                        fill="currentcolor"
                      />
                    </svg>
                    <div
                      className="ms-1 bg-light w-100 rounded p-1 hover-light"
                    >
                      <input
                        type="text"
                        autoFocus
                        onChange={({ target }) => this.setState({ newListName: target.value })}
                        className="border-0 bg-transparent text-center col-12"
                        required
                        maxLength={26}
                      />
                    </div>
                    <button
                      type="button"
                      className="btn-close"
                      aria-label="Close"
                      onClick={removeNewList}
                    />
                  </div>
                );
              }
              return (
                <div
                  className="d-flex p-2 align-items-center"
                  key={item._id}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 24 24"
                    color={`${selectedItem === item?._id ? '#5d0018' : 'transparent'}`}
                    className="p-1 border rounded"
                    onClick={() => this.onChange({
                      name: item[renderKey],
                      value: item._id,
                    })}
                    role="button"
                  >
                    <path
                      d="M0 12.116l2.053-1.897c2.401 1.162 3.924 2.045 6.622 3.969 5.073-5.757 8.426-8.678 14.657-12.555l.668 1.536c-5.139 4.484-8.902 9.479-14.321 19.198-3.343-3.936-5.574-6.446-9.679-10.251z"
                      fill="currentcolor"
                    />
                  </svg>
                  <div
                    role="button"
                    onClick={() => this.onChange({
                      name: item[renderKey],
                      value: item._id,
                    })}
                    tabIndex={0}
                    className="ms-1 bg-light w-100 text-center rounded p-1 hover-light"
                  >
                    <span>
                      {item[renderKey]}
                    </span>
                  </div>
                </div>
              );
            }) : null}
          </form>
        )}
      </div>
    );
  }
}

ListsFilter.defaultProps = {
  className: '',
  selectedItem: null,
  listsPerUserExceeded: false,
};

ListsFilter.propTypes = {
  data: PropTypes.shape().isRequired,
  renderKey: PropTypes.string.isRequired,
  selectedItem: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  addNewList: PropTypes.func.isRequired,
  removeNewList: PropTypes.func.isRequired,
  onNewListSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  listsPerUserExceeded: PropTypes.bool,
};

export default ListsFilter;
