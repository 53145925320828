import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Message extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const {
      // title,
      message, status, className,
    } = this.props;
    if (!message) return null;

    return (
      <div className={className}>
        <div
          className={`alert fade show position-relative ${status === 'success' ? 'alert-success' : 'alert-danger'}`}
          role="alert"
        >
          <span>
            {/* <strong>{title}</strong> */}
            {/* <br /> */}
            {message}
          </span>
        </div>
      </div>
    );
  }
}

Message.defaultProps = {
  // title: 'Paziņojums',
  status: 'success',
  message: '',
  className: '',
};

Message.propTypes = {
  // title: PropTypes.string,
  message: PropTypes.string,
  status: PropTypes.string,
  className: PropTypes.string,
};

export default Message;
