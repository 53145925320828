import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import ProductCard from '../../components/productCard';
import CategoryFilter from '../../components/categoryFilter';
import ShopFilter from '../../components/shopFilter';
import ProductSort from '../../components/productSort';
import Pagination from '../../components/pagination';
import Alert from '../../components/alert';
import CenteredSpinner from '../../components/centeredSpinner';
import Floaty from '../../popUps/floaty';

class SingleCategory extends Component {
  constructor(props) {
    super(props);
    const { match } = this.props;
    const { params } = match;
    const { categorySlug } = params;
    this.state = {
      loading: true,
      category: {},
      products: [],
      ads: [],
      categories: [],
      categoryLoading: false,
      shops: [],
      categorySlug,
      timeout: null,
      lists: {},
      qtyLoading: false,
      notificationMsg: '',
      notificationStatus: null,
    };
    this.searchController = new AbortController();

    this.getCategory = this.getCategory.bind(this);
    this.getAds = this.getAds.bind(this);
    this.onChange = this.onChange.bind(this);
    this.getCategories = this.getCategories.bind(this);
    this.getShops = this.getShops.bind(this);
    this.getLists = this.getLists.bind(this);
    this.handleProductQtyChanges = this.handleProductQtyChanges.bind(this);

    this.fetchListsController = new AbortController();
  }

  componentDidMount() {
    this.getLists();
    this.getShops();
    this.getAds();
    this.getCategory({});
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
  }

  componentDidUpdate() {
    const { match } = this.props;
    const { params } = match;
    const { categorySlug } = this.state;

    if (categorySlug !== params?.categorySlug
       && categorySlug
       && params?.categorySlug) {
      this.getCategory({ update: true });
    }
  }

  componentWillUnmount() {
    this.setState({
      loading: false,
    });
    this.searchController.abort();
  }

  handleProductQtyChanges({ qty, baseProductId, listId }) {
    this.setState({
      qtyLoading: true,
    });
    fetch('/api/lists/qty', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        qty,
        baseProductId,
        listId,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        const {
          status, data,
        } = res;
        const { lists } = data;
        if (status === 'success') {
          this.setState({
            lists,
            qtyLoading: false,
          });
        } else {
          this.setState({
            notificationMsg: 'Neizdevās saglabāt izmaiņas',
            notificationStatus: 'error',
            qtyLoading: false,
          });
        }
      }).catch((ex) => {
        this.setState({
          notificationMsg: 'Neizdevās saglabāt izmaiņas',
          notificationStatus: 'error',
          qtyLoading: false,
        });
        console.error('aaa', ex);
      });
  }

  onChange(e) {
    const { inputType, target } = e;
    const { name, value } = target;
    const { category } = this.state;

    if (this.searchController) {
      this.searchController.abort();
    }

    // if (name === 'page') {
    //   window.scrollTo({
    //     top: 0,
    //     behavior: 'instant',
    //   });
    // }

    this.setState({
      [name]: value,
    });

    const sessionFilter = sessionStorage.getItem(category?.slug);
    const tempFilter = JSON.parse(sessionFilter) || {};
    if (name === 'categoryFilter') {
      tempFilter.categories = value;
      tempFilter.page = 1;
    }

    if (name === 'sort') {
      tempFilter.sort = value;
    }

    if (name === 'shopFilter') {
      localStorage.setItem('shops', JSON.stringify(value));
      tempFilter.page = 1;
    }

    if (name === 'page') {
      tempFilter.page = value;
    }
    sessionStorage.setItem(category?.slug, JSON.stringify(tempFilter));
    this.getCategory({ wait: inputType === 'inputField' });
  }

  getCategory({ update, wait }) {
    this.searchController = new AbortController();
    if (update) {
      this.setState({
        loading: true,
      });
    }

    let { timeout } = this.state;
    if (wait) {
      clearTimeout(timeout);
    }

    const { match } = this.props;
    const { params } = match;
    const { categorySlug } = params;
    this.setState({
      categorySlug,
    });
    const filter = JSON.parse(sessionStorage.getItem(categorySlug)) || {};

    const {
      categories, sort, page,
    } = filter;

    const shops = JSON.parse(localStorage.getItem('shops')) || [];

    timeout = setTimeout(() => {
      if (window.screen.width > 991) {
        window.scrollTo({
          top: 0,
          behavior: 'instant',
        });
      }
      this.setState({
        categoryLoading: true,
      });
      fetch(`/api/categories/single?slug=${categorySlug}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          categories,
          selectedShops: shops,
          sort,
          page,
        }),
        signal: this.searchController.signal,
      })
        .then((res) => res.json())
        .then((res) => {
          const {
            status, category, products, message,
            pageCount,
          } = res.data;
          if (status) {
            this.setState({
              category,
              products,
              pageCount,
              // loading: false,
            });
            document.title = category?.name;
            this.getCategories();
          } else {
            this.setState({
              loading: false,
              categoryLoading: false,
              message: 'Atnāc vēlāk',
              // message,
            });
            console.error(message);
          }
        }).catch((ex) => {
          if (!ex.name || ex.name !== 'AbortError') {
            console.log(ex);
            this.setState({
              loading: false,
              categoryLoading: false,
              message: 'Atnāc vēlāk',
            });
          }
        });
    }, wait ? 800 : 0);
    this.setState({ timeout });
  }

  getCategories() {
    const { category } = this.state;
    fetch(`/api/categories?middle=${category._id}`)
      .then((res) => res.json())
      .then((res) => {
        const { categories, status } = res;
        if (status) {
          this.setState({
            categories,
            categoryLoading: false,
            loading: false,
          });
        } else {
          this.setState({
            categoryLoading: false,
            loading: false,
          });
        }
      }).catch((ex) => {
        console.error(ex);
        this.setState({
          loading: false,
          message: 'Atnāc vēlāk',
        });
      });
  }

  getShops() {
    fetch('/api/shops')
      .then((res) => res.json())
      .then((res) => {
        const { shops, status } = res;
        if (status) {
          this.setState({
            shops,
            // loading: false,
          });
        }
      }).catch((ex) => {
        console.error(ex);
        this.setState({
          loading: false,
          message: 'Atnāc vēlāk',
        });
      });
  }

  getAds() {
    fetch('/api/ads')
      .then((res) => res.json())
      .then((res) => {
        const { ads, status } = res;
        if (status) {
          this.setState({
            ads,
            // loading: false,
          });
        }
      }).catch((ex) => {
        console.error(ex);
        this.setState({
          loading: false,
          message: 'Atnāc vēlāk',
        });
      });
  }

  getLists() {
    fetch('/api/lists/product-page', {
      signal: this.fetchListsController.signal,
    })
      .then((res) => res.json())
      .then((res) => {
        const {
          status, message, data,
        } = res;
        if (status === 'success') {
          this.setState({
            lists: data,
          });
        } else {
          console.log(message);
        }
      }).catch((ex) => {
        if (!ex.name || ex.name !== 'AbortError') {
          console.log(ex);
        }
      });
  }

  render() {
    const {
      loading, category, products, ads, categories,
      message, categoryLoading, pageCount, shops,
      lists, qtyLoading, notificationMsg, notificationStatus,
    } = this.state;

    const filter = JSON.parse(sessionStorage.getItem(category.slug));
    const selectedCategories = filter?.categories || [];
    const selectedPage = filter?.page || 1;
    const selectedShops = JSON.parse(localStorage.getItem('shops')) || [];
    const sort = filter?.sort || {};

    if (loading) return <CenteredSpinner />;

    return (
      <>
        {notificationMsg
          ? (
            <Floaty
              status={notificationStatus}
              message={notificationMsg}
              onClose={() => this.setState({ notificationMsg: '' })}
            />
          )
          : null}
        {(message || !products) ? (
          <Alert />
        ) : (
          <article className="container-fluid mt-3 mb-5 me-0 ms-0 d-flex justify-content-center flex-lg-row flex-column align-items-lg-start align-items-center">
            <Helmet>
              <title>{category.name}</title>
              <meta name="keywords" content={category.tags} />
              <meta name="description" content={`Meklē "${category.name}" un salīdzini cenas Latvijas veikalos`} />
              <link rel="canonical" href={`/category/${category.slug}`} />
            </Helmet>
            <div className="d-lg-block d-none mt-xxl-5">
              <div className="col-12 border bg-white p-1 mt-5">
                <CategoryFilter
                  data={categories}
                  renderKey="name"
                  parentStateKey="categoryFilter"
                  onChange={this.onChange}
                  selectedItems={selectedCategories}
                  multipleSelect
                />
              </div>
            </div>
            <div className="accordion d-lg-none container" id="accordionBasic">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className={`accordion-button shadow-none bg-white text-dark collapsed ${selectedCategories?.length ? 'fw-bold' : ''}`}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    Kategorijas
                  </button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionBasic">
                  <div className="accordion-body">
                    <div className="col-lg-3 justify-content-end d-flex">
                      <div className="col-xxl-6 col-lg-8 col-12 p-1">
                        <CategoryFilter
                          data={categories}
                          renderKey="name"
                          parentStateKey="categoryFilter"
                          onChange={this.onChange}
                          selectedItems={selectedCategories}
                          multipleSelect
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion d-lg-none container mt-2" id="accordionBasic">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className={`accordion-button shadow-none bg-white text-dark collapsed ${selectedShops?.length ? 'fw-bold' : ''}`}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Veikali
                  </button>
                </h2>
                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionBasic">
                  <div className="accordion-body">
                    <div className="col-lg-3 justify-content-end d-flex">
                      <div className="col-xxl-6 col-lg-8 col-12 p-1">
                        <ShopFilter
                          data={shops}
                          renderKey="image"
                          parentStateKey="shopFilter"
                          selectedItems={selectedShops}
                          onChange={this.onChange}
                          multipleSelect
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion d-lg-none container mt-2" id="accordionBasic">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button shadow-none bg-white text-dark collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Kārtot
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionBasic"
                >
                  <div className="accordion-body">
                    <div className="col-lg-3 justify-content-end d-flex">
                      <div className="col-xxl-6 col-lg-8 col-12 p-1">
                        <ProductSort
                          renderKey="title"
                          parentStateKey="sort"
                          onChange={this.onChange}
                          sort={sort}
                          multipleSelect
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 container mx-4 mt-lg-0 mt-4">
              <div>
                <div className="d-flex pb-1 border-bottom align-items-end">
                  {category?.image ? (
                    <img
                      className="col-2 col-md-1 p-5 pt-0 pe-2 pb-2 ps-0"
                      src={category.image}
                      alt={category.name}
                      title={category.name}
                    />
                  ) : null}
                  <h2>
                    {category.name}
                  </h2>
                </div>
                <div className="row mt-4 row-cols-xxxl-5 row-cols-xxl-4 row-cols-lg-3 row-cols-md-4 row-cols-sm-3 row-cols-2 pe-lg-0 ps-lg-0 px-sm-3 px-1 pb-3">
                  {!categoryLoading ? products.map(
                    (product) => (
                      <ProductCard
                        key={product._id}
                        product={product}
                        hideShop
                        redirect
                        showPrice={!!selectedShops?.length}
                        allowAddToList
                        lists={lists}
                        qtyLoading={qtyLoading}
                        handleProductChanges={this.handleProductQtyChanges}
                      />
                    ),
                  ) : (
                    <div className="col-12 my-5 position-relative d-flex justify-content-center">
                      <CenteredSpinner />
                    </div>
                  )}
                  {!products?.length && !categoryLoading
                    ? <h4 className="col-12">Neviens produkts neatbilst kritērijiem</h4> : null}
                </div>
                {(pageCount) ? (
                  <Pagination
                    pageCount={pageCount}
                    handlePagination={this.onChange}
                    selectedPage={selectedPage}
                  />
                ) : null}
              </div>
            </div>
            <div className="d-lg-block d-none mt-xxl-5">
              <div className="col-12 d-flex flex-column border bg-white mt-5">
                <ProductSort
                  renderKey="title"
                  parentStateKey="sort"
                  onChange={this.onChange}
                  sort={sort}
                  multipleSelect
                />
              </div>
              <div className="col-12 d-flex flex-column border bg-white mt-2">
                <ShopFilter
                  data={shops}
                  renderKey="image"
                  parentStateKey="shopFilter"
                  onChange={this.onChange}
                  selectedItems={selectedShops}
                  multipleSelect
                />
              </div>
            </div>
            {ads && ads.map((ad) => {
              if (ad.categoryPage) {
                return (
                  <div
                    className="container d-flex justify-content-center mt-5"
                    key={ad._id}
                  >
                    <NavLink
                      to={ad?.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={ad.image} alt="banner" title="banner" />
                    </NavLink>
                  </div>
                );
              }
              return null;
            })}
          </article>
        )}
      </>
    );
  }
}

SingleCategory.propTypes = {
  match: PropTypes.object.isRequired,
};

export default SingleCategory;
