import React, { Component } from 'react';
import PropTypes from 'prop-types';

class AddProductToList extends Component {
  constructor(props) {
    super(props);

    this.state = ({
      addToListButton: React.createRef(),
    });
    this.changeQty = this.changeQty.bind(this);
  }

  componentDidUpate(prevProps) {
    const { list } = this.props;
    const { list: prevList } = prevProps;
    if (prevList !== list) {
      this.changeQty({ increment: 0 });
    }
  }

  async changeQty({ increment }) {
    const { handleProductChanges, baseProduct, list } = this.props;

    const { productsQtyPerList } = list;
    const qtyInList = productsQtyPerList?.[baseProduct._id] || 0;
    const newQty = qtyInList + increment;

    handleProductChanges({ listId: list._id, baseProductId: baseProduct._id, qty: newQty });
  }

  render() {
    const { addToListButton } = this.state;
    const {
      className, list, baseProduct, loading,
    } = this.props;

    // šadam gadijumam, nebūtu jābūt
    if (!list) return null;

    const { productsPerListLimitExceeded, productsQtyPerList } = list;

    const qtyInList = productsQtyPerList?.[baseProduct._id] || 0;
    const listIsFull = productsPerListLimitExceeded && !qtyInList;

    return (
      <div
        className={`${className || ''} me-1 glass-container`}
        onClick={(e) => {
          e.preventDefault();
          if (listIsFull || loading) return;

          if (!qtyInList) {
            this.changeQty({ increment: 1 });
          }
        }}
        role="button"
        tabIndex={0}
        style={{ height: 30 }}
        ref={addToListButton}
      >
        <div
          className={`d-flex border px-1 ${qtyInList ? 'd-none' : null} ${listIsFull ? 'text-grey' : ''} rounded-3`}
          title={listIsFull ? 'saraksts ir pilns' : 'pievienot sarakstam'}
        >
          <span
            className="fs-3 lh-1"
          >
            +
          </span>
        </div>
        <div>
          {qtyInList ? (
            <div
              className="d-flex align-items-center border rounded-3"
            >
              <div
                label="izņemt no saraksta"
                role="button"
                tabIndex={-1}
                onClick={(e) => {
                  e.preventDefault();
                  if (listIsFull || loading) return;

                  // const qtyChanges = { [list._id]: { [listProductId]: qtyInList - 1 } };
                  this.changeQty({ increment: -1 });
                  // this.changeQty({ qtyChanges });
                }}
                className={`px-1 ${loading ? 'text-grey' : ''}`}
              >
                <span
                  className="fs-3 lh-1"
                >
                  -
                </span>
              </div>
              <span
                className="fw-bold fs-5 text-center"
                style={{ width: 30 }}
              >
                {qtyInList}
              </span>
              <div
                role="button"
                tabIndex={0}
                label="pievienot sarakstam"
                onClick={(e) => {
                  e.preventDefault();
                  if (listIsFull || loading) return;

                  this.changeQty({ increment: 1 });
                }}
                className={`px-1 ${loading ? 'text-grey' : ''}`}
              >
                <span
                  className="fs-3 lh-1"
                >
                  +
                </span>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

AddProductToList.defaultProps = {
  handleProductChanges: () => {},
  className: null,
  loading: false,
};

AddProductToList.propTypes = {
  list: PropTypes.object.isRequired,
  className: PropTypes.string,
  baseProduct: PropTypes.object.isRequired,
  handleProductChanges: PropTypes.func,
  loading: PropTypes.bool,
  // addProductToList: PropTypes.func.isRequired,
//   className: PropTypes.string,
//   toggleAddProductSuggestionModal: PropTypes.func.isRequired,
};

export default AddProductToList;
