import React, { Component } from 'react';
import {
  Switch, Route,
  // useLocation,
  NavLink,
} from 'react-router-dom';

import CookieConsent from 'react-cookie-consent';
import Header from './layout/header';
import Footer from './layout/footer';
import Home from './views/home';
import SingleProduct from './views/singleProduct';
import SingleCategory from './views/singleCategory';
import './index.css';
import SearchResults from './views/searchResults';
import ShopsMap from './views/shopsMap';
import Cepumi from './layout/cookieConsent';
import NotFound from './layout/404';
import Lists from './views/lists/index';
import Register from './views/auth/register';
import Login from './views/auth/login';
import UserActivation from './views/auth/userActivation';
import UserProfile from './views/userProfile';
import EmailSent from './views/auth/emailSent';

import withAuth from './middleware/withAuth';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      location: null,
    };

    this.setLocation = this.setLocation.bind(this);
  }

  componentDidMount() {
    this.setLocation();
  }

  setLocation() {
    // const location = useLocation();
    const fullURL = window.location.href;

    // Split the URL by '/' to get an array of path segments
    const pathSegments = fullURL.split('/');

    // Get the last segment which should represent the user location
    const location = pathSegments[pathSegments.length - 1];

    this.setState({
      location,
    });
  }

  render() {
    const { location } = this.state;

    const WrappedHeader = withAuth(Header);
    const WrappedHome = withAuth(Home);
    const WrappedSingleProduct = withAuth(SingleProduct);
    const WrappedSingleCategory = withAuth(SingleCategory);
    const WrappedSearchResults = withAuth(SearchResults);
    const WrappedShopsMap = withAuth(ShopsMap);
    const WrappedCepumi = withAuth(Cepumi);
    const WrappedLists = withAuth(Lists);
    const WrappedUserActivation = withAuth(UserActivation);
    const WrappedRegister = withAuth(Register);
    const WrappedLogin = withAuth(Login);
    const WrappedNotFound = withAuth(NotFound);
    const WrappedUserProfile = withAuth(UserProfile);
    const WrappedEmailSent = withAuth(EmailSent);

    return (
      <div
        className="h-100 d-flex flex-column justify-content-between overflow-scroll"
      >
        <WrappedHeader location={location} />
        <Switch>
          <Route path="/" exact component={WrappedHome} />
          <Route path="/product/:productId" exact component={WrappedSingleProduct} />
          <Route path="/category/:categorySlug" exact component={WrappedSingleCategory} />
          <Route path="/products" exact component={WrappedSearchResults} />
          <Route path="/shops" exact component={WrappedShopsMap} />
          <Route path="/cepumi" exact component={WrappedCepumi} />
          <Route path="/lists" exact component={WrappedLists} />
          <Route path="/activate" exact component={WrappedUserActivation} />
          <Route path="/register" exact component={WrappedRegister} />
          <Route path="/login" exact component={WrappedLogin} />
          <Route path="/profile" exact component={WrappedUserProfile} />
          <Route path="/email-sent" exact component={WrappedEmailSent} />

          <Route path="*" exact component={WrappedNotFound} />
        </Switch>
        <CookieConsent
          buttonText="Sapratu"
          buttonStyle={{
            background: 'linear-gradient(90deg, rgba(193,133,44,1) 3%, rgba(181, 150, 107,1) 100%)',
            borderRadius: 4,
            color: '#332d26',
            fontWeight: 'bold',
            paddingLeft: 20,
            paddingRight: 20,
          }}
          style={{
            background: 'linear-gradient(90deg, rgba(254,211,216,1) 13%, rgba(252,236,223,1) 52%, rgba(246,238,228,1) 100%)',
            color: '#b5326d',
            fontWeight: 'bold',
          }}
          expires={365}
        >
          Šī vietne izmanto sīkdatnes, lai uzlabotu lietošanas pieredzi un optimizētu tās darbību.
          {' '}
          <NavLink to="/cepumi" style={{ color: '#5195b0' }}>Uzzini vairāk šeit</NavLink>
        </CookieConsent>
        <Footer location={location} />
      </div>

    );
  }
}

export default App;
