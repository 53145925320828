import React, { Component } from 'react';
import PropTypes from 'prop-types';

class StarRating extends Component {
  constructor() {
    super();
    this.state = {
    };

    this.handleStarClick = this.handleStarClick.bind(this);
  }

  handleStarClick(rating) {
    const { handleRating } = this.props;
    handleRating(rating);
  }

  render() {
    const { rating, ratingCount } = this.props;
    const maxStars = 5;
    // const filledStars = Math.min(Math.max(rating, 0), maxStars);

    const starElements = [];

    for (let i = 0; i < maxStars; i++) {
      let starClass = 'star';
      if (i < Number(rating.toFixed())) {
        starClass += ' filled';
      }
      starElements.push(
        <span
          key={i}
          className={starClass}
          onClick={() => this.handleStarClick(i + 1)}
          role="button"
          tabIndex={0}
        >
          &#9733;

        </span>,
      );
    }

    return (
      <div>
        <div className="star-rating">
          {starElements}
        </div>
        (
        {ratingCount}
        )
      </div>
    );
  }
}

StarRating.defaultProps = {
  rating: 0,
  ratingCount: 0,
};

StarRating.propTypes = {
  rating: PropTypes.number,
  ratingCount: PropTypes.number,
  handleRating: PropTypes.func.isRequired,
};

export default StarRating;
