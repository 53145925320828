import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { FaSearch } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';
// import SearchAllNavlink from '../../../components/autoComplete/components/searchAllNavlink';
import Message from '../../../components/message';

// function truncateName(name) {
//   if (name?.length > 30) {
//     return `${name?.substring(0, 27)}...`;
//   }
//   return name;
// }

class ChangePasswordModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: React.createRef(),
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      isCurrentPasswordValid: false,
      currentPasswordLoading: false,
      message: '',
    };

    this.handleClickOutsideModal = this.handleClickOutsideModal.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.verifyCurrentPassword = this.verifyCurrentPassword.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutsideModal);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutsideModal);
    // this.searchController.abort();
  }

  handleClickOutsideModal(e) {
    const { modal } = this.state;
    const { toggleChangePasswordModal } = this.props;
    if (!modal.current.contains(e.target)) toggleChangePasswordModal({});
  }

  onChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  async onSubmit(e) {
    e.preventDefault();
    const { onSubmit } = this.props;
    const { currentPassword, newPassword, confirmPassword } = this.state;

    if (currentPassword && newPassword && newPassword === confirmPassword) {
      onSubmit({ newPassword, confirmPassword });
    }
  }

  verifyCurrentPassword(e) {
    e.preventDefault();
    const { currentPassword } = this.state;
    this.setState({
      message: null,
      currentPasswordLoading: true,
    });
    fetch('/api/auth/verifyPassword', {
      method: 'POST',
      body: JSON.stringify({ password: currentPassword }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const { status, message } = data;
        if (status === 'success') {
          this.setState({
            isCurrentPasswordValid: true,
            currentPasswordLoading: false,
            message,
            status,
          });
        } else {
          this.setState({
            isCurrentPasswordValid: false,
            currentPasswordLoading: false,
            message,
            status,
          });
        }
      })
      .catch((error) => {
        console.error('Error verifying current password:', error);
        this.setState({
          currentPasswordLoading: false,
          message: 'Neizdevās nomainīt paroli 😔',
        });
      });
  }

  render() {
    const {
      modal,
      currentPassword,
      newPassword,
      confirmPassword,
      isCurrentPasswordValid,
      currentPasswordLoading,
      message,
      loading,
      status,
    } = this.state;
    const {
      toggleChangePasswordModal,
      isOpen,
      //   onSubmit,
      title,
    } = this.props;

    return (

      <>
        {/* backdrop */}
        <div className="position-fixed top-0 start-0 w-100 h-100 bg-dark-transparent z-index-1" />
        <div
          className={`modal ${isOpen ? 'show' : ''}`}
          style={{
            display: isOpen ? 'block' : 'none',
          }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content" ref={modal}>
              <div className="modal-header">
                <h5 className="modal-title">{title}</h5>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={toggleChangePasswordModal}
                />
              </div>
              <div className="modal-body">
                {!isCurrentPasswordValid ? (
                  <form className="mb-3" onSubmit={this.verifyCurrentPassword}>
                    <label htmlFor="currentPassword" className="form-label">
                      Patreizējā parole
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="currentPassword"
                      name="currentPassword"
                      value={currentPassword}
                      onChange={this.onChange}
                      disabled={currentPasswordLoading}
                    />
                    <button
                      type="submit"
                      className="btn btn-primary mt-2"
                    >
                      {currentPasswordLoading ? (
                        <div className="spinner-grow spinner-grow-sm mx-4" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : 'Turpināt'}
                    </button>
                  </form>
                ) : (
                  <form onSubmit={this.onSubmit}>
                    <div className="mb-3">
                      <label htmlFor="newPassword" className="form-label">
                        Jaunā parole
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="newPassword"
                        name="newPassword"
                        value={newPassword}
                        onChange={this.onChange}
                        disabled={loading}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="confirmPassword" className="form-label">
                        Atkārto jauno paroli
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="confirmPassword"
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={this.onChange}
                        disabled={loading}
                      />
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={
                        (!isCurrentPasswordValid
                        || (newPassword !== confirmPassword && (newPassword || confirmPassword))
                        || loading)
                        || (
                          !newPassword && !confirmPassword
                        )
                      }
                    >
                      {loading ? (
                        <div className="spinner-grow spinner-grow-sm mx-4" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        <div>
                          <span>
                            { !isCurrentPasswordValid
                            || (newPassword !== confirmPassword && (newPassword || confirmPassword))
                            || loading ? 'paroles nesakrīt' : null}

                            {!newPassword && !confirmPassword ? 'ievadi paroles' : null}

                            {newPassword === confirmPassword
                            && newPassword
                            && confirmPassword
                            && !loading ? 'mainīt paroli!' : null}

                          </span>

                        </div>
                      )}
                    </button>
                  </form>
                )}
                <Message message={message} status={status} className="px-2" />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

ChangePasswordModal.defaultProps = {
  title: 'Mainīt paroli',
};

ChangePasswordModal.propTypes = {
  toggleChangePasswordModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string,
//   history: PropTypes.shape({
//     push: PropTypes.func.isRequired,
//   }).isRequired,
};
export default withRouter(ChangePasswordModal);
