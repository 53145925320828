import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SuggestionProductTooltip from '../../../components/tooltips/SuggestionProductTooltip';

class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  }

  render() {
    const {
      product: listProduct,
      listId,
      selectedShop,
      toggleAddAltProductModal,
      onQtyChange,
      onMainProductDelete,
      onAltDelete,
    } = this.props;

    const {
      alternatives,
      _id: mainProductId,
      qty,
      // suggestions,
      // product,
    } = listProduct;
    let { product } = listProduct;

    // const selectedShopSuggestion = suggestions
    //   .find((suggestion) => {
    //     const { shops: suggestionShops } = suggestion;

    //     if (suggestionShops.some((each) => String(each.shop) === String(selectedShop._id))) {
    //       return true;
    //     }
    //     return false;
    //   });

    if (!product) return null;
    // if (selectedShopSuggestion) {
    //   product = selectedShopSuggestion;
    // }

    const {
      shops,
      _id: productId,
    } = product;
    const selectedProductShop = shops.find(({ _id }) => _id === selectedShop._id);
    let price;
    const bestProduct = selectedProductShop?.bestProduct;
    // const mainProductBestProduct = bestProduct?._id === productId;
    let shopsBestProduct;
    if (bestProduct) {
      const { shops: bestProductShops } = bestProduct;
      shopsBestProduct = bestProductShops.find(({ shop }) => shop === selectedShop._id);
      price = shopsBestProduct?.lastPrice;
    }
    const isMainProductBestProduct = productId === bestProduct?._id;
    const isSuggestionBestProduct = bestProduct?.isSuggestion;
    const isAlternativeBestProduct = bestProduct?.isAlternative;

    const allowAlternatives = alternatives?.length < 3
     && !isMainProductBestProduct
     && !isSuggestionBestProduct
     && !isAlternativeBestProduct;
    if (isSuggestionBestProduct) {
      product = bestProduct;
    }

    const {
      image, name, isSuggestion,
    } = product;
    return (
      <div className="d-flex bg-white shadow-sm p-2 rounded mb-2 fadeIn justify-content-between position-relative button-on-hover">
        <div
          onClick={() => onMainProductDelete({ listId, mainProductId })}
          role="button"
          className="hover-danger btn"
          tabIndex={-1}
          label="Dzēst"
        >
          <i
            className="fa fa-trash"
            aria-hidden="true"
          />
        </div>
        <div className="d-flex align-items-center justify-content-center col-md-3 col-4 flex-column position-relative">
          <div className="col-12">
            {isSuggestion ? <SuggestionProductTooltip /> : null}
          </div>
          <img
            src={image}
            alt=""
            style={{ maxWidth: 85 }}
            className={`${isMainProductBestProduct || isSuggestionBestProduct ? '' : 'disabled'}`}
          />
          <div className="pt-2">
            <h6 className="text-center small">{name}</h6>
          </div>
        </div>
        {/* WEB */}
        <div className="col-9 d-md-flex align-items-center d-none">
          <div className="col-7 d-flex align-items-center justify-content py-1">
            {alternatives?.length
            && !isSuggestionBestProduct
            && !isMainProductBestProduct ? alternatives.map((alt) => {
                const isAltBestProduct = bestProduct?._id === alt?._id;
                if (alt) {
                  return (
                    <div
                      key={alt?._id}
                      className={`position-relative card-custom zoom col-4 p-1 h-100 ${isAltBestProduct ? 'border rounded-3' : 'disabled'}`}
                      role="button"
                      tabIndex={-1}
                    >
                      <span
                        role="button"
                        tabIndex={0}
                        className="btn-close font-xxs shadow-none position-absolute end-0 card-close fadeIn"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => onAltDelete({
                          listId,
                          mainProductId,
                          altProductId: alt._id,
                        })}
                      />
                      <img
                        src={alt.image}
                        alt={alt.name}
                        className="w-100 p-2"
                      />
                      <span className="fw-bold font-xs">
                        {alt?.name?.length > 20 ? `${alt?.name?.substring(0, 20)}...` : alt?.name}
                      </span>
                    </div>
                  );
                }
                return null;
              }) : null}
            {allowAlternatives ? (
              <div className="col-4 p-1 h-100">
                <div
                  className="border rounded-3 d-flex justify-content-center align-items-center h-100 fs-3 hover-light"
                  role="button"
                  onClick={() => toggleAddAltProductModal({ listProduct })}
                  tabIndex={0}
                >
                  +
                </div>
              </div>
            ) : null}
          </div>
          <div className="col-5 d-flex align-items-center justify-content-center">
            {price ? (
              <div className={`d-flex flex-column ${qty > 1 ? 'align-items-end' : 'align-items-center'}`}>
                <div className="d-flex">
                  <h6>
                    {(price * (qty || 1) || 0).toFixed(2)}
                    €
                  </h6>
                  {qty > 1 ? (
                    <h6 className="ms-2">
                      (
                      {price}
                      €
                      )
                    </h6>
                  ) : null}
                </div>
                <div className="d-flex align-items-center">
                  <button
                    className={`fs-1 rounded-circle d-flex justify-content-center align-items-center pb-2 border-0 ${qty <= 1 ? 'fadeInDanger' : ''}`}
                    style={{ width: 32, height: 32 }}
                    type="button"
                    tabIndex={0}
                    onClick={() => onQtyChange({ listId, mainProductId, qty: qty - 1 })}
                    disabled={qty <= 1}
                  >
                    -
                  </button>
                  <input
                    type="text"
                    value={qty > 0 ? qty : 1}
                    className="shadow-sm rounded-5 mx-2 text-center fw-bold border-0"
                    style={{ maxWidth: 45, height: 32 }}
                    // disabled={qty <= 0}
                    onChange={({ target }) => onQtyChange({
                      listId,
                      mainProductId,
                      qty: target.value > 0 ? target.value : 1,
                    })}
                  />
                  <button
                    className="fs-2 rounded-circle bg-warning d-flex justify-content-center align-items-center border-0"
                    style={{ width: 32, height: 32 }}
                    tabIndex={0}
                    type="button"
                    onClick={() => onQtyChange({ listId, mainProductId, qty: qty + 1 })}
                  >
                    +
                  </button>
                </div>
              </div>
            ) : (
              <>
                <h6 className="text-secondary small ps-3">
                  {alternatives.some((alt) => alt) ? 'neviens no produktiem' : 'produkts'}
                  {' '}
                  nav
                  {' veikalā "'}
                  {selectedShop?.name}
                  {'" '}
                  pievieno alternatīvu
                  <br />
                  <i className="fas fa-arrow-left ms-2" />
                </h6>
              </>
            )}
          </div>
        </div>
        {/* MOBILE */}
        <div className="col-8 d-md-none d-flex flex-column align-items-end justify-content-between">
          <div className="col-12 d-flex align-items-center py-1">
            {alternatives?.length && !isMainProductBestProduct ? alternatives.map((alt) => {
              const isAltBestProduct = bestProduct?._id === alt?._id;
              if (alt) {
                return (
                  <div
                    key={alt?._id}
                    className={`position-relative card-custom col-4 p-1 ${isAltBestProduct ? 'border rounded-3' : 'disabled'}`}
                  >
                    <span
                      role="button"
                      tabIndex={0}
                      className="btn-close font-xxs shadow-none position-absolute end-0 card-close fadeIn"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => onAltDelete({ listId, mainProductId, altProductId: alt._id })}
                    />
                    <img
                      src={alt.image}
                      alt={alt.name}
                      className="w-100 p-2"
                    />
                    <span className="fw-bold font-xs card-title zoom fadeIn">
                      {alt?.name?.length > 20 ? `${alt?.name?.substring(0, 20)}...` : alt?.name}
                    </span>
                  </div>
                );
              }
              return null;
            }) : null}
            {alternatives?.length < 3 && !bestProduct ? (
              <div className="col-4 p-1">
                <div
                  className="border rounded-3 d-flex justify-content-center align-items-center fs-3 hover-light"
                  role="button"
                  onClick={() => toggleAddAltProductModal({ listProduct })}
                  tabIndex={0}
                >
                  +
                </div>
              </div>
            ) : null}
          </div>
          <div className="col-7 d-flex align-items-between justify-content-end me-2">
            {price ? (
              <div className={`d-flex flex-column ${qty > 1 ? 'align-items-end' : 'align-items-center'}`}>
                <div className="d-flex">
                  <h6>
                    {(price * (qty || 1) || 0).toFixed(2)}
                    €
                  </h6>
                  {qty > 1 ? (
                    <h6>
                      (
                      {price}
                      €
                      )
                    </h6>
                  ) : null}
                </div>
                <div className="d-flex align-items-center">
                  <button
                    className={`fs-1 rounded-circle d-flex justify-content-center align-items-center pb-2 border-0 ${qty <= 1 ? 'fadeInDanger' : ''}`}
                    style={{ width: 32, height: 32 }}
                    type="button"
                    tabIndex={0}
                    onClick={() => onQtyChange({ listId, mainProductId, qty: qty - 1 })}
                    disabled={qty <= 1}
                  >
                    -
                  </button>
                  <input
                    type="text"
                    value={qty > 0 ? qty : 1}
                    className="shadow-sm rounded-5 mx-2 text-center fw-bold border-0"
                    style={{ maxWidth: 45, height: 32 }}
                    onChange={({ target }) => onQtyChange({
                      listId,
                      mainProductId,
                      qty: target.value > 0 ? target.value : 1,
                    })}
                  />
                  <button
                    className="fs-2 rounded-circle bg-warning d-flex justify-content-center align-items-center border-0"
                    style={{ width: 32, height: 32 }}
                    tabIndex={0}
                    type="button"
                    onClick={() => onQtyChange({ listId, mainProductId, qty: qty + 1 })}
                  >
                    +
                  </button>
                </div>
              </div>
            ) : (
              <>
                <h6 className="text-secondary small">
                  {alternatives.some((alt) => alt) ? 'neviens no produktiem' : 'produkts'}
                  {' '}
                  nav
                  {' veikalā "'}
                  {selectedShop?.name}
                  {'" '}
                  pievieno alternatīvu
                  <i className="fas fa-arrow-up ms-2" />
                </h6>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Product.propTypes = {
  product: PropTypes.object.isRequired,
  selectedShop: PropTypes.object.isRequired,
  listId: PropTypes.string.isRequired,
  toggleAddAltProductModal: PropTypes.func.isRequired,
  onQtyChange: PropTypes.func.isRequired,
  onMainProductDelete: PropTypes.func.isRequired,
  onAltDelete: PropTypes.func.isRequired,
};

export default Product;
