import React, { Component } from 'react';
import PropTypes from 'prop-types';

class shopFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { shops, onShopSelect, selectedShop } = this.props;
    return (
      <div className="d-sm-none fixed-bottom mb-2 d-flex justify-content-center container">
        <div className="table-responsive d-flex bg-white shadow rounded-3">
          {shops?.length ? shops.map((shop) => (
            <div
              key={Math.random(1000)}
              role="button"
              tabIndex={0}
              onClick={() => onShopSelect(shop)}
              className={`hover-light d-flex justify-content-end flex-column px-1 rounded-3 position-relative ${selectedShop._id === shop._id ? 'bg-darker' : ''}`}
              style={{ maxWidth: 70, minWidth: 60 }}
            >
              <div
              // style={{ height: 40 }}
                className="d-flex align-items-center"
              >
                {shop.missingProducts ? (
                  <h4
                    className="text-danger position-absolute top-0 end-0 pe-1"
                    title={`nav ${shop?.missingProducts > 1 ? 'pieejami' : 'pieejams'} ${shop.missingProducts} ${shop?.missingProducts > 1 ? 'produkti' : 'produkts'}`}
                  >
                    !
                  </h4>
                ) : null}
                <img
                  src={shop.image}
                  alt={shop.name}
                  className="img-fluid pt-1"
                />
              </div>
              <div className="d-flex justify-content-center small mt-1">
                <span>
                  {Number(shop.price)?.toFixed(2)}
                  €
                </span>
              </div>
            </div>
          )) : null}
        </div>
      </div>
    );
  }
}

shopFooter.propTypes = {
  shops: PropTypes.array.isRequired,
  onShopSelect: PropTypes.func.isRequired,
  selectedShop: PropTypes.object.isRequired,
};

export default shopFooter;
