import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Carousel from 'react-elastic-carousel';
import { NavLink } from 'react-router-dom';

class ProductSlider extends Component {
  constructor() {
    super();
    this.state = {
      carouselRef: React.createRef(),
    };
    this.onNextStart = this.onNextStart.bind(this);
    this.onPrevStart = this.onPrevStart.bind(this);
  }

  onNextStart(currentItem, nextItem) {
    const { carouselRef } = this.state;
    if (currentItem.index === nextItem.index) {
      // we hit the last item, go to first item
      carouselRef.current.goTo(0);
    }
  }

  onPrevStart(currentItem, nextItem) {
    const { carouselRef } = this.state;
    const { products } = this.props;
    if (currentItem.index === nextItem.index) {
      // we hit the first item, go to last item
      carouselRef.current.goTo(products.length);
    }
  }

  render() {
    const { carouselRef } = this.state;
    const { products, title } = this.props;

    const breakPoints = [
      { width: 1, itemsToShow: 2 },
      { width: 550, itemsToShow: 3 },
      { width: 768, itemsToShow: 4 },
    ];
    if (!products || !products.length) return null;
    return (
      <div className="container mt-4 mb-4 text-center p-0">
        <h2 className="fw-bold text-dark-grey">{title}</h2>
        <Carousel
          breakPoints={breakPoints}
          className="mt-4"
          onPrevStart={this.onPrevStart}
          ref={carouselRef}
          onNextStart={this.onNextStart}
          disableArrowsOnEnd={false}
        >
          {products && products.map((product) => (
            <NavLink
              to={`/product/${product.slug}`}
              className="d-flex justify-content-between text-decoration-none text-dark p-lg-3 p-md-2 p-2 mb-4"
              key={product._id}
            >
              <div className="d-flex flex-column justify-content-between shadow rounded-5 align-items-center bg-white position-relative px-sm-2 px-1">
                <div className="align-self-end m-2 fw-bold fs-3 col-6 text-end font-petrona">
                  {Number(product.minPrice).toFixed(2)}
                  €
                </div>
                <img src={product.image} className="img-slider w-75 p-2 ps-xxl-3 pe-xxl-3" alt="not found" />
                <div className="text-center fw-bold fs-5 mb-3">
                  {product.name}
                </div>
              </div>
            </NavLink>
          ))}
        </Carousel>
      </div>
    );
  }
}

ProductSlider.propTypes = {
  products: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

export default ProductSlider;
