import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';

class CookieConsent extends Component {
  constructor() {
    super();
    this.state = {
      url: window.location.hostname,
    };
  }

  render() {
    const { url } = this.state;
    return (
      <>
        <Helmet>
          <title>`ProductCave sīkdatņu piekrišanas politika`</title>
          <link rel="canonical" href="/cepumi" />
          <meta name="robots" content="noindex" />
        </Helmet>
        <div className="container px-md-5 px-3 pb-4 pt-4">
          <p className="pe-md-5 ps-md-3 px-3">
            Šī privātuma politika informē par privātuma praksi
            un personas datu apstrādes principiem saistībā ar
            {' '}
            {url}
            {' '}
            mājas lapu un pakalpojumiem. Lai sazinātos saistībā ar datu
            apstrādes jautājumiem, lūdzu rakstiet e-pastu uz
            <a
              href="mailto: productcaveofficial@gmail.com"
              className="text-decoration-none text-dark fw-bold"
            >
              {' '}
              productcaveofficial@gmail.com
            </a>
          </p>

          <p className="px-md-5 px-3">
            Mēs glabājam jūsu personas datus tik ilgi, cik ilgi tie ir
            mums nepieciešami atbilstoši to ieguves
            mērķim un kā to pieļauj vai nosaka normatīvo aktu prasības.
          </p>

          <p className="px-md-5 px-3">
            {' '}
            {url}
            {' '}
            nemitīgi pilnveido Vietni ar mērķi, uzlabot tās
            lietošanu, tāpēc
            {' '}
            {url}
            {' '}
            ir jāzina, kāda
            informācija ir svarīga Vietnes apmeklētājiem, cik bieži viņi
            šo Vietni apmeklē, kādas ierīces un pārlūkprogrammu
            viņi izmanto, no kāda reģiona nāk apmeklētāji, un kādu saturu vislabprātāk lasa.
          </p>

          <p className="px-md-5 px-3">
            {' '}
            {url}
            {' '}
            izmanto sistēmu
            {' '}
            <a
              href="https://analytics.google.com"
              className="text-decoration-none text-dark fw-bold"
            >
              Google Analytics
            </a>
            , kas ļauj analizēt,
            kā apmeklētāji izmanto Vietni.
            {' '}
            {url}
            {' '}
            izmanto savāktos datus savās tiesiskajās interesēs,
            lai uzlabotu izpratni par Vietnes apmeklētāju
            vajadzībām un uzlabotu pieejamību vietnes publiskotajai informācijai.
          </p>
          <p className="px-md-5 px-3">
            Mēs arī vācam datus par apmeklētāju mijiedarbību ar Vietni, piemēram:

            <ul>
              <li>Produktu klikšķi</li>
              <li>Lapu apmeklējumi</li>
              <li>Produktu pievienošana sarakstam</li>
              <li>Produktu noņemšana no saraksta</li>
              <li>Kategoriju klikšķi</li>
              <li>Kategoriju mijiedarbības</li>
            </ul>

            Šie dati tiek izmantoti, lai izanalizētu apmeklētāju uzvedību
            un uzlabotu Vietnes funkcionalitāti un lietotāju pieredzi.

          </p>
          <p className="px-md-5 px-3">
            Serveris, kurā izvietota Vietne, var reģistrēt apmeklētāja nosūtītos
            pieprasījumus (izmantotā ierīce, pārlūkprogramma,
            IP adrese, piekļuves datums un laiks). Šajā punktā minētie dati tiek
            izmantoti tehniskiem nolūkiem: lai nodrošinātu
            Vietnes pienācīgu funkcionēšanu un drošību un izmeklētu iespējamos
            drošības incidentus. Šajā punktā minēto datu vākšanas
            pamats ir
            {' '}
            {url}
            {' '}
            tiesiskā interese nodrošināt Vietnes
            tehnisku pieejamību un integritāti.
          </p>
          {/*
        <p style={{textIndent: 50}}>
          Atsevišķas sīkdatnes tiek izmantotas, lai atlasītu un piemērotu
          apmeklētājam piedāvājamo informāciju un reklāmas, pamatojoties
          uz saturu, ko apmeklētājs aplūkojis agrāk, un tādējādi veidotu
          apmeklētājam Vietnes lietošanu vienkāršu, ērtu un individuāli
          piemērotu. Papildu informāciju par sīkdatnēm, kā arī to dzēšanu
          un pārvaldīšanu var iegūt mājaslapā www.aboutcookies.org.
        </p> */}

          <p className="px-md-5 px-3">
            {' '}
            {url}
            {' '}
            izmanto sīkdatnes, lai vāktu lietotāja IP adreses
            un pārlūkošanas informāciju un ļautu
            {' '}
            {url}
            {' '}
            atcerēties apmeklētāja
            izvēli. Sīkdatnes ļauj Pārzinim sekot līdzi
            {' '}
            {url}
            {' '}
            datu
            plūsmai un lietotāju mijiedarbībai ar Vietni – Pārzinis
            izmanto šos
            datus, lai analizētu apmeklētāju uzvedību un uzlabotu Vietni. Sīkdatņu
            izmantošanas tiesiskais pamats ir Pārziņa leģitīmā
            interese nodrošināt Vietnes funkcionalitāti, pieejamību un integritāti.
          </p>

          <p className="pe-md-5 ps-md-3 px-3">
            Sīkdatnes ir nelielas teksta datnes, ko jūsu apmeklētās mājas lapas
            saglabā jūsu datorā. Tās tiek izmantotas, lai nodrošinātu
            mājas lapas darbību, kā arī lai sniegtu informāciju vietnes īpašniekiem.
          </p>

          <p className="px-md-5 px-3">
            Lai atteiktos no sīkdatņu saņemšanas, jūs varat izmantojot privātās
            pārlūkošanas režīmu, kuru nodrošina lielākā daļa
            pārlūkprogrammu (privāts logs, inkognito logs vai InPrivate logs).
            Jebkādas sīkdatnes, kas tiek izveidotas, darbojoties
            privātās pārlūkošanas režīmā, tiek dzēstas, tiklīdz jūs aizverat visus pārlūka logus.
          </p>

          <p className="px-md-5 px-3">
            Statistikas datiem par
            {' '}
            {url}
            {' '}
            apmeklētājiem var
            piekļūt tikai tie Pārziņa darbinieki, kuri ir atbildīgi par tādu datu analīzi.
          </p>

          <p className="px-md-5 px-3">
            Ja nav noteikts citādi, sīkdatnes tiek glabātas, kamēr tiek
            izpildīta darbība, kādam nolūkam tās vāktas un pēc tam tās tiek dzēstas.
          </p>

          <p className="px-md-5 px-3">
            Ja
            {' '}
            {url}
            {' '}
            Vietnē ir paredzēta foruma vai komentāru
            iespēja, šādā gadījumā Vietnē tiek saglabāta IP adrese, kā arī dati,
            ko norādījis pats apmeklētājs.
            Sīkdatnes, kas satur šos datus, Jūsu ērtībām, lai ikreiz nebūtu
            jāraksta no jauna, var tikt saglabātas vienu gadu.
          </p>

          <p className="px-md-5 px-3">
            {' '}
            {url}
            {' '}
            patur tiesības jebkurā brīdī mainīt šos
            noteikumus.
            {' '}
            {url}
            {' '}
            apmeklētāja pienākums ir patstāvīgi pārbaudīt
            Vietnes saturu, lai iepazītos ar noteikumu izmaiņām
          </p>
        </div>
      </>

    );
  }
}

export default CookieConsent;
