import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ShopFilter extends Component {
  constructor(props) {
    super(props);
    const { selectedItems } = this.props;
    this.state = {
      selectedItems,
      selectedRenderKeys: [],
    };

    this.onChange = this.onChange.bind(this);
  }

  componentWillUnmount() {
    const { selectedItems } = this.state;
    const {
      parentState, onChange, parentStateKey,
    } = this.props;
    if (parentState) {
      const _parentState = parentState;
      // if (selectedItems.length === data.length) {
      selectedItems.forEach((item) => {
        const index = _parentState.indexOf(item);
        if (index !== -1) {
          _parentState.splice(index, 1);
        }
      });
      onChange({ target: { name: parentStateKey, value: [...new Set(_parentState)] } });
    }
    // }
  }

  async onChange(e) {
    const { name, value } = e;
    const {
      onChange, parentStateKey, renderKey, multipleSelect, parentState,
    } = this.props;
    let _parentState = [];
    const { selectedItems, selectedRenderKeys } = this.state;
    if (name === 'allItems') {
      const { data } = this.props;

      let tempSelectedItems = [];
      if (selectedItems?.length === data?.length) {
        await this.setState({
          selectedRenderKeys: [],
          selectedItems: [],
        });
      } else {
        tempSelectedItems = data?.map((item) => item._id);
        const tempSelectedRenderItems = data?.map((item) => item[renderKey]);
        await this.setState({
          selectedItems: tempSelectedItems,
          selectedRenderKeys: tempSelectedRenderItems,
        });
      }
      if (parentState) {
        _parentState = parentState.concat(tempSelectedItems);
        if (selectedItems.length === data.length) {
          selectedItems.forEach((item) => {
            const index = _parentState.indexOf(item);
            if (index !== -1) {
              _parentState.splice(index, 1);
            }
          });
        }
        onChange({ target: { name: parentStateKey, value: [...new Set(_parentState)] } });
      } else {
        onChange({ target: { name: parentStateKey, value: tempSelectedItems } });
      }
    } else if (multipleSelect) {
      const tempSelectedItems = selectedItems;
      const tempSelectedRenderItems = selectedRenderKeys;
      if (parentState) {
        _parentState = parentState;
      }
      if (tempSelectedItems.includes(value)) {
        tempSelectedItems.splice(tempSelectedItems.indexOf(value), 1);
        tempSelectedRenderItems.splice(tempSelectedItems.indexOf(name), 1);
        if (parentState) {
          _parentState.splice(tempSelectedItems.indexOf(name), 1);
        }
      } else {
        tempSelectedItems.push(value);
        tempSelectedRenderItems.push(name);
        _parentState.push(value);
      }
      await this.setState({
        selectedItems: tempSelectedItems,
        selectedRenderKeys: tempSelectedRenderItems,
      });
      if (parentState) {
        onChange({ target: { name: parentStateKey, value: [...new Set(_parentState)] } });
      } else {
        onChange({ target: { name: parentStateKey, value: tempSelectedItems } });
      }
    } else if (value === selectedItems[0]) {
      this.setState({
        selectedItems: [],
        selectedRenderKeys: [],
      });
      onChange({ target: { name: parentStateKey, value: [] } });
    } else {
      this.setState({
        selectedItems: [value],
        selectedRenderKeys: [name],
      });
      onChange({ target: { name: parentStateKey, value: [value] } });
    }
  }

  render() {
    const {
      selectedItems,
    } = this.state;
    const {
      renderKey, multipleSelect, className, data,
    } = this.props;
    return (
      <div
        className={`${className}`}
      >
        {(data && data.length) ? data.map((item) => (

          <div
            className="d-flex p-2 align-items-center"
            key={item._id}
          >
            <svg
              width="28"
              height="28"
              viewBox="0 0 24 24"
              color={`${selectedItems.includes(item._id) ? '#5d0018' : 'transparent'}`}
              className="p-1 border rounded"
              onClick={() => this.onChange({
                name: item[renderKey],
                value: item._id,
              })}
              role="button"
            >
              <path
                d="M0 12.116l2.053-1.897c2.401 1.162 3.924 2.045 6.622 3.969 5.073-5.757 8.426-8.678 14.657-12.555l.668 1.536c-5.139 4.484-8.902 9.479-14.321 19.198-3.343-3.936-5.574-6.446-9.679-10.251z"
                fill="currentcolor"
              />

            </svg>
            <div
              role="button"
              onClick={() => this.onChange({
                name: item[renderKey],
                value: item._id,
              })}
              tabIndex={0}
              className={`${selectedItems.includes(item._id) ? 'bg-darker' : 'bg-light'} ms-1 bg-darker w-100 text-center rounded p-1 hover-light`}
            >
              <span>
                <img width="55" src={item[renderKey]} alt="" />
                {/* {item[renderKey]} */}
              </span>
            </div>
          </div>
        )) : null}
        {multipleSelect ? (
          <div
            className="d-flex p-2 align-items-center"
          >
            <div>
              <svg
                width="28"
                height="28"
                viewBox="0 0 24 24"
                color={`${selectedItems?.length === data?.length ? '#5d0018' : 'transparent'}`}
                className="p-1 border rounded"
                onClick={() => this.onChange({ name: 'allItems' })}
                role="button"
              >
                <path
                  d="M0 12.116l2.053-1.897c2.401 1.162 3.924 2.045 6.622 3.969 5.073-5.757 8.426-8.678 14.657-12.555l.668 1.536c-5.139 4.484-8.902 9.479-14.321 19.198-3.343-3.936-5.574-6.446-9.679-10.251z"
                  fill="currentcolor"
                />
              </svg>
            </div>
            <div
              role="button"
              onClick={() => this.onChange({ name: 'allItems' })}
              tabIndex={0}
              className="ms-1 bg-light w-100 text-center rounded p-1 hover-light"
            >
              <span>
                Visi
              </span>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

ShopFilter.defaultProps = {
  multipleSelect: false,
  className: '',
  parentState: null,
  selectedItems: [],
};

ShopFilter.propTypes = {
  data: PropTypes.array.isRequired,
  renderKey: PropTypes.string.isRequired,
  parentStateKey: PropTypes.string.isRequired,
  parentState: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  selectedItems: PropTypes.array,
  multipleSelect: PropTypes.bool,
  className: PropTypes.string,
};

export default ShopFilter;
