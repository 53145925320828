import React, { Component } from 'react';
import Slider from './components/slider';
import SliderMobile from './components/sliderMobile';
import CategoriesHome from './components/category';
import ProductSlider from './components/productSlider';
import Alert from '../../components/alert';
import CenteredSpinner from '../../components/centeredSpinner';
// import MultiSlider from './components/multiSlider';
import Floaty from '../../popUps/floaty';

class Home extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      categories: [],
      ads: [],
      banners: [],
      popularProducts: [],
      lists: {},
      message: '',
      qtyLoading: false,
    };
    this.getCategories = this.getCategories.bind(this);
    this.getBanners = this.getBanners.bind(this);
    this.getAds = this.getAds.bind(this);
    this.getPopularProducts = this.getPopularProducts.bind(this);
    this.getLists = this.getLists.bind(this);
    this.handleProductChanges = this.handleProductChanges.bind(this);
    this.fetchListsController = new AbortController();
  }

  componentDidMount() {
    this.getLists();
    this.getCategories();
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
    document.title = 'ProductCave';
  }

  componentWillUnmount() {
    // console.log('unmount');
    this.fetchListsController.abort();
  }

  handleProductChanges({ qty, baseProductId, listId }) {
    this.setState({
      qtyLoading: true,
    });
    fetch('/api/lists/qty', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        qty,
        baseProductId,
        listId,
      }),
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((res) => {
        const {
          status, data,
        } = res;
        const { lists } = data;
        if (status === 'success') {
          this.setState({
            lists,
            qtyLoading: false,
          });
        } else {
          this.setState({
            notificationMsg: 'Neizdevās saglabāt izmaiņas',
            notificationStatus: 'error',
            qtyLoading: false,
          });
        }
      }).catch((ex) => {
        this.setState({
          notificationMsg: 'Neizdevās saglabāt izmaiņas',
          notificationStatus: 'error',
          qtyLoading: false,
        });
        console.error('error updating qty', ex);
      });
  }

  getCategories() {
    fetch(`/api/categories/products/?limit=${5}`)
      .then((res) => res.json())
      .then((res) => {
        const { status, categories } = res;
        if (status) {
          this.setState({
            categories,
            // loading: false,
          });
          this.getAds();
        } else {
          this.setState({
            loading: false,
            // message,
          });
        }
      }).catch((ex) => {
        console.log(ex);
        this.setState({
          message: 'Atnāc vēlāk',
          loading: false,
        });
      });
  }

  getAds() {
    fetch('/api/ads')
      .then((res) => res.json())
      .then((res) => {
        const { status, ads } = res;
        if (status) {
          this.setState({
            ads,
          });
        }
        this.getBanners();
      }).catch((ex) => {
        console.error(ex);
        this.setState({
          message: 'Atnāc vēlāk',
          loading: false,
        });
      });
  }

  getBanners() {
    fetch('/api/banners')
      .then((res) => res.json())
      .then((res) => {
        const { banners, status } = res;
        if (status) {
          this.setState({
            banners,
          });
        }
        this.getPopularProducts();
      }).catch((ex) => {
        console.error(ex);
        this.setState({
          message: 'Atnāc vēlāk',
          loading: false,
        });
      });
  }

  getPopularProducts() {
    fetch('/api/products/popular')
      .then((res) => res.json())
      .then((res) => {
        const { status, data, message } = res;
        if (status) {
          this.setState({
            popularProducts: data,
            loading: false,
          });
        } else {
          this.setState({
            loading: false,
            // message,
          });
          console.log(message);
        }
      }).catch((ex) => {
        console.log(ex);
        this.setState({
          message: 'Atnāc vēlāk',
          loading: false,
        });
      });
  }

  getLists() {
    fetch('/api/lists/product-page', {
      signal: this.fetchListsController.signal,
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((res) => {
        const {
          status, message, data,
        } = res;
        if (status === 'success') {
          this.setState({
            lists: data,
          });
        } else {
          console.log(message);
        }
      }).catch((ex) => {
        if (!ex.name || ex.name !== 'AbortError') {
          console.log(ex);
        }
      });
  }

  render() {
    const {
      loading, categories, ads, banners,
      popularProducts, message, lists,
      notificationMsg, notificationStatus,
      qtyLoading,
    } = this.state;

    if (loading) return <CenteredSpinner />;

    return (
      <>
        {notificationMsg
          ? (
            <Floaty
              status={notificationStatus}
              message={notificationMsg}
              onClose={() => this.setState({ notificationMsg: '' })}
            />
          )
          : null}
        {(message || !categories || !categories.length) ? (
          <Alert />
        ) : (
          <div>
            <Slider />
            <SliderMobile />
            <ProductSlider products={popularProducts} title="Populārākie produkti" />
            {categories.map((category, index) => (
              <CategoriesHome
                index={index}
                key={category.name}
                category={category}
                ads={ads}
                banners={banners}
                lists={lists}
                handleProductChanges={this.handleProductChanges}
                qtyLoading={qtyLoading}
              />
            ))}
          </div>
        )}
      </>
    );
  }
}

export default Home;
