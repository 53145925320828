import React, { Component } from 'react';
// import { Rating } from 'react-simple-star-rating';
import { NavLink, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import PriceChart from './components/priceChart';
// import ProductCard from '../../components/productCard';

import Floaty from '../../popUps/floaty';
import Alert from '../../components/alert';
import CenteredSpinner from '../../components/centeredSpinner';
// import MissingShopProductCard from './components/missingShopProductCard';
import AddProductSuggestionModal from './components/addProductSuggestionModal';
// import UserListsDropdown from './components/userListsDropdown';
import StarRating from './components/starRating';
import ShopsProducts from './components/shopsProducts';
import AcceptancePopup from '../../popUps/acceptancePopup';
import SuggestionProductTooltip from '../../components/tooltips/SuggestionProductTooltip';
import AddToListsDropdown from '../../components/addToListsDropdown';
import AddProductToList from '../../components/addProductToList';

class SingleProduct extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      products: [],
      selectedProduct: {},
      ads: [],
      rating: 0,
      ratingLoading: false,
      unit: null,
      // startSize: window.screen.width < 576 ? 40 : 50,
      productId: null,
      lists: {},
      notificationMsg: '',
      notificationStatus: null,
      lastRating: 0,
      redirectToHome: false,
      selectedShop: null,
      missingShops: [],
      openAddSuggestionProductModal: false,
      selectedShopProduct: null,
      refreshPriceChart: false,
      qtyLoading: false,
      openDeleteSuggestionProductModal: false,
      baseProduct: {},
      deleteSuggestionLoading: false,
    };
    this.getProduct = this.getProduct.bind(this);
    this.getAds = this.getAds.bind(this);
    // this.getProductInAllShops = this.getProductInAllShops.bind(this);
    this.handleRating = this.handleRating.bind(this);
    this.incrementSearchCount = this.incrementSearchCount.bind(this);
    this.resizeStars = this.resizeStars.bind(this);
    this.getLists = this.getLists.bind(this);
    this.toggleAddProductSuggestionModal = this.toggleAddProductSuggestionModal.bind(this);
    this.toggleDeleteProductSuggestionModal = this.toggleDeleteProductSuggestionModal.bind(this);
    this.addProductSuggestion = this.addProductSuggestion.bind(this);
    this.onShopProductSelect = this.onShopProductSelect.bind(this);
    this.deleteSuggestion = this.deleteSuggestion.bind(this);
    this.handleProductQtyChanges = this.handleProductQtyChanges.bind(this);

    this.fetchProductsController = new AbortController();
    this.fetchListsController = new AbortController();
  }

  async componentDidMount() {
    const { location } = this.props;
    if (location?.state?.searchCount) {
      this.incrementSearchCount();
    }
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
    if (window.screen.width < 350) {
      // this.setState({ startSize: 40 });
    }

    window.addEventListener('resize', () => {
      this.resizeStars();
    });
    await this.getProduct();
    this.getLists();
  }

  componentDidUpdate(prevProps) {
    const { match: prevMatch } = prevProps;
    const { match } = this.props;
    const { params: prevParams } = prevMatch;
    const { params } = match;
    const { productId } = params;
    if (productId && productId !== prevParams?.productId) {
      this.getLists();
    }
  }

  componentWillUnmount() {
    this.fetchProductsController.abort();
    this.fetchListsController.abort();
  }

  handleRating(rate) {
    const { lastRating } = this.state;
    if (lastRating === rate) return;
    this.setState({
      ratingLoading: true,
    });
    const { match } = this.props;
    const { params } = match;
    const { productId } = params;
    fetch('/api/products/rating/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        productId,
        rating: rate,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        const {
          status, rating, ratingCount,
        } = res;
        if (status) {
          this.setState({
            ratingLoading: false,
            rating,
            ratingCount,
            lastRating: rate,
          });
        } else {
          this.setState({
            ratingLoading: false,
          });
        }
      }).catch((ex) => {
        console.log(ex);
        this.setState({
          ratingLoading: false,
        });
      });
  }

  handleProductQtyChanges({ qty, baseProductId, listId }) {
    this.setState({
      qtyLoading: true,
    });
    fetch('/api/lists/qty', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        qty,
        baseProductId,
        listId,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        const {
          status, data,
        } = res;
        const { lists } = data;
        if (status === 'success') {
          this.setState({
            lists,
            qtyLoading: false,
          });
        } else {
          this.setState({
            notificationMsg: 'Neizdevās saglabāt izmaiņas',
            notificationStatus: 'error',
            qtyLoading: false,
          });
        }
      }).catch((ex) => {
        this.setState({
          notificationMsg: 'Neizdevās saglabāt izmaiņas',
          notificationStatus: 'error',
          qtyLoading: false,
        });
        console.error('aaa', ex);
      });
  }

  onShopProductSelect({ selectedProduct, shop, shopsProduct }) {
    this.setState({
      selectedProduct,
      selectedShop: shop,
      selectedShopProduct: shopsProduct,
    });
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  getProduct() {
    this.setState({
      loading: true,
    });
    const { match } = this.props;
    const { params } = match;
    const { productId } = params;

    fetch(`/api/products/single/${productId}`, {
      signal: this.fetchProductsController.signal,
    })
      .then((res) => res.json())
      .then((res) => {
        const {
          status, product, message, products,
          missingShops,
        } = res;
        if (status) {
          const updatedProducts = [...products, { ...product, order: 0 }]
            .map((data) => {
              if (data?.order !== 0) {
                return { ...data, order: 1 };
              }
              return data;
            });

          this.setState({
            selectedProduct: product,
            baseProduct: { ...product, order: 0 },
            rating: product?.rating || 0,
            ratingCount: product?.ratingCount || 0,
            unit: product?.unit,
            productId: product?._id,
            products: updatedProducts,
            missingShops,
          });
          document.title = product?.name;
          this.getAds();
        } else {
          this.setState({
            loading: false,
            redirectToHome: true,
          });
          console.error(message);
        }
      }).catch((ex) => {
        if (!ex.name || ex.name !== 'AbortError') {
          console.log(ex);
          this.setState({
            message: 'Atnāc vēlāk',
            loading: false,
          });
        }
      });
  }

  getLists() {
    fetch('/api/lists/product-page', {
      signal: this.fetchListsController.signal,
    })
      .then((res) => res.json())
      .then((res) => {
        const {
          status, message, data,
        } = res;
        if (status === 'success') {
          this.setState({
            lists: data,
          });
        } else {
          console.log(message);
        }
      }).catch((ex) => {
        if (!ex.name || ex.name !== 'AbortError') {
          console.log(ex);
        }
      });
  }

  getAds() {
    fetch('/api/ads')
      .then((res) => res.json())
      .then((res) => {
        const { ads } = res;
        if (ads && ads.length) {
          this.setState({
            ads,
            loading: false,
          });
        } else {
          this.setState({
            loading: false,
          });
        }
      }).catch((ex) => {
        console.error(ex);
        this.setState({
          loading: false,
          message: 'Atnāc vēlāk',
        });
      });
  }

  deleteSuggestion() {
    const { selectedShopProduct } = this.state;
    const { suggestionId } = selectedShopProduct;
    this.setState({
      deleteSuggestionLoading: true,
    });

    fetch(`/api/products-suggestions/${suggestionId}`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      // signal: this.tokenCheckController.signal,
    }).then((res) => res.json())
      .then((res) => {
        const {
          status, message, products,
          missingShops,
        } = res;
        if (status === 'success') {
          const { selectedProduct, baseProduct } = this.state;
          // ja ir izvēlēts ieteikums, ieliekam vietā baseProduct
          if (selectedProduct?.suggestionId === suggestionId) {
            this.setState({
              selectedProduct: baseProduct,
            });
          }

          const updatedProducts = [...products, { ...baseProduct, order: 0 }]
            .map((data) => {
              if (data?.order !== 0) {
                return { ...data, order: 1 };
              }
              return data;
            });

          this.setState({
            notificationMsg: message,
            notificationStatus: status,
            products: updatedProducts,
            missingShops,
            deleteSuggestionLoading: false,
            refreshPriceChart: true,
          }, () => {
            this.toggleDeleteProductSuggestionModal();

            this.setState({
              refreshPriceChart: false,
            });
          });
        } else {
          this.setState({
            notificationMsg: message,
            notificationStatus: status,
            deleteSuggestionLoading: false,
          });
        }
      });
  }

  resizeStars() {
    this.setState({
      // startSize: window.screen.width < 576 ? 40 : 50,
    });
  }

  incrementSearchCount() {
    const { match } = this.props;
    const { params } = match;
    const { productId } = params;
    fetch('/api/products/inc-search/', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        productId,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        const {
          status, message,
        } = res;
        if (!status) {
          console.error(message);
        }
      }).catch((ex) => {
        console.error(ex);
      });
  }

  toggleAddProductSuggestionModal({ missingShop }) {
    const { openAddSuggestionProductModal } = this.state;
    if (missingShop) {
      this.setState({
        selectedMissingShop: missingShop,
      });
    }
    this.setState({
      openAddSuggestionProductModal: !openAddSuggestionProductModal,
    });
  }

  toggleDeleteProductSuggestionModal(selectedShopProduct) {
    const { openDeleteSuggestionProductModal } = this.state;

    this.setState({
      openDeleteSuggestionProductModal: !openDeleteSuggestionProductModal,
      selectedShopProduct,
    });
  }

  addProductSuggestion({ products, missingShops }) {
    const { baseProduct } = this.state;

    const updatedProducts = [...products, { ...baseProduct, order: 0 }];

    this.setState({
      products: updatedProducts.map((data) => {
        if (data?.order !== 0) {
          return { ...data, order: 1 };
        }
        return data;
      }),
      missingShops,
      refreshPriceChart: true,
    }, () => {
      this.toggleAddProductSuggestionModal({});
      this.setState({
        refreshPriceChart: false,
      });
    });
  }

  render() {
    const {
      loading, products, ads, rating,
      ratingLoading, ratingCount, message, unit,
      // startSize,
      productId, notificationStatus, notificationMsg,
      redirectToHome, selectedShop,
      lists, missingShops, openAddSuggestionProductModal, selectedMissingShop,
      selectedShopProduct, refreshPriceChart, qtyLoading,
      openDeleteSuggestionProductModal, selectedProduct, baseProduct,
      deleteSuggestionLoading,
    } = this.state;

    const hasMultipleLists = lists && Object.keys(lists)?.length > 1;

    // const { user } = this.props;

    const samePrices = selectedProduct?.minPrice === selectedProduct?.maxPrice;

    if (loading) return <CenteredSpinner />;

    if (redirectToHome) {
      return <Redirect to="/" />;
    }

    return (
      <>
        {message ? (
          <Alert />
        ) : (
          <article className="container-lg my-5">
            <Helmet>
              <title>{baseProduct.name}</title>
              <meta name="description" content={`Salīdzini ${baseProduct.name} cenas visos Latvijas veikalos ar ProductCave`} />
              <link rel="canonical" href={`/product/${baseProduct.slug}`} />
            </Helmet>
            <div className="row d-flex justify-content-around border-bottom border-2 pb-5">
              <div
                className="d-flex flex-column col-10 col-lg-4 col-md-5 col-sm-8 p-4 pt-0 shadow rounded-3 align-items-center
                justify-content-between bg-white position-relative"
                style={{ minHeight: '440px' }}
              >
                {selectedProduct?.suggestion ? (
                  <div className="d-flex justify-content-between col-12 position-absolute">
                    <div id="suggestion-tooltip">
                      <SuggestionProductTooltip />
                    </div>
                    <button
                      type="button"
                      className="btn-close"
                      aria-label="Close"
                      id="delete-suggestion"
                      onClick={() => this.toggleDeleteProductSuggestionModal(selectedProduct)}
                      title="Noņemt ieteikumu"
                    />
                  </div>
                ) : null}
                <div className="col-12 mt-4 d-flex flex-wrap justify-content-between align-items-center">
                  {selectedProduct?.shop?.image ? (
                    <div
                      className="col-lg-4 col-5 px-auto mx-auto"
                    >
                      <img
                        src={selectedProduct?.shop?.image}
                        alt={selectedProduct?.shop?.name}
                        className="w-100"
                        style={{ maxHeight: '80px', objectFit: 'contain' }}
                      />
                    </div>
                  ) : null}
                  <div className="d-block fw-bold fs-3 font-petrona mx-auto">
                    {samePrices
                      ? selectedProduct?.minPrice : (
                        <>
                          {selectedProduct?.minPrice}
                          -
                          {selectedProduct?.maxPrice}
                        </>
                      )}
                    €
                    {unit ? ` /${unit}` : null}
                  </div>
                </div>
                <div>
                  <img
                    src={selectedProduct.image}
                    className="img-fluid"
                    alt={selectedProduct?.name || 'bilde pazuda'}
                  />
                </div>
                <div className="d-flex flex-column align-items-center">
                  <h2 className="text-center fw-bold fs-5">
                    {selectedProduct.name}
                  </h2>
                  <div className="rating-container mt-md-4">
                    {ratingLoading ? (
                      <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      <StarRating
                        rating={Number(rating.toFixed(2))}
                        handleRating={this.handleRating}
                        ratingCount={ratingCount}
                      />
                    )}
                  </div>
                </div>

                {!hasMultipleLists ? (
                  <AddProductToList
                    baseProduct={baseProduct}
                    list={lists ? lists[Object.keys(lists)[0]] : {}}
                    handleProductChanges={this.handleProductQtyChanges}
                    className="position-absolute end-0 bottom-0 d-flex justify-content-end me-1 mb-1"
                    loading={qtyLoading}
                  />
                ) : null}
                {hasMultipleLists ? (
                  <AddToListsDropdown
                    baseProduct={baseProduct}
                    lists={lists}
                    handleProductChanges={this.handleProductQtyChanges}
                    className="position-absolute end-0 bottom-0 d-flex justify-content-end me-1 mb-1"
                    loading={qtyLoading}
                  />
                ) : null}
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-5 p-0 pe-2 px-md-1">
                <PriceChart
                  selectedShop={selectedShop}
                  baseProductId={productId}
                  shopProductId={selectedShopProduct?._id}
                  refreshPriceChart={refreshPriceChart}
                  // chartData={product?.chartData}
                  // hideButtons={!!product.shop}
                />
              </div>
            </div>
            {products?.length ? (
              <ShopsProducts
                baseProduct={baseProduct}
                selectedProduct={selectedProduct}
                shopsProducts={products}
                missingShops={missingShops}
                onShopProductSelect={this.onShopProductSelect}
                toggleAddProductSuggestionModal={this.toggleAddProductSuggestionModal}
                toggleDeleteProductSuggestionModal={this.toggleDeleteProductSuggestionModal}
              />
            ) : null}
            {ads && ads.map((ad) => {
              if (ad.productPage) {
                return (
                  <div
                    className="container d-flex justify-content-center mt-5"
                    key={ad._id}
                  >
                    <NavLink
                      to={ad?.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={ad.image} alt="banner" className="w-100" />
                    </NavLink>
                  </div>
                );
              }
              return null;
            })}
            {baseProduct?.masterCategory?.name === 'Alkoholiskie dzērieni' ? (
              <h4
                className="mb-0 d-none d-md-block fs-5"
              >
                ALKOHOLA LIETOŠANAI IR NEGATĪVA IETEKME. ALKOHOLISKOS DZĒRIENUS IR AIZLIEGTS PĀRDOT,
                IEGĀDĀTIES UN NODOT NEPILNGADĪGĀM PERSONĀM!
              </h4>
            ) : null}
            {baseProduct?.masterCategory?.name === 'Alkoholiskie dzērieni' ? (
              <h4 className="mb-0 d-block d-md-none fs-5">
                ALKOHOLA LIETOŠANAI IR NEGATĪVA IETEKME. ALKOHOLISKOS DZĒRIENUS IR AIZLIEGTS PĀRDOT,
                IEGĀDĀTIES UN NODOT NEPILNGADĪGĀM PERSONĀM!
              </h4>
            ) : null}
            <h4 className="text-muted fw-normal m-0 small mt-3 fs-6">
              Mēs apkopojam veikalu publiski sniegto informāciju - diemžēl,
              ne vienmēr šī informācija atbilst ar reālo situāciju visos veikalos.
            </h4>
          </article>
        )}
        {notificationMsg
          ? (
            <Floaty
              status={notificationStatus}
              message={notificationMsg}
              onClose={() => this.setState({ notificationMsg: '' })}
            />
          )
          : null}
        {openAddSuggestionProductModal ? (
          <AddProductSuggestionModal
            missingShop={selectedMissingShop}
            toggleAddProductSuggestionModal={this.toggleAddProductSuggestionModal}
            addProductSuggestion={this.addProductSuggestion}
            isOpen={openAddSuggestionProductModal}
            baseProduct={baseProduct}
            chartData={selectedProduct?.chartData}
            selectedShop={selectedShop}
          />
        ) : null}
        {openDeleteSuggestionProductModal ? (
          <AcceptancePopup
            content="Vai tiešām vēlies dzēst šo ieteikumu?"
            onSubmit={this.deleteSuggestion}
            onClose={this.toggleDeleteProductSuggestionModal}
            isOpen={openDeleteSuggestionProductModal}
            loading={deleteSuggestionLoading}
          />
        ) : null}
      </>
    );
  }
}

SingleProduct.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  // user: PropTypes.object,
};

export default SingleProduct;
