import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ProductCard from '../../components/productCard';
import Pagination from '../../components/pagination';
import ProductSort from '../../components/productSort';
import CategoryFilter from '../../components/categoryFilter';
import ShopFilter from '../../components/shopFilter';
import Alert from '../../components/alert';
import Floaty from '../../popUps/floaty';
import CenteredSpinner from '../../components/centeredSpinner';
// import { Redirect } from 'react-router-dom';

class SearchResults extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      productsLoading: false,
      products: [],
      mostSearchedProducts: [],
      search: '',
      categories: [],
      listShop: null,
      timeout: null,
      exceedsLimit: false,
      lists: {},
      qtyLoading: false,
      notificationMsg: '',
      notificationStatus: null,
    };
    this.searchController = new AbortController();

    this.getProducts = this.getProducts.bind(this);
    this.getMostSearchedProducts = this.getMostSearchedProducts.bind(this);
    this.onChange = this.onChange.bind(this);
    this.getShops = this.getShops.bind(this);
    this.getLists = this.getLists.bind(this);
    this.handleProductQtyChanges = this.handleProductQtyChanges.bind(this);

    this.fetchListsController = new AbortController();
  }

  componentDidMount() {
    const { location } = this.props;
    this.getLists();
    this.getMostSearchedProducts();
    const query = new URLSearchParams(location.search);
    const shop = query.get('shop');
    this.setState({
      listShop: shop,
    }, () => {
      this.getShops();
      this.getProducts({});
    });
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
    document.title = 'ProductCave';
  }

  componentDidUpdate() {
    const { search } = this.state;
    const { location } = this.props;
    const query = new URLSearchParams(location.search);
    const newSearch = query.get('search');

    if (newSearch !== search) {
      /* eslint-disable-next-line */
      this.setState({
        categories: [],
        loading: true,
        // set page to 1
      }, () => this.getProducts({}));
    }
  }

  componentWillUnmount() {
    this.setState({
      loading: false,
    });
    this.searchController.abort();
  }

  handleProductQtyChanges({ qty, baseProductId, listId }) {
    this.setState({
      qtyLoading: true,
    });
    fetch('/api/lists/qty', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        qty,
        baseProductId,
        listId,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        const {
          status, data,
        } = res;
        const { lists } = data;
        if (status === 'success') {
          this.setState({
            lists,
            qtyLoading: false,
          });
        } else {
          this.setState({
            notificationMsg: 'Neizdevās saglabāt izmaiņas',
            notificationStatus: 'error',
            qtyLoading: false,
          });
        }
      }).catch((ex) => {
        this.setState({
          notificationMsg: 'Neizdevās saglabāt izmaiņas',
          notificationStatus: 'error',
          qtyLoading: false,
        });
        console.error('aaa', ex);
      });
  }

  onChange(e) {
    const { inputType, target } = e;
    const { name, value } = target;
    if (this.searchController) {
      this.searchController.abort();
    }

    if (name === 'page') {
      // console.log(name, value, inputType);
      // ja inputType === inputField, tas nozīmē, ka lietotājs lapas numuru ir vadījis ar roku
      window.scrollTo({
        top: 315,
        behavior: 'instant',
      });
    }
    this.setState({
      [name]: value,
    });

    const sessionFilter = sessionStorage.getItem('search');
    let tempFilter = {};
    if (sessionFilter) {
      tempFilter = JSON.parse(sessionFilter) || {};
    }
    if (name === 'categoryFilter') {
      tempFilter.selectedCategories = value;
      tempFilter.page = 1;
    }

    if (name === 'sort') {
      tempFilter.sort = value;
    }

    if (name === 'shopFilter') {
      const { listShop } = this.state;

      if (listShop && value?.length) {
        const selectedShops = [listShop, ...value];
        localStorage.setItem('shops', JSON.stringify(selectedShops));
      } else {
        localStorage.setItem('shops', JSON.stringify(value));
      }
      this.setState({
        listShop: null,
      });
      tempFilter.page = 1;
    }

    if (name === 'page') {
      tempFilter.page = value;
    }
    sessionStorage.setItem('search', JSON.stringify(tempFilter));
    this.getProducts({ wait: inputType === 'inputField' });
  }

  getShops() {
    fetch('/api/shops')
      .then((res) => res.json())
      .then((res) => {
        const { shops, status } = res;
        if (status) {
          this.setState({
            shops,
            // loading: false,
          });
        } else {
          console.log('Neatrada veikalus');
          this.setState({
            loading: false,
          });
        }
      }).catch((ex) => {
        console.error(ex);
        this.setState({
          loading: false,
          message: 'Atnāc vēlāk',
        });
      });
  }

  getProducts({ wait }) {
    // this.setState({ searchLoading: false });
    this.searchController = new AbortController();
    const { location } = this.props;
    // listShop nāk no sarakstiem -
    // - ja lietotājs uzspiedis 'Rādīt visu no alternatīvu pievienošanas'.
    const { listShop } = this.state;
    let { timeout } = this.state;
    if (wait) {
      clearTimeout(timeout);
    }

    const query = new URLSearchParams(location.search);
    const search = query.get('search');

    let filter = {};
    if (sessionStorage.getItem('search')) {
      filter = JSON.parse(sessionStorage.getItem('search'));
    }
    const {
      selectedCategories,
      sort, page,
    } = filter;
    let selectedShops = [];

    const localStorageShops = localStorage.getItem('shops');
    if (localStorageShops && localStorageShops !== 'undefined') {
      selectedShops = JSON?.parse(localStorageShops) || [];
    }
    const { categories } = this.state;

    this.setState({
      search,
    });

    timeout = setTimeout(() => {
      // mobile versijā nescrollot uz augšu
      if (window.screen.width > 991) {
        window.scrollTo({
          top: 0,
          behavior: 'instant',
        });
      }

      const { redirectToSearchResults } = this.state;
      if (redirectToSearchResults) return;
      this.setState({
        productsLoading: true,
      });
      fetch('/api/products/search', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          search,
          sort,
          mostSearched: true,
          pagination: true,
          page: page || 1,
          selectedCategories,
          selectedShops: listShop ? [listShop] : selectedShops,
        }),
        signal: this.searchController.signal,
      })
        .then((res) => res.json())
        .then((res) => {
          const { status, data } = res;
          const {
            products, mostSearchedProducts, pageCount,
            totalProducts, message, categories: updatedCategories,
            exceedsLimit,
          } = data || {};

          if (status) {
            this.setState({
              products,
              mostSearchedProducts,
              loading: false,
              productsLoading: false,
              pageCount,
              totalProducts,
              categories: !categories?.length ? updatedCategories : categories,
              exceedsLimit,
            });
          } else {
            this.setState({
              loading: false,
              products: products || [],
              productsLoading: false,
              mostSearchedProducts,
              message,
            });
          }
        }).catch((ex) => {
          if (!ex.name || ex.name !== 'AbortError') {
            console.error(ex);
            this.setState({
              loading: false,
              productsLoading: false,
            });
          }
        });
    }, wait ? 800 : 0);
    this.setState({ timeout });
  }

  getMostSearchedProducts() {
    fetch('/api/products/most-searched')
      .then((res) => res.json())
      .then((res) => {
        const {
          data, status, message,
        } = res;
        if (status) {
          this.setState({
            mostSearchedProducts: data,
          });
        } else {
          this.setState({
            loading: false,
            productsLoading: false,
            message,
          });
        }
      }).catch((ex) => {
        console.error(ex);
        this.setState({
          loading: false,
          productsLoading: false,
        });
      });
  }

  getLists() {
    fetch('/api/lists/product-page', {
      signal: this.fetchListsController.signal,
    })
      .then((res) => res.json())
      .then((res) => {
        const {
          status, message, data,
        } = res;
        if (status === 'success') {
          this.setState({
            lists: data,
          });
        } else {
          console.log(message);
        }
      }).catch((ex) => {
        if (!ex.name || ex.name !== 'AbortError') {
          console.log(ex);
        }
      });
  }

  render() {
    const {
      loading, products, mostSearchedProducts,
      search, pageCount, totalProducts, productsLoading,
      categories, shops, message, listShop, exceedsLimit,
      lists, qtyLoading, notificationMsg, notificationStatus,
      // redirectToHome
    } = this.state;

    let filter = {};
    if (sessionStorage.getItem('search')) {
      filter = JSON.parse(sessionStorage.getItem('search'));
    }
    const selectedCategories = filter?.selectedCategories || [];
    const selectedPage = Number(filter?.page) || 1;
    let selectedShops = [];

    const localStorageShops = localStorage.getItem('shops');
    if (localStorageShops && localStorageShops !== 'undefined') {
      selectedShops = JSON?.parse(localStorageShops) || [];
    }
    const sort = filter?.sort || {};

    if (loading) return <CenteredSpinner />;

    return (
      <>
        {notificationMsg
          ? (
            <Floaty
              status={notificationStatus}
              message={notificationMsg}
              onClose={() => this.setState({ notificationMsg: '' })}
            />
          )
          : null}
        {message ? (
          <Alert />
        ) : (
          <div className="container-fluid mt-3 mb-5 me-0 ms-0 d-flex justify-content-center flex-lg-row flex-column align-items-lg-start align-items-center">
            {categories?.length ? (
              <div className="d-lg-block d-none mt-5">
                <div className="col-12 border bg-white p-1 mt-4">
                  <CategoryFilter
                    data={categories}
                    renderKey="name"
                    parentStateKey="categoryFilter"
                    onChange={this.onChange}
                    selectedItems={selectedCategories}
                    multipleSelect
                  />
                </div>
              </div>
            ) : null}
            {categories?.length ? (
              <div className="accordion d-lg-none container" id="accordionBasic">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className={`accordion-button shadow-none bg-white text-dark collapsed ${selectedCategories?.length ? 'fw-bold' : ''}`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      Kategorijas
                    </button>
                  </h2>
                  <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionBasic">
                    <div className="accordion-body">
                      <div className="col-lg-3 justify-content-end d-flex">
                        <div className="col-xxl-6 col-lg-8 col-12 p-1">
                          <CategoryFilter
                            data={categories}
                            renderKey="name"
                            parentStateKey="categoryFilter"
                            onChange={this.onChange}
                            selectedItems={selectedCategories}
                            multipleSelect
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            <div className="accordion d-lg-none container mt-2" id="accordionBasic">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className={`accordion-button shadow-none bg-white text-dark collapsed ${selectedShops?.length ? 'fw-bold' : ''}`}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Veikali
                  </button>
                </h2>
                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionBasic">
                  <div className="accordion-body">
                    <div className="col-lg-3 justify-content-end d-flex">
                      <div className="col-xxl-6 col-lg-8 col-12 p-1">
                        <ShopFilter
                          data={shops}
                          renderKey="image"
                          parentStateKey="shopFilter"
                          selectedItems={listShop ? [listShop] : selectedShops}
                          onChange={this.onChange}
                          multipleSelect
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion d-lg-none container mt-2" id="accordionBasic">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button shadow-none bg-white text-dark collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Kārtot
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionBasic"
                >
                  <div className="accordion-body">
                    <div className="col-lg-3 justify-content-end d-flex">
                      <div className="col-xxl-6 col-lg-8 col-12 p-1">
                        <ProductSort
                          renderKey="title"
                          parentStateKey="sort"
                          onChange={this.onChange}
                          sort={sort}
                          multipleSelect
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 container mx-4">
              <div className="">
                <div className="fs-2 pb-1 border-bottom mt-2">
                  Meklēšanas rezultāti
                  <span className="fw-bold ms-4 fs-1">
                    {' "'}
                    {search}
                    {'" '}
                  </span>
                </div>
                {totalProducts ? (
                  <div className="mt-1 text-end text-grey">
                    <span>
                      Rezultāti:
                      {' '}
                    </span>
                    <span className="fw-bold">
                      {totalProducts}
                      {exceedsLimit ? '+' : null}
                    </span>
                  </div>
                ) : null}
                {(!products?.length && !productsLoading) ? (
                  <h4>Neviens produkts neatbilst kritērijiem</h4>
                )
                  : (
                    <div className="row mt-4 row-cols-xxl-4 row-cols-lg-3 row-cols-md-4 row-cols-sm-3 row-cols-2 px-0 pb-2">
                      {(products && products.length && !productsLoading)
                        ? products.map(
                          (product) => (
                            <ProductCard
                              key={product._id}
                              product={product}
                              hideShop
                              redirect
                              showPrice={!!selectedShops?.length}
                              lists={lists}
                              allowAddToList
                              qtyLoading={qtyLoading}
                              handleProductChanges={this.handleProductQtyChanges}
                            />
                          ),
                        ) : (
                          <div className="col-12 my-5 position-relative d-flex justify-content-center">
                            <CenteredSpinner />
                          </div>
                        )}
                    </div>
                  )}
                {(pageCount > 1) ? (
                  <Pagination
                    pageCount={pageCount}
                    handlePagination={this.onChange}
                    selectedPage={selectedPage}
                  />
                ) : null}
              </div>
              <div className="mt-4">
                {(mostSearchedProducts && mostSearchedProducts.length) ? (
                  <>
                    <div className="fs-3 text-grey text-center pb-1">
                      Biežāk meklētākās preces
                      <div className="row mt-4 row-cols-xxl-4 row-cols-lg-3 row-cols-md-4 row-cols-sm-3 row-cols-2 pb-3">
                        {mostSearchedProducts.map(
                          (product) => (
                            <ProductCard
                              key={product._id}
                              product={product}
                              hideShop
                              redirect
                              searchCount
                              allowAddToList
                              lists={lists}
                              qtyLoading={qtyLoading}
                              handleProductChanges={this.handleProductQtyChanges}
                            />
                          ),
                        )}
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
            <div className="d-lg-block d-none mt-5">
              <div className="col-12 d-flex flex-column border bg-white mt-4">
                <ProductSort
                  renderKey="title"
                  parentStateKey="sort"
                  onChange={this.onChange}
                  sort={sort}
                  multipleSelect
                />
              </div>
              <div className="col-12 d-flex flex-column border bg-white mt-2">
                <ShopFilter
                  data={shops}
                  renderKey="image"
                  parentStateKey="shopFilter"
                  onChange={this.onChange}
                  selectedItems={listShop ? [listShop] : selectedShops}
                  multipleSelect
                />
              </div>
            </div>
          </div>
        )}
      </>

    );
  }
}

SearchResults.propTypes = {
  location: PropTypes.object.isRequired,
};

export default SearchResults;
