import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class NotFound extends Component {
  constructor() {
    super();

    this.state = {};
  }

  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
  }

  render() {
    return (
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="text-grey">
            <h1>
              Vaj vaj!
            </h1>
            <h2>
              Lapa nav atrasta :(
            </h2>
            <div className="mt-3">
              <NavLink to="/" className="btn btn-outline-secondary btn-lg">
                Uz sākumu
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NotFound;
