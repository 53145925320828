import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Pagination extends Component {
  constructor(props) {
    super(props);
    const { selectedPage } = this.props;
    this.state = {
      pages: [],
      page: selectedPage,
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.onChange({ update: false });
  }

  componentDidUpdate(prevProps) {
    const { pageCount, selectedPage } = this.props;
    if (prevProps.pageCount !== pageCount
      || prevProps.selectedPage !== selectedPage) {
      this.onChange({ update: true });
    }
  }

  onChange({ update }) {
    let { page } = this.state;
    if (update) {
      const { selectedPage } = this.props;
      page = selectedPage;
    }
    const { pageCount, handlePagination } = this.props;
    let currentPage = page;
    let buttonsOnEachSide = 3;
    let hideArrows = false;
    if (window.screen.width < 450) {
      buttonsOnEachSide = 2;
      hideArrows = true;
    }
    if (page > pageCount) {
      currentPage = pageCount;
    }
    const pages = [];
    if (pageCount <= 10) {
      for (let i = 0; i <= pageCount + 1; i++) {
        // REGULAR
        let elem = (
          <button
            type="button"
            key={i}
            className={`d-flex justify-content-center align-items-center border border-2 rounded p-2 m-1 ${page === i ? 'shadow-sm border-2 border-warning' : ''}`}
            style={{ maxWidth: '30px', maxHeight: '30px', transition: 'all .2s ease' }}
            tabIndex={0}
            onClick={() => {
              this.setState({ page: i }, () => this.onChange({ update: false }));
              handlePagination({ target: { name: 'page', value: i }, inputType: 'button' });
            }}
          >
            {i}
          </button>
        );
        // IZVEIDO KREISO BULTIŅU
        if (!i && hideArrows) {
          elem = (<></>);
        }
        if ((i === pageCount + 1) && hideArrows) {
          elem = (<></>);
        }
        if (!i && !hideArrows) {
          elem = (
            <button
              type="button"
              className={`d-flex btn justify-content-center align-items-center border border-2 rounded p-2 m-1 ${page === i ? 'shadow-sm border-2 border-warning' : ''}`}
              style={{ maxWidth: '30px', maxHeight: '30px', transition: 'all .2s ease' }}
              key={i}
              tabIndex={0}
              disabled={currentPage === 1}
              onClick={() => {
                this.setState({ page: currentPage - 1 }, () => this.onChange({ update: false }));
                handlePagination({ target: { name: 'page', value: currentPage - 1 }, inputType: 'button' });
              }}
            >
              {'<'}
            </button>
          );
        }

        // IZVEIDO LABO BULTIŅU
        if ((i === pageCount + 1) && !hideArrows) {
          elem = (
            <button
              type="button"
              className={`d-flex btn justify-content-center align-items-center border border-2 rounded p-2 m-1 ${page === i ? 'shadow-sm border-2 border-warning' : ''}`}
              style={{ maxWidth: '30px', maxHeight: '30px', transition: 'all .2s ease' }}
              key={i}
              tabIndex={0}
              disabled={currentPage === pageCount}
              onClick={() => {
                this.setState({ page: currentPage + 1 }, () => this.onChange({ update: false }));
                handlePagination({ target: { name: 'page', value: currentPage + 1 }, inputType: 'button' });
              }}
            >
              {'>'}
            </button>
          );
        }
        pages.push(elem);
      }
    } else {
      for (let i = 0; i <= pageCount + 1; i++) {
        let elem;

        // IZVEIDO PIRMOS TRĪS
        if (i > 0 && i <= buttonsOnEachSide) {
          elem = (
            <button
              type="button"
              className={`d-flex justify-content-center align-items-center border border-2 rounded p-2 m-1 ${page === i ? 'shadow-sm border-2 border-warning' : ''}`}
              style={{ maxWidth: '30px', maxHeight: '30px', transition: 'all .2s ease' }}
              key={i}
              tabIndex={0}
              onClick={() => {
                this.setState({ page: i }, () => this.onChange({ update: false }));
                handlePagination({ target: { name: 'page', value: i }, inputType: 'button' });
              }}
            >
              {i}
            </button>
          );
        }

        // IZVEIDO PĒDĒJOS TRĪS
        if (i === pageCount || i === pageCount - 1 || i === pageCount - (buttonsOnEachSide - 1)) {
          elem = (
            <button
              type="button"
              className={`d-flex justify-content-center align-items-center border border-2 rounded p-2 m-1 ${page === i ? 'shadow-sm border-2 border-warning' : ''}`}
              style={{
                maxWidth: '30px',
                maxHeight: '30px',
                transition: 'all .2s ease',
              }}
              key={i}
              tabIndex={0}
              onClick={() => {
                this.setState({ page: i }, () => this.onChange({ update: false }));
                handlePagination({ target: { name: 'page', value: i }, inputType: 'button' });
              }}
            >
              {i}
            </button>
          );
        }

        // IZVEIDO IEVADES LAUKU VIDŪ
        if (i === parseInt((pageCount / 2).toFixed(), 10)) {
          elem = (
            <input
              type="number"
              max={pageCount}
              key={i}
              min={0}
              value={currentPage}
              className="d-flex justify-content-center align-items-center border border-2 rounded p-2 m-1 text-center"
              style={{ maxWidth: '40px', maxHeight: '30px', transition: 'all .2s ease' }}
              onChange={(e) => {
                this.setState({ page: e.target.value }, () => this.onChange({ update: false }));
                setTimeout(() => {
                  handlePagination({ target: { name: 'page', value: e.target.value }, inputType: 'inputField' });
                }, 600);
                // handlePagination({ page: e.target.value });
              }}
            />
          );
        }
        // IZVEIDO KREISO BULTIŅU
        if (!i) {
          elem = (
            <button
              className={`d-flex btn justify-content-center align-items-center border border-2 rounded p-2 m-1 ${page === i ? 'shadow-sm border-2 border-warning' : ''}`}
              style={{
                maxWidth: '30px',
                maxHeight: '30px',
                transition: 'all .2s ease',
              }}
              type="button"
              key={i}
              tabIndex={0}
              disabled={page === 1}
              onClick={() => {
                this.setState({ page: page - 1 }, () => this.onChange({ update: false }));
                handlePagination({ target: { name: 'page', value: page - 1 }, inputType: 'button' });
              }}
            >
              {'<'}
            </button>
          );
        }

        // IZVEIDO LABO BULTIŅU
        if (i === pageCount + 1) {
          elem = (
            <button
              type="button"
              className={`d-flex btn justify-content-center align-items-center border border-2 rounded p-2 m-1 ${page === i ? 'shadow-sm border-2 border-warning' : ''}`}
              style={{ maxWidth: '30px', maxHeight: '30px', transition: 'all .2s ease' }}
              key={i}
              tabIndex={0}
              disabled={page === pageCount}
              onClick={() => {
                this.setState({ page: page + 1 }, () => this.onChange({ update: false }));
                handlePagination({ target: { name: 'page', value: page + 1 }, inputType: 'button' });
              }}
            >
              {'>'}
            </button>
          );
        }
        pages.push(elem);
      }
    }
    this.setState({ pages, page: currentPage });
  }

  render() {
    const { pages } = this.state;
    return (
      <div className="d-flex flex-row flex-wrap justify-content-center border-bottom pb-4">
        {pages}
      </div>
    );
  }
}

Pagination.defaultProps = {
  pageCount: 1,
  selectedPage: 1,
};

Pagination.propTypes = {
  pageCount: PropTypes.number,
  handlePagination: PropTypes.func.isRequired,
  selectedPage: PropTypes.number,
};

export default Pagination;
