import React from 'react';

// Import the logo image
import logo from '../img/PCSYMBOL.png';

export default function CenteredSpinner() {
  return (
    <div className="position-absolute top-50 start-50 translate-middle text-dark">
      {/* Use a custom CSS class 'logo-spinner' for the animation */}
      <div className="logo-spinner">
        <img src={logo} height={30} alt="Loading..." />
      </div>
    </div>
  );
}
