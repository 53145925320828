import React from 'react';

// Import the logo image
import logo from '../img/PCSYMBOL.png';

export default function CenteredSpinner() {
  return (
    <div className="logo-spinner">
      <img src={logo} height={30} alt="Loading..." />
    </div>
  );
}
