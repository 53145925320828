import React, { Component } from 'react';
import PropTypes from 'prop-types';

class DeleteListModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: React.createRef(),
    };
    this.handleClickOutsideModal = this.handleClickOutsideModal.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutsideModal);
    document.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutsideModal);
    document.removeEventListener('keydown', this.onKeyDown);
  }

  handleClickOutsideModal(e) {
    const { modal } = this.state;
    const { toggleDeleteListModal } = this.props;
    if (!modal.current.contains(e.target)) toggleDeleteListModal({});
  }

  onKeyDown(e) {
    console.log(e);
    const { deleteList, toggleDeleteListModal, list } = this.props;
    if (e?.key === 'Enter') {
      deleteList({
        listId: list._id,
      });
      toggleDeleteListModal();
    }
    if (e?.key === 'Escape') {
      toggleDeleteListModal();
    }
  }

  render() {
    const {
      modal,
    } = this.state;

    const {
      list,
      toggleDeleteListModal,
      isOpen,
      deleteList,
      loading,
    } = this.props;

    const { name } = list;

    return (
      <div
        className={`modal ${isOpen ? 'show' : ''}`}
        style={{
          display: isOpen ? 'block' : 'none',
        }}
        ref={modal}
      >
        <div
          className="modal-dialog modal-dialog-centered"
        >
          <div
            className="modal-content"
          >
            <div className="modal-header">
              <h5 className="modal-title">
                Vai tiešām vēlies dzēst sarakstu?
                <br />
                {' "'}
                {name}
                {'" '}
              </h5>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={toggleDeleteListModal}
              />
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-danger fw-bold"
                onClick={() => {
                  deleteList({
                    listId: list._id,
                  });
                  toggleDeleteListModal();
                }}
              >
                {loading ? (
                  <div className="d-flex justify-content-center mb-5 mt-5">
                    <div className="spinner-border mt-4 mb-3" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : 'Dzēst'}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DeleteListModal.defaultProps = {
  loading: false,
};

DeleteListModal.propTypes = {
  list: PropTypes.object.isRequired,
  toggleDeleteListModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  deleteList: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default DeleteListModal;
