// import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import CenteredSpinner from '../../components/centeredSpinner';
import AcceptancePopup from '../../popUps/acceptancePopup';
// import openChangePasswordModal from '../../popUps/openChangePasswordModal';
import ChangePasswordModal from './components/changePasswordModal';
import Floaty from '../../popUps/floaty';
import Alert from '../../components/alert';

class UserProfile extends Component {
  constructor(props) {
    super(props);
    const user = {};
    user.username = localStorage.getItem('username');
    user._id = localStorage.getItem('userId');
    this.state = {
      photoUrl: 'https://st3.depositphotos.com/6672868/13701/v/450/depositphotos_137014128-stock-illustration-user-profile-icon.jpg',
      isDropdownOpen: false,
      loading: true,
      user,
      deletePopup: false,
      openChangePasswordModal: false,
      changePasswordLoading: false,
      notificationStatus: null,
      notificationMsg: '',
      redirectToHome: false,
      alert: false,
    };
    this.dropdownRef = React.createRef();

    this.getUser = this.getUser.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.handleClickOutsideDropdown = this.handleClickOutsideDropdown.bind(this);

    this.toggleDeletePopup = this.toggleDeletePopup.bind(this);
    this.handleDeleteAccount = this.handleDeleteAccount.bind(this);

    this.toggleChangePasswordModal = this.toggleChangePasswordModal.bind(this);
    this.changePassword = this.changePassword.bind(this);

    this.handleLogout = this.handleLogout.bind(this);
  }

  componentDidMount() {
    document.title = 'Profils';
    this.getUser();
    document.addEventListener('mousedown', this.handleClickOutsideDropdown);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutsideDropdown);
  }

  handleClickOutsideDropdown(event) {
    if (this?.dropdownRef && !this.dropdownRef?.current?.contains(event?.target)) {
      this.setState({ isDropdownOpen: false });
    }
  }

  handleDeleteAccount() {
    this.setState({
      loading: true,
    });
    const { user } = this.state;
    const { _id } = user;
    fetch(`/api/users/${_id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((res) => {
        const {
          status, message,
        } = res;
        if (status === 'success') {
          this.setState({
            redirectToHome: true,
          });
        } else {
          this.setState({
            notificationMsg: message,
            notificationStatus: status,
            loading: false,
          });
        }
      }).catch((ex) => {
        this.setState({
          notificationMsg: 'Neizdevās sazinaties ar serveri!',
          notificationStatus: 'error',
          loading: false,
        });
        console.error(ex);
      });
  }

  handleLogout(e) {
    e.preventDefault();
    this.setState({
      loading: true,
    });

    fetch('/api/auth/logout', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((res) => {
        const { status, message } = res;
        if (status === 'success') {
          localStorage.removeItem('image');
          localStorage.removeItem('username');
          localStorage.removeItem('userId');
          this.setState({ redirectToHome: true });
        } else {
          this.setState({
            notificationMsg: message,
            notificationStatus: status,
            loading: false,
          });
        }
      })
      .catch((err) => {
        console.error(err, 'log out error');
        this.setState({
          loading: false,
          notificationMsg: 'Neizdevās sazināties ar serveri',
          notificationStatus: 'error',
        });
      });
  }

  getUser() {
    // this.setState({ loading: true });
    const { user = {} } = this.state;
    const { _id } = user;

    if (!_id) {
      this.setState({
        redirectToHome: true,
      });
      return;
    }

    fetch(`/api/users/${_id}`)
      .then((res) => res.json())
      .then((res) => {
        const { status, data, message } = res;
        if (status === 'success') {
          this.setState({
            user: data.user,
            loading: false,
          });
        } else {
          this.setState({
            loading: false,
            notificationMsg: message,
            notificationStatus: status,
          });
        }
      }).catch((ex) => {
        console.error(ex);
        this.setState({
          notificationMsg: 'Neizdevās sazināties ar serveri',
          notificationStatus: 'error',
          loading: false,
        });
      });
  }

  changePassword({ newPassword }) {
    this.setState({
      changePasswordLoading: true,
    });
    const { user } = this.state;
    const { _id } = user;
    fetch(`/api/users/changePassword/${_id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ password: newPassword }),
    })
      .then((res) => res.json())
      .then((res) => {
        const {
          status, message,
        } = res;
        if (status === 'success') {
          this.toggleChangePasswordModal();
          this.setState({
            notificationMsg: message,
            notificationStatus: status,
            changePasswordLoading: false,
          });
        } else {
          this.toggleChangePasswordModal();
          this.setState({
            notificationMsg: message,
            notificationStatus: status,
            changePasswordLoading: false,
          });
        }
      }).catch((ex) => {
        this.setState({
          notificationMsg: 'Neizdevās sazināties ar serveri',
          changePasswordLoading: false,
          notificationStatus: 'error',
        });
        console.error(ex);
      });
  }

  toggleDeletePopup() {
    const { deletePopup } = this.state;
    this.toggleDropdown();
    this.setState({ deletePopup: !deletePopup });
  }

  toggleChangePasswordModal() {
    const { openChangePasswordModal } = this.state;
    this.toggleDropdown();
    this.setState({ openChangePasswordModal: !openChangePasswordModal });
  }

  toggleDropdown() {
    const { isDropdownOpen } = this.state;
    this.setState({ isDropdownOpen: !isDropdownOpen });
  }

  render() {
    const {
      user, photoUrl, isDropdownOpen, loading,
      deletePopup, openChangePasswordModal, changePasswordLoading,
      notificationMsg, notificationStatus, redirectToHome,
      alert,
    } = this.state;

    if (redirectToHome) return <Redirect to="/" />;

    if (loading) return <CenteredSpinner />;

    if (alert) {
      return (
        <Alert />
      );
    }

    return (
      <>
        {notificationMsg
          ? (
            <Floaty
              status={notificationStatus}
              message={notificationMsg}
              onClose={() => this.setState({ notificationMsg: '' })}
            />
          )
          : null}
        <div className="container mt-5 position-relative">
          <div className="profile-header">
            <div className="d-flex align-items-center flex-column">
              <img
                className="rounded-circle"
                style={{ width: 70 }}
                src={photoUrl}
                alt="User"
              />
              <div className="profile-info-row d-flex align-items-center mt-2">
                <span>{user?.email}</span>
                <button
                  onClick={this.handleLogout}
                  title="Iziet no profila"
                  type="button"
                  className="ms-2 btn"
                  label="Iziet"
                >
                  <i
                    className="fa fa-sign-out fs-4"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
          </div>
          <div className="profile-content position-relative mt-5">
            {/* <div className="border p-3 h-100 col-md-4 mb-3">
              <NavLink
                role="button"
                //   onClick={() => this.onChange({ name: 'allItems' })}
                tabIndex={0}
                className="ms-1 bg-light w-100 text-center rounded p-1 hover-light"
                to="/lists"
              >
                <span>
                  mani Saraksti
                </span>
              </NavLink>
            </div> */}

            <div className="h-100 position-relative mb-5 d-flex justify-content-end">
              <div
                className="mb-5 d-flex flex-column align-items-end position-absolute end-0"
                style={{ zIndex: 1 }}
                ref={this.dropdownRef}
              >
                <button
                  type="button"
                  className="toggle-dropdown-button fs-1 d-flex p-2 shadow-none border-0 rounded-2"
                  style={{ lineHeight: 0 }}
                  onClick={this.toggleDropdown}
                  label="Iespējas"
                >
                  <i className="fa fa-ellipsis-v" aria-hidden="true" />
                </button>
                {isDropdownOpen && (
                  <div className="dropdown-content card">
                    <div className="card-body d-flex flex-column">
                      <button
                        type="button"
                        className="delete-account-button btn btn-secondary my-1"
                        onClick={this.toggleChangePasswordModal}
                      >
                        <b>mainīt</b>
                        {' '}
                        paroli
                      </button>
                      <button
                        type="button"
                        className="delete-account-button btn btn-danger my-1"
                        onClick={this.toggleDeletePopup}
                      >
                        <b>dzēst</b>
                        {' '}
                        profilu
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>

          </div>
          {deletePopup ? (
            <AcceptancePopup
              content="Vai tiešām vēlies dzēst savu kontu?"
              onSubmit={this.handleDeleteAccount}
              onClose={this.toggleDeletePopup}
              isOpen={deletePopup}
            />
          ) : null}
          {openChangePasswordModal ? (
            <ChangePasswordModal
              // onClose={this.toggleChangePasswordModal}
              isOpen={openChangePasswordModal}
              onSubmit={this.changePassword}
              toggleChangePasswordModal={this.toggleChangePasswordModal}
              loading={changePasswordLoading}
            />
          ) : null}
        </div>
      </>
    );
  }
}
// UserProfile.propTypes = {
//   user: PropTypes.object.isRequired,
// history: PropTypes.shape({
//   push: PropTypes.func.isRequired,
// }).isRequired,
// };
export default UserProfile;
