import React from 'react';
// import jwtDecode from 'jwt-decode';
import { NavLink, Redirect } from 'react-router-dom';
import Poster from '../../img/posters/avenes-udeni-4k.webp';
import Message from '../../components/message';
import CenteredSpinner from '../../components/centeredSpinner';

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // googleUser: {},
      message: null,
      status: null,
      loading: false,
      submitLoading: false,
      redirectToEmailSentPage: false,
    };
    // this.onGoogleCallback = this.onGoogleCallback.bind(this);
    // this.onGoogleSignIn = this.onGoogleSignIn.bind(this);
    // this.onSignOut = this.onSignOut.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  // componentDidMount() {
  //   /* global google */
  //   google?.accounts.id.initialize({
  //     client_id: '84386383254-uvte12402dppj7uq99vvisr96offltmm.apps.googleusercontent.com',
  //     callback: this.onGoogleCallback,
  //   });

  //   google?.accounts.id.renderButton(
  //     document.getElementById('googleSignIn'),
  //     {
  //       theme: 'outline',
  //       size: 'large',
  //       scope: 'profile email',
  //     },
  //   );

  //   const { googleUser } = this.state;
  //   if (!Object.keys(googleUser).length) {
  //     google?.accounts.id.prompt();
  //   }
  // }

  // onGoogleCallback(res) {
  // const googleUser = jwtDecode(res.credential);
  // const { setUser } = this.context;
  // this.setState({ googleUser });
  // setUser(googleUser);
  // setUser(googleUser);
  // }

  // onGoogleSignIn(googleUser) {
  //   const userProfile = googleUser.getBasicProfile();
  //   const email = userProfile.getEmail();
  //   const name = userProfile.getName();

  //   const formData = {
  //     email,
  //     name,
  //     // googleAccessToken,
  //   };
  //   fetch('/api/auth/register', {
  //     method: 'POST',
  //     body: JSON.stringify(formData),
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       console.log(data);
  //       this.setState({
  //         loading: false,
  //       });
  //     })
  //     .catch((error) => console.error(error));
  // }

  onChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  // onSignOut() {
  //   this.setState({ googleUser: {} });
  // }

  onSubmit(e) {
    e.preventDefault();
    const url = window.location.href;
    const arr = url.split('/');
    const domain = `${arr[0]}//${arr[2]}`;
    console.log({ domain });
    this.setState({
      submitLoading: true,
    });
    const {
      email,
      // password,
      // googleAccessToken,
    } = this.state;

    fetch('/api/auth/register', {
      method: 'POST',
      body: JSON.stringify({
        email,
        // password,
        domain,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((res) => {
        const { status, message } = res;
        if (status === 'success') {
          this.setState({
            submitLoading: false,
            redirectToEmailSentPage: true,
          });
        } else {
          this.setState({
            submitLoading: false,
            message,
            status,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          submitLoading: false,
          message: 'Neizdevās Jums nosūtīt e-pastu 😥',
        });
      });
  }

  render() {
    const {
      loading, message, status, submitLoading,
      redirectToEmailSentPage, email,
    } = this.state;

    if (redirectToEmailSentPage) {
      return <Redirect to={`/email-sent?email=${email}`} />;
    }

    if (loading) return <CenteredSpinner />;

    return (
      <div
        className="container d-flex justify-content-between align-items-center"
        style={{ height: '100vh' }}
      >
        {/* LOGIN PUSE */}
        <div className="col-md-6 col-12 d-flex justify-content-center align-items-center flex-column">
          <form
            style={{
              backgroundColor: '#d0d0d7',
              padding: '20px',
              borderRadius: '10px',
              width: '300px',
            }}
            onSubmit={this.onSubmit}
          >
            <Message message={message} status={status} className="px-2" />

            <div>
              <div className="d-flex flex-column align-items-center">
                <div className="form-floating">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="form-control border shadow-none rounded-3 px-2 fs-5"
                    onChange={this.onChange}
                    autoComplete="email"
                    placeholder="E-pasts:"
                  />
                  <label
                    htmlFor="email"
                    className="text-grey small"
                  >
                    E-pasts:
                  </label>
                </div>
                {/* <div className="form-floating mt-2">
                  <input
                    type="password"
                    name="password"
                    id="password"
                    className="form-control border shadow-none rounded-3 px-2"
                    onChange={this.onChange}
                    autoComplete="new-password"
                  />
                  <label htmlFor="password">
                    Parole:
                  </label>
                </div> */}
                <button
                  type="submit"
                  className="text-light border-0 px-3 py-2 fw-bold rounded-3 mt-3"
                  style={{
                    backgroundColor: '#ffa536',
                    fontSize: '14px',
                  }}
                >
                  {submitLoading ? (
                    <div className="spinner-grow spinner-grow-sm mx-5" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : 'REĢISTRĒTIES'}
                </button>
                <NavLink
                  to="/login"
                  className="text-end col-12 grey-link text-decoration-none small mt-3"
                >
                  Ienākt
                </NavLink>
              </div>
            </div>
          </form>
          <div className="mt-3">
            {/* auth iespējas */}
            {/* GOOGLE */}
            {/* <div
              className="g-signin2"
              id="googleSignIn"
              data-onsuccess="this.onGoogleSignIn"
              data-theme="dark"
              data-width="50"
              data-height="50"
            /> */}
          </div>
        </div>
        {/* BILDES PUSE */}
        <div className="col-6 d-none d-md-block">
          <img src={Poster} className="" height={700} alt="ProductCave" />
        </div>
        <div />
      </div>
    );
  }
}

export default RegisterPage;
