import React, { Component } from 'react';
import PropTypes from 'prop-types';

class AddToListsDropdown extends Component {
  constructor() {
    super();
    this.state = ({ });
    this.changeQty = this.changeQty.bind(this);
  }

  async changeQty({ selectedListId, increment }) {
    const { handleProductChanges, baseProduct, lists } = this.props;

    const list = lists[selectedListId];
    const { productsQtyPerList } = list;
    const qtyInList = productsQtyPerList[baseProduct._id] || 0;

    const newQty = qtyInList + increment;

    await handleProductChanges({ listId: list._id, baseProductId: baseProduct._id, qty: newQty });
  }

  render() {
    const {
      lists,
      baseProduct,
      className,
      loading,
    } = this.props;

    return (
      <div
        className={`btn-group dropdown me-1 ${className || ''}`}
      >
        <div
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          className="d-flex border rounded-3 px-1"
          style={{ height: 30 }}
        >
          <span
            className="fs-3 lh-1"
          >
            +
          </span>
        </div>
        <div
          className="dropdown-menu py-1"
          onClick={(e) => e.preventDefault()}
          role="button"
          tabIndex={0}
        >
          {(lists && Object.keys(lists)?.length) ? Object.keys(lists).map((key) => {
            const list = lists[key] || {};
            const { productsQtyPerList, productsPerListLimitExceeded } = list;
            const qtyInList = productsQtyPerList[baseProduct._id] || 0;
            const listIsFull = productsPerListLimitExceeded && !qtyInList;
            return (
              <div
                key={list._id}
                className="py-1 d-flex align-items-center justify-content-between mx-1"
                tabIndex={-3}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                role="button"
              >
                <span
                  className={`col-8 text-nowrap overflow-hidden me-1 ${listIsFull ? 'text-grey' : ''}`}
                  style={{ textOverflow: 'ellipsis' }}
                >
                  {list.name}
                </span>
                <div
                  className="d-flex align-items-center"
                >
                  <div
                    title={listIsFull ? '' : '-1'}
                    role="button"
                    tabIndex={-1}
                    onClick={(e) => {
                      if (listIsFull || loading || !qtyInList) return;
                      e.preventDefault();
                      e.stopPropagation();
                      this.changeQty({ selectedListId: list._id, increment: -1 });
                    }}
                    className={`pe-1 ${listIsFull || loading || !qtyInList ? 'text-grey' : ''}`}
                  >
                    <span
                      className="fs-3 lh-1"
                    >
                      -
                    </span>
                  </div>
                  <span
                    className={`fw-bold fs-5 text-center ${listIsFull ? 'text-grey' : ''}`}
                    title={listIsFull ? 'saraksts ir pilns' : ''}
                    style={{ width: 25 }}
                  >
                    {qtyInList}
                  </span>
                  <div
                    role="button"
                    tabIndex={0}
                    title={listIsFull ? 'saraksts ir pilns' : '+1'}
                    onClick={(e) => {
                      if (listIsFull || loading) return;
                      e.preventDefault();
                      e.stopPropagation();
                      this.changeQty({ selectedListId: list._id, increment: 1 });
                    }}
                    className={`ps-1 ${listIsFull || loading ? 'text-grey' : ''}`}
                  >
                    <span
                      className="fs-3 lh-1"
                    >
                      +
                    </span>
                  </div>
                </div>
              </div>
            );
          }) : null}
        </div>
      </div>
    );
  }
}

AddToListsDropdown.defaultProps = {
  lists: {},
  handleProductChanges: () => {},
  className: '',
  loading: false,
};

AddToListsDropdown.propTypes = {
  baseProduct: PropTypes.object.isRequired,
  lists: PropTypes.object,
  handleProductChanges: PropTypes.func,
  loading: PropTypes.bool,
  // addProductToList: PropTypes.func.isRequired,
  className: PropTypes.string,
//   toggleAddProductSuggestionModal: PropTypes.func.isRequired,
};

export default AddToListsDropdown;
