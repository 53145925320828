import React, { Component } from 'react';
import PropTypes from 'prop-types';

class AcceptancePopup extends Component {
  constructor() {
    super();
    this.state = {
      modal: React.createRef(),
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.handleClickOutsideModal = this.handleClickOutsideModal.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutsideModal);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutsideModal);
  }

  handleClickOutsideModal(e) {
    const { modal } = this.state;
    const { onClose } = this.props;
    if (!modal?.current?.contains(e.target)) onClose();
  }

  onSubmit() {
    const { onSubmit } = this.props;
    onSubmit();
  }

  render() {
    const { modal } = this.state;
    const {
      content, title, onClose, isOpen, loading,
    } = this.props;

    return (
      <div
        className={`modal ${isOpen ? 'show' : ''}`}
        style={{
          display: isOpen ? 'block' : 'none',
        }}
      >
        <div className="modal-backdrop z-index-1" style={{ display: isOpen ? 'block' : 'none' }} />
        <div className="modal-dialog modal-dialog-centered z-index-2">
          <form
            className="modal-content"
            ref={modal}
          >
            <div className="modal-header">
              <h5 className="modal-title">{title}</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onClose}
              />
            </div>
            <div className="modal-body">
              <p>{content}</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={onClose}
                disabled={loading}
              >
                Nē
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.onSubmit}
                disabled={loading}
              >
                {loading ? (
                  <div className="spinner-grow spinner-grow-sm" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : 'Jā'}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
AcceptancePopup.defaultProps = {
  title: '',
  loading: false,
};

AcceptancePopup.propTypes = {
  content: PropTypes.node.isRequired,
  title: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
};

export default AcceptancePopup;
