import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MissingShopProductCard from './missingShopProductCard';
import ProductCard from '../../../components/productCard';

class ShopsProducts extends Component {
  constructor() {
    super();
    this.state = ({
    });
  }

  render() {
    const {
      shopsProducts, baseProduct, missingShops,
      onShopProductSelect, toggleAddProductSuggestionModal,
      toggleDeleteProductSuggestionModal, selectedProduct,
    } = this.props;

    return (
      <>
        <h3 className="fs-5 fw-bold mt-3">
          {baseProduct.name}
          {' citos veikalos'}
        </h3>
        <div className="d-flex table-responsive align-items-center" style={{ height: '360px' }}>
          {shopsProducts.sort((a, b) => a.minPrice - b.minPrice)
            .sort((a, b) => parseFloat(a.order) - parseFloat(b.order))
            .sort((a, b) => parseFloat(a?.daysSinceUpdate) - parseFloat(b?.daysSinceUpdate))
            .map((data) => {
              if (selectedProduct._id !== data?._id) {
                return (
                  <div
                    key={data?._id}
                    role="button"
                    tabIndex={0}
                    // onClick={() => {
                    //   onShopProductSelect({
                    //     selectedProduct: data,
                    //     shop: data?.shop,
                    //     shopsProduct: data?.shop ? data : null,
                    //   });
                    // }}
                    className="px-1 h-100 d-flex align-items-center"
                    style={{
                      maxWidth: '260px',
                      minWidth: '220px',
                    }}
                    label={data?.name}
                  >
                    <ProductCard
                      product={data}
                      disabled={data?.daysSinceUpdate > 2}
                      showRange
                      className="h-100"
                      toggleDeleteProductSuggestionModal={toggleDeleteProductSuggestionModal}
                      onClick={onShopProductSelect}
                    />
                  </div>
                );
              }
              return null;
            })}
          {missingShops?.length ? missingShops.map((missingShop) => (
            <div
              className="px-1 h-100 d-flex align-items-center"
              style={{
                maxWidth: '260px',
                minWidth: '220px',
              }}
              key={missingShop._id}
            >
              <MissingShopProductCard
                missingShop={missingShop}
                toggleAddProductSuggestionModal={toggleAddProductSuggestionModal}
                key={missingShop._id}
                // className="h-100"
              />
            </div>
          )) : null}
        </div>
      </>
    );
  }
}

ShopsProducts.defaultProps = {
  shopsProducts: [],
  missingShops: [],
  toggleAddProductSuggestionModal: () => {},
  toggleDeleteProductSuggestionModal: () => {},
  //   loading: false,
};

ShopsProducts.propTypes = {
  baseProduct: PropTypes.object.isRequired,
  selectedProduct: PropTypes.object.isRequired,
  shopsProducts: PropTypes.array,
  missingShops: PropTypes.array,
  onShopProductSelect: PropTypes.func.isRequired,
  toggleAddProductSuggestionModal: PropTypes.func,
  toggleDeleteProductSuggestionModal: PropTypes.func,
  //   className: PropTypes.string,
};

export default ShopsProducts;
