import React from 'react';
import PropTypes from 'prop-types';
// import './SuggestionTooltip.css'; // Import your CSS file

function SuggestionProductTooltip(props) {
  const { status } = props;

  if (status === 'pending') {
    return (
      <div
        className="pc-tooltip"
      >
        {/* info icon */}
        <i
          className="fa fa-info-circle"
          style={{
            width: 20,
            height: 20,
            color: 'darkorange',
          }}
        />
        <div className="pc-tooltiptext">
          Šis ieteikums ir redzams tikai Jums.
          Līdz Mēs to apstiprināsim.
        </div>
      </div>
    );
  }

  if (status === 'approved') {
    return (
      <div
        className="pc-tooltip"
      >
        {/* check icon */}
        <i
          className="fa fa-check-circle"
          style={{
            width: 20,
            height: 20,
            color: 'green',
          }}
        />
        <div className="pc-tooltiptext">
          <strong>
            Paldies!
          </strong>
          <br />
          Šis ieteikums ir apstiprināts!
        </div>
      </div>
    );
  }

  if (status === 'rejected') {
    return (
      <div
        className="pc-tooltip"
      >
        {/* x icon */}
        <i
          className="fa fa-times-circle"
          style={{
            width: 20,
            height: 20,
            color: 'red',
          }}
        />
        <div className="pc-tooltiptext">
          <strong>
            Atvainojiet!
          </strong>
          <br />
          Šis ieteikums ir noraidīts.
        </div>
      </div>
    );
  }

  return null;
}

SuggestionProductTooltip.defaultProps = {
  status: 'pending',
};

SuggestionProductTooltip.propTypes = {
  status: PropTypes.string,
};

export default SuggestionProductTooltip;
