import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FaSearch } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';
import SearchAllNavlink from '../../../components/autoComplete/components/searchAllNavlink';
import CenteredSpinner from '../../../components/centeredSpinner';
import PriceChart from '../../singleProduct/components/priceChart';

function getBorderColorByConfindence(confidence) {
  if (confidence >= 70) {
    return 'high-confidence';
  } if (confidence >= 40) {
    return 'medium-confidence';
  }
  return 'low-confidence';
}

class AddAltProductModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchLoading: false,
      modal: React.createRef(),
      searchInput: React.createRef(),
      dropdown: React.createRef(),
      openDropdown: false,
      products: [],
      substring: '',
      // search: '',
      productCount: 0,
      selectedProduct: null,
      timeout: null,
      selectLoading: false,
      selectedProductIndex: -1,
      closestBaseProducts: [],
    };
    this.searchController = new AbortController();

    this.handleClickOutsideModal = this.handleClickOutsideModal.bind(this);
    this.handleClickOutsideSearch = this.handleClickOutsideSearch.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onProductSelect = this.onProductSelect.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.scrollToSelectedIndex = this.scrollToSelectedIndex.bind(this);
    this.getClosestBaseProducts = this.getClosestBaseProducts.bind(this);
  }

  componentDidMount() {
    const { searchInput } = this.state;
    document.addEventListener('mousedown', this.handleClickOutsideSearch);
    document.addEventListener('mousedown', this.handleClickOutsideModal);
    searchInput.current.addEventListener('keydown', this.handleKeyDown);
    this.getClosestBaseProducts();
  }

  componentWillUnmount() {
    const { searchInput } = this.state;
    document.removeEventListener('mousedown', this.handleClickOutsideModal);
    document.removeEventListener('mousedown', this.handleClickOutsideSearch);
    searchInput.current.removeEventListener('keydown', this.handleKeyDown);
    this.searchController.abort();
  }

  handleClickOutsideModal(e) {
    const { modal } = this.state;
    const { toggleAddAltProductModal } = this.props;
    if (!modal.current.contains(e.target)) toggleAddAltProductModal({});
  }

  handleKeyDown(event) {
    const { selectedShop } = this.props;
    const { selectedProductIndex, products, substring } = this.state;
    if (event.keyCode === 13) {
      // Enter key pressed
      // console.log({ selectedProductIndex, prodLen: products.length - 1 });
      if (selectedProductIndex > -1 && selectedProductIndex !== products.length - 1) {
        this.onProductSelect({ product: products[selectedProductIndex] });
        this.setState({
          selectedProductIndex: -1,
        });
      } else if (selectedProductIndex > -1 && selectedProductIndex === products.length - 1) {
        // const { history } = this.props;
        // history.push(`/products/?search=${substring}&shop=${selectedShop?._id}`);
        window.open(`/products/?search=${substring}&shop=${selectedShop?._id}`, '_blank');
        this.setState({
          selectedProductIndex: -1,
        });
      }
    } else if (event.keyCode === 38 || event.keyCode === 40) {
      event.preventDefault();
      if (event.keyCode === 38) {
        // Up arrow key
        this.setState({
          selectedProductIndex: Math.max(0, selectedProductIndex - 1),
        });
        this.scrollToSelectedIndex();
      } else if (event.keyCode === 40) {
        // Down arrow key
        this.setState({
          selectedProductIndex: Math.min(
            products.length - 1,
            selectedProductIndex + 1,
          ),
        });
        this.scrollToSelectedIndex();
      }
    }
  }

  async handleClickOutsideSearch(e) {
    const {
      dropdown, searchInput, openDropdown,
    } = this.state;
    if (
      !dropdown?.current?.contains(e.target)
        && !searchInput?.current?.contains(e.target)
    ) {
      this.setState({
        openDropdown: false,
      });
    } else if (searchInput?.current?.contains(e.target)
    ) {
      this.setState({
        openDropdown: !openDropdown,
      });
    } else {
      this.setState({
        openDropdown: true,
      });
    }
  }

  onProductSelect({ product }) {
    if (!product) return;
    this.setState({
      openDropdown: false,
      products: [],
      selectLoading: true,
      substring: '',
    });

    // iegūstam izvēleto produktu no servera, jau pārveidotu kā altProduct;
    fetch(`/api/lists/alternative?altProductId=${product._id}`)
      .then((res) => res.json())
      .then((res) => {
        const { altProduct, status } = res;
        if (status) {
          this.setState({
            selectedProduct: altProduct,
            selectLoading: false,
          });
        } else {
          this.setState({
            selectLoading: false,
          });
        }
      }).catch((ex) => {
        console.log(ex);
        this.setState({
          selectLoading: false,
        });
      });

    let { closestBaseProducts } = this.state;
    const selectedProductInClosestProducts = closestBaseProducts
      .find((each) => each._id === product._id);

    if (!selectedProductInClosestProducts) {
      // put product in start of closestBaseProducts
      closestBaseProducts = [{ ...product, manuallyAdded: true }, ...closestBaseProducts];
    }
    this.setState({
      closestBaseProducts,
    }, () => {
      this.setState({
        refreshPriceChart: true,
      });
    });
  }

  onSearch(substring) {
    this.setState({ substring });
    if (substring?.length < 2) {
      this.setState({ products: [], searchLoading: false });
      if (this.searchController) {
        this.searchController.abort();
      }
      return;
    }
    let { timeout } = this.state;
    clearTimeout(timeout);

    if (this.searchController) {
      this.searchController.abort();
      // this.setState({ searchLoading: false });
      this.searchController = new AbortController();
    }

    timeout = setTimeout(() => {
      this.setState({
        // search: substring,
        searchLoading: true,
        openDropdown: true,
      });
      const { selectedShop } = this.props;

      fetch(`/api/lists/autofill?search=${substring}&shopId=${selectedShop?._id}`, {
        signal: this.searchController.signal,
      })
        .then((res) => res.json())
        .then((res) => {
          const { products, status, productCount } = res;
          if (status) {
            this.setState({
              products,
              searchLoading: false,
              productCount,
              selectedProductIndex: productCount === 1 ? 0 : -1,
            });
          } else {
            this.setState({
              searchLoading: false,
            });
          }
        }).catch((ex) => {
          if (!ex.name || ex.name !== 'AbortError') {
            console.log(ex);
            this.setState({
              searchLoading: false,
            });
          } else {
            this.setState({
              searchLoading: false,
            });
          }
        });
    }, 1000);
    this.setState({ timeout });
  }

  getClosestBaseProducts() {
    const { product, selectedShop } = this.props;

    fetch(`/api/products/closest?shopId=${selectedShop._id}&baseProductId=${product.product._id}`)
      .then((res) => res.json())
      .then((res) => {
        const {
          status,
          data: closestBaseProducts,
        } = res;
        if (status === 'success') {
          this.setState({
            loading: false,
            closestBaseProducts,
          });
        } else {
          this.setState({
            loading: false,
          });
        }
      }).catch((ex) => {
        console.log(ex);
        this.setState({
          loading: false,
        });
      });
  }

  scrollToSelectedIndex() {
    const { selectedProductIndex, dropdown } = this.state;
    const list = dropdown.current;
    const item = list.children[selectedProductIndex];
    item?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }

  render() {
    const {
      modal, searchInput, openDropdown,
      searchLoading, dropdown,
      selectedProduct,
      selectLoading, selectedProductIndex,
      products,
      substring,
      productCount,
      loading,
      closestBaseProducts,
      refreshPriceChart,
    } = this.state;

    const {
      product,
      toggleAddAltProductModal,
      isOpen,
      addAlternative,
      listId,
      selectedShop,
    } = this.props;

    const { name, image, unit } = product.product;
    if (products?.length && !products?.some(({ navlink }) => navlink)) {
      products.push({ navlink: true });
    }
    const samePrices = product?.minPrice === product?.maxPrice;

    const isWeb = window.innerWidth > 768;

    return (
      <div
        className={`modal ${isOpen ? 'show' : ''}`}
        style={{
          display: isOpen ? 'block' : 'none',
        }}
      >
        <div className="modal-backdrop z-index-1" style={{ display: isOpen ? 'block' : 'none' }} />
        <div className="modal-dialog modal-dialog-centered z-index-2" style={{ maxWidth: 700 }}>
          <div
            className="modal-content"
            ref={modal}
          >
            <div className="modal-header">
              <h5 className="modal-title">
                Pievieno alternatīvu
              </h5>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={toggleAddAltProductModal}
              />
            </div>
            <div className="modal-body">
              {loading ? <CenteredSpinner /> : (
                <div>
                  {!selectLoading ? (
                    <div className="d-flex flex-sm-row flex-column border-bottom align-items-center">
                      <div
                        className="col-sm-3 col-4 d-flex flex-column
                            align-items-center justify-content-center"
                        style={{
                          minWidth: 150,
                        }}
                      >
                        <div className="d-block fw-bold mx-auto">
                          {samePrices
                            ? product?.minPrice : (
                              <>
                                {product?.minPrice}
                                -
                                {product?.maxPrice}
                              </>
                            )}
                          €
                          {unit ? ` /${unit}` : null}
                        </div>
                        <img src={image} alt="" className="col-12" />
                        <span className="d-sm-block d-none text-center small fw-bold mt-1">{name}</span>
                      </div>
                      <span className="d-sm-none d-block text-center small fw-bold mt-1">{name}</span>
                      <div className="col-sm-9 col-12 d-flex  justify-content-center">
                        <PriceChart
                          minimalistic
                          period="6months"
                          baseProductId={product.product._id}
                          additionalBaseProductId={selectedProduct?._id}
                          refreshPriceChart={refreshPriceChart}
                          selectedShop={selectedShop}
                          hideSuggestions
                        />
                      </div>
                      {/* price chart */}
                    </div>
                  ) : null}
                  <div
                    className="d-flex border rounded mt-3 col-12"
                  >
                    <div
                      className="dropdown w-100"
                    >
                      <input
                        className="form-control text-nowrap border-0 p-2 ps-3 pe-3 bg-white shadow-none"
                        id="categories"
                        type="text"
                        name="search"
                        autoComplete="off"
                        autoFocus={isWeb}
                        onChange={(e) => {
                          this.onSearch(e.target.value);
                        }}
                        ref={searchInput}
                        placeholder="meklē šeit..."
                        value={substring}
                      />
                      <div
                        className={`dropdown-menu w-100 ${(openDropdown && products?.length || searchLoading) ? 'show' : 'd-none'}`}
                        style={{
                          overflowY: 'auto',
                          maxHeight: '350px',
                        }}
                        ref={dropdown}
                      >
                        {searchLoading ? (
                          <div
                            className="spinner-border spinner-border-sm text-dark ms-2 me-2 mt-2"
                            role="status"
                          />
                        ) : null}
                        {(products?.length && !searchLoading) ? products.map((each, index) => {
                          if (each?._id) {
                            return (
                              <div
                                key={each?._id}
                                role="button"
                                tabIndex={0}
                                onClick={() => this.onProductSelect({ product: each })}
                                onKeyDown={({ keyCode }) => {
                                  if (keyCode === 13) {
                                    this.onProductSelect({ product: each });
                                  }
                                }}
                                title={each?.name}
                              >

                                <div
                                  className={`rounded-1 m-1 p-1 ps-2 small
                                 text-dark hover-light d-flex justify-content-between
                                 ${selectedProductIndex === index ? 'bg-darker' : ''}`}
                                >
                                  <div className="d-flex align-items-center col-10">
                                    <img
                                      style={{ width: '50px' }}
                                      className="me-2"
                                      src={each?.image}
                                      alt=""
                                    />
                                    <span className="text-truncate">
                                      {/* {truncateName(each?.name)} */}
                                      {each?.name}
                                    </span>
                                  </div>
                                  <div className="col-2 d-flex flex-column align-items-end">
                                    {each?.shop?.lastPrice
                                      ? `${each?.shop?.lastPrice}€ `
                                      : null}
                                    {each?.shop ? (
                                      <img
                                        src={each?.shop?.image}
                                        alt={each?.shop?.name}
                                        style={{ maxWidth: 30 }}
                                      />
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            );
                          }
                          return (
                            <SearchAllNavlink
                              key="searchLink"
                              isSelected={selectedProductIndex === products?.length - 1}
                              products={products}
                              search={substring}
                              selectedShop={selectedShop}
                              productCount={productCount}
                              exceedsLimit={productCount === products?.length}
                            />
                          );
                        }) : null}
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="border-0 p-2 ps-3 pe-3"
                      label="Meklēt"
                    >
                      <FaSearch />
                    </button>
                  </div>
                  {selectLoading
                    ? (
                      <div
                        className="spinner-border spinner-border-sm text-dark ms-2 me-2 mt-2"
                        role="status"
                      />
                    )
                    : null}
                  <div>
                    {closestBaseProducts?.length ? (
                      <div className="mt-2">
                        <h5>
                          {selectedShop?.name ? `${selectedShop?.name} alternatīvas` : null}
                        </h5>
                        <div className="d-flex table-responsive">
                          {closestBaseProducts.map((closestProduct) => {
                            const { _id, manuallyAdded, shop } = closestProduct;
                            const isSelected = selectedProduct?._id === _id;
                            return (
                              <div
                                className="px-1 position-relative"
                                key={closestProduct._id}
                                style={{
                                  minWidth: 130,
                                  maxWidth: 200,
                                }}
                              >
                                {manuallyAdded
                                  ? (
                                    <button
                                      type="button"
                                      className="btn-close small position-absolute top-0 end-0 pe-3 pt-1 shadow-none"
                                      aria-label="Close"
                                      onClick={() => {
                                        const filteredShopProducts = closestBaseProducts
                                          .filter((each) => each._id !== _id);

                                        this.setState({
                                          closestBaseProducts: filteredShopProducts,
                                          selectedProduct: isSelected ? null : selectedProduct,
                                          refreshPriceChart: isSelected,
                                        });
                                      }}
                                      style={{ zIndex: 2 }}
                                    />
                                  )
                                  : null}
                                <div
                                  className={`h-100 border ${isSelected ? 'shadow-sm' : ''} rounded-3 d-flex flex-column justify-content-between product-container`}
                                  onClick={() => this.onProductSelect({
                                    product: closestProduct,
                                  })}
                                  tabIndex={0}
                                  role="button"
                                >
                                  <div className="col-12 d-flex justify-content-between px-1 pt-1">
                                    {closestProduct?.confidence ? (
                                      <div
                                        style={{ height: 35, width: 35 }}
                                        className={`border border-2 rounded-circle d-flex 
                                justify-content-center align-items-center small text-nowrap
                                 ${getBorderColorByConfindence(closestProduct.confidence)}
                                    `}
                                      >
                                        {closestProduct.confidence}
                                        %
                                      </div>
                                    ) : null}
                                    <div>
                                      {shop?.lastPrice ? (
                                        <span className={`${isSelected ? 'fw-bold' : ''}`}>
                                          {shop?.lastPrice}
                                          €
                                        </span>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="image-container text-center">
                                    <img src={closestProduct.image} alt="" className="col-10" />
                                  </div>
                                  <span className={`text-center small mt-1 ${isSelected ? 'fw-bold' : ''} `}>{closestProduct?.name}</span>
                                  <span className="hover-effect">+</span>
                                </div>
                              </div>
                            );
                          })}

                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  addAlternative({
                    listId,
                    altProduct: selectedProduct,
                    listProductId: product._id,
                  });
                  toggleAddAltProductModal({});
                }}
                disabled={!selectedProduct || selectLoading}
              >
                Pievienot
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AddAltProductModal.propTypes = {
  product: PropTypes.object.isRequired,
  toggleAddAltProductModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  addAlternative: PropTypes.func.isRequired,
  listId: PropTypes.string.isRequired,
  selectedShop: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};
export default withRouter(AddAltProductModal);
