import React, { Component } from 'react';

class ShopsMap extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      // shops: [],
    };
    this.getShops = this.getShops.bind(this);
  }

  componentDidMount() {
    this.getShops();
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
  }

  getShops() {
    fetch('/api/shops/')
      .then((res) => res.json())
      .then((res) => {
        const { status, message } = res;
        if (status) {
          this.setState({
            // shops,
            loading: false,
          });
        } else {
          this.setState({
            loading: false,
            // message,
          });
          console.log(message);
        }
      }).catch((ex) => {
        console.log(ex);
        this.setState({
          // message: 'couldn`t fetch shops 😥',
          loading: false,
        });
      });
  }

  render() {
    const {
      loading,
    } = this.state;

    if (loading) {
      return (
        <div className="d-flex justify-content-center mb-5 mt-5">
          <div className="spinner-border mt-4 mb-3" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      );
    }

    return (
      <div className="container">
        <h2 className="text-center mt-3">Veikali</h2>
        <iframe
          title="places"
          src="https://www.google.com/maps/d/u/0/embed?mid=1Y2iP1V0-HRYPuby0Z_USge_0a5dh1tXw"
          width="100%"
          height="480"
          className="mt-2"
        />
      </div>
    );
  }
}

export default ShopsMap;
