import React, { Component } from 'react';
// import Logo from '../../img/logo.png';
import PCSymbol from '../img/PCSYMBOL.png';
import InstagramLogo from '../img/instagram.png';
import FacebookLogo from '../img/facebook.png';
import TwitterLogo from '../img/twitter.png';

class Footer extends Component {
  constructor() {
    super();

    this.state = {};
  }

  render() {
    return (
      <footer className="container-fluid footer d-flex flex-column pb-4">
        <div className="mt-4 mb-2 d-flex position-relative justify-content-center">
          <img
            src={PCSymbol}
            alt="ProductCave"
            title="ProductCave"
            className="img-fluid col-sm-2 col-1"
            style={{ maxWidth: 55, opacity: '75%' }}
          />
          <div className="position-absolute top-0 end-0 d-md-block d-none">
            <a
              href="https://www.instagram.com/product.cave/"
              target="_blank"
              rel="noreferrer"
              className="me-3"
            >
              <img src={InstagramLogo} alt="instagram" title="Droši seko mums arī šeit" />
            </a>
            <a
              href="https://www.facebook.com/productCave"
              target="_blank"
              rel="noreferrer"
              className="me-3"
            >
              <img src={FacebookLogo} alt="facebook" title="Tiekamies Facebook" />
            </a>
            <a
              href="https://twitter.com/ProductCave"
              target="_blank"
              rel="noreferrer"
              className="me-3"
            >
              <img src={TwitterLogo} alt="twitter" title="Esi lietas kursā ar mums" />
            </a>
          </div>
        </div>
        <div className="d-flex flex-wrap justify-content-center fw-light text-light mt-4 mb-md-5">
          <a
            href="mailto: productcaveofficial@gmail.com"
            className="text-decoration-none text-light"
          >
            <span
              className="me-3 mt-sm-0 mt-2 ms-3"
            >
              productcaveofficial@gmail.com
            </span>
          </a>
        </div>
        <div className="d-md-none mt-4 mb-3 d-flex justify-content-center">
          <a
            href="https://www.instagram.com/product.cave/"
            target="_blank"
            rel="noreferrer"
            className="me-3"
          >
            <img src={InstagramLogo} alt="instagram" title="Droši seko mums arī šeit" />
          </a>
          <a
            href="https://www.facebook.com/productCave"
            target="_blank"
            rel="noreferrer"
            className="me-3"
          >
            <img src={FacebookLogo} alt="facebook" title="Tiekamies Facebook" />
          </a>
          <a
            href="https://twitter.com/ProductCave"
            target="_blank"
            rel="noreferrer"
            className="me-3"
          >
            <img src={TwitterLogo} alt="twitter" title="Esi lietas kursā ar mums" />
          </a>
        </div>
      </footer>
    );
  }
}

export default Footer;
