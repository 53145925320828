import PropTypes from 'prop-types';
import React, { Component } from 'react';
import GmailLogo from '../../img/socials/gmail-icon.png';
import OutlookLogo from '../../img/socials/outlook-logo.png';
import YahooLogo from '../../img/socials/yahoo-logo.png';
import InboxLogo from '../../img/socials/inbox-logo.png';

class EmailSentPage extends Component {
  constructor(props) {
    super(props);
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const email = params.get('email');

    this.state = {
      email,
      socials: {
        inbox: {
          name: 'inbox',
          image: InboxLogo,
          link: 'https://inbox.lv/',
        },
        gmail: {
          name: 'gmail',
          image: GmailLogo,
          link: 'https://mail.google.com/',
        },
        outlook: {
          name: 'outlook',
          image: OutlookLogo,
          link: 'https://outlook.live.com/',
        },
        yahoo: {
          name: 'yahoo',
          image: YahooLogo,
          link: 'https://mail.yahoo.com/',
        },
      },
    };
    this.handleEmailLinkClick = this.handleEmailLinkClick.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    // Here you can add the logic to send the verification code to the provided email
    // For this example, we'll just log the email to the console
    const { email } = this.state;
    console.log('Verification code sent to:', email);
  }

  handleEmailLinkClick() {
    const { email } = this.state;
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile && email) {
      window.location.href = `mailto:${email}`;
    }
  }

  render() {
    const { email, socials } = this.state;
    return (
      <div className="text-center my-4">
        <h1>Mēs tev nosūtījām ziņu!</h1>
        <p>{email}</p>
        <div className="mt-4 mb-5">
          <div>
            {Object.keys(socials)?.length ? Object.keys(socials).map((key) => {
              const { link, image } = socials[key];
              return (
                <a
                  key={key}
                  href={link}
                  onClick={this.handleEmailLinkClick}
                  className="mx-sm-5 mx-3"
                  target="blank"
                >
                  <button
                    type="button"
                    className="p-1"
                  >
                    <img
                      src={image}
                      alt={key}
                    />
                  </button>
                </a>
              );
            }) : null}
          </div>
          {/* CHECK SPAM IF NOT RECEIVED */}
        </div>
        <p>
          Ja neesi saņēmis mūsu ziņu, lūdzu, pārbaudi savu
          {' '}
          <strong>spam</strong>
          {' '}
          mapi.
        </p>
      </div>
    );
  }
}

EmailSentPage.defaultProps = {
  match: {
    params: {
      email: '',
    },
  },
};

EmailSentPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      email: PropTypes.string,
    }),
  }),
};

export default EmailSentPage;
