import React, { Component } from 'react';

import slider0 from '../../../img/BANERIS-mobile.png';
import slider1 from '../../../img/slider_1_mobile.jpg';
import slider2 from '../../../img/slider_2_mobile.jpg';

class SliderMobile extends Component {
  constructor() {
    super();
    this.state = {
      images: [slider0, slider1, slider2],
      // images: ['https://cdn.discordapp.com/attachments/805469389390479404/971750858792505364/BANERIS-_mobile-.png'],
      // images:
      //   ['https://images.unsplash.com/photo-1498837167922-ddd27525d352?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1170&q=80',
      //     'https://images.unsplash.com/photo-1599599810769-bcde5a160d32?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1632&q=80',
      //     'https://cannabisnow.com/wp-content/uploads/2019/03/Snoop-Dogg-Cookbook-Review.jpg',
      //     'https://images.pexels.com/photos/1660030/pexels-photo-1660030.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'],

    };
  }

  render() {
    const {
      images,
    } = this.state;

    return (
      <div className="d-flex d-sm-none justify-content-center">
        <div
          id="carousel1"
          className="carousel slide"
          data-bs-ride="carousel1"
          data-bs-touch="true"
          data-bs-pause="hover"
          data-bs-interval="3500"
        >
          <div
            className="carousel-inner slider mt-2"
          >
            <div className="carousel-item active">
              <img
                src={images[0]}
                className="d-block w-100"
                alt="Slide 1"
                title="Slide 1"
              />
            </div>
            <div className="carousel-item">
              <img
                src={images[1]}
                className="d-block w-100"
                alt="Slide 2"
                title="Slide 2"
              />
            </div>
            <div className="carousel-item">
              <img
                src={images[2]}
                className="d-block w-100"
                alt="Slide 3"
                title="Slide 3"
              />
            </div>
          </div>
          <a className="carousel-control-prev" href="#carousel1" role="button" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="visually-hidden">Previous</span>
          </a>
          <a className="carousel-control-next" href="#carousel1" id="next-slide" role="button" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="visually-hidden">Next</span>
          </a>
        </div>
      </div>
    );
  }
}

export default SliderMobile;
