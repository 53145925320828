import React, { Component } from 'react';
// import { Redirect } from 'react-router-dom';
import List from './components/list';
import ListsFilter from './components/listsFilter';
import ShopFooter from './components/shopFooter';
import Floaty from '../../popUps/floaty';
import Alert from '../../components/alert';
import CenteredSpinner from '../../components/centeredSpinner';

function findBestProduct({ shop, products }) {
  let bestProduct = null;
  products.forEach((product) => {
    const productShop = product.shops.find((s) => String(s.shop) === String(shop._id));
    if (productShop) {
      if (!bestProduct || (productShop.lastPrice < bestProduct.lastPrice
        || (productShop.lastPrice === bestProduct.lastPrice
          && productShop.daysSinceUpdate < bestProduct.daysSinceUpdate
         && productShop.daysSinceUpdate > 3 && bestProduct.daysSinceUpdate > 3))) {
        bestProduct = product;
      }
    }
  });
  return bestProduct;
}

function calculateShopPrices({ shops, products }) {
  const shopsPricesObj = {};
  const shopsMissingProducts = {};
  const shopsHasSuggestions = {};

  Object.keys(products).forEach((key) => {
    const { qty, product } = products[key];
    const { shops: productShops } = product;
    productShops.forEach((productShop) => {
      const { bestProduct } = productShop;
      if (bestProduct) {
        const { shops: bestProductShops } = bestProduct;

        if (bestProduct?.isSuggestion) {
          shopsHasSuggestions[productShop._id] = true;
        }
        if (bestProduct.isAlternative || bestProduct.isSuggestion) {
          bestProductShops?.forEach((each) => {
            if (!shopsPricesObj[each.shop]) {
              shopsPricesObj[each.shop] = {};
              if (shopsPricesObj[each.shop][key] === undefined) {
                shopsPricesObj[each.shop][key] = 0;
              }
            }
            if (each.shop === productShop._id) {
              shopsPricesObj[each.shop][key] = each.lastPrice * qty;
            }
          });
        } else {
          if (!shopsPricesObj[productShop.shop]) {
            shopsPricesObj[productShop.shop] = {};
            if (shopsPricesObj[productShop.shop][key] === undefined) {
              shopsPricesObj[productShop.shop][key] = 0;
            }
          }
          shopsPricesObj[productShop.shop][key] = productShop.lastPrice * qty;
        }
      } else if (shopsMissingProducts[productShop._id] === undefined) {
        shopsMissingProducts[productShop._id] = 1;
      } else {
        shopsMissingProducts[productShop._id] += 1;
      }
    });
  });
  // salikt katram veikalam cenas.
  const updatedShops = shops.map((shop) => {
    const shopPrices = shopsPricesObj[shop._id];
    let shopPrice = 0;
    const missingProducts = shopsMissingProducts[shop._id] || 0;
    if (!shopPrices) {
      return {
        ...shop,
        price: shopPrice,
        missingProducts,
        hasSuggestion: 0,
      };
    }
    Object.keys(shopPrices).forEach((each) => {
      shopPrice += shopPrices[each] || 0;
    });

    const hasSuggestion = !!shopsHasSuggestions[shop._id];
    return {
      ...shop,
      hasSuggestion,
      // hasSuggestions: hasNoProducts ? 0 : shop.hasSuggestions,
      price: shopPrice,
      missingProducts,
    };
  });

  return updatedShops.sort((a, b) => {
    if (a.missingProducts === b.missingProducts) return a.price - b.price;
    return a.missingProducts > b.missingProducts;
  });
}

class Lists extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      message: '',
      notificationMsg: '',
      notificationStatus: '',
      lists: {},
      selectedList: {},
      selectedShop: null,
      newListLoading: false,
      qtyChanges: {},
      productsToDelete: {},
      changesCount: 0,
      timeout: null,
      updateLoading: false,
      // redirectToHome: false,
      listsPerUserExceeded: false,
      settings: {},
    };
    this.getLists = this.getLists.bind(this);
    this.onListSelect = this.onListSelect.bind(this);
    this.onShopSelect = this.onShopSelect.bind(this);
    this.addAlternative = this.addAlternative.bind(this);
    this.onQtyChange = this.onQtyChange.bind(this);
    this.addNewList = this.addNewList.bind(this);
    this.removeNewList = this.removeNewList.bind(this);
    this.onNewListSubmit = this.onNewListSubmit.bind(this);
    this.onMainProductDelete = this.onMainProductDelete.bind(this);
    this.onAltDelete = this.onAltDelete.bind(this);
    this.deleteList = this.deleteList.bind(this);
    this.editList = this.editList.bind(this);
    this.updateDeletedProducts = this.updateDeletedProducts.bind(this);
    this.updateChanges = this.updateChanges.bind(this);
    this.handleChanges = this.handleChanges.bind(this);
    this.updateAltProduct = this.updateAltProduct.bind(this);
    this.handleAltDelete = this.handleAltDelete.bind(this);
  }

  componentDidMount() {
    document.title = 'Produktu saraksts';

    this.getLists({});

    window.addEventListener('beforeunload', this.handleChanges);
  }

  componentWillUnmount() {
    this.handleChanges();
    window.removeEventListener('beforeunload', this.handleChanges);
  }

  handleBeforeUnload(e) {
    e.preventDefault();
    this.setState({ loading: false });
  }

  handleChanges() {
    this.setState({
      updateLoading: true,
    });
    const { qtyChanges, productsToDelete } = this.state;
    fetch('/api/lists/changes', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        qtyChanges,
        productsToDelete,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        const {
          status,
        } = res;
        if (status === 'success') {
          this.setState({
            changesCount: 0,
            updateLoading: false,
          });
        } else {
          this.setState({
            notificationMsg: 'Neizdevās saglabāt izmaiņas',
            notificationStatus: 'error',
          });
        }
      }).catch((ex) => {
        this.setState({
          notificationMsg: 'Neizdevās saglabāt izmaiņas',
          notificationStatus: 'error',
        });
        console.error('aaa', ex);
      });
  }

  handleAltDelete({ listId, mainProductId, altProductId }) {
    fetch('/api/lists/alternative', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        listId,
        mainProductId,
        altProductId,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        const {
          status, message,
        } = res;
        if (!status) {
          this.setState({
            notificationMsg: 'Neizdevās saglabāt izmaiņas',
            notificationStatus: 'error',
          });
          console.error(message);
        }
      }).catch((ex) => {
        this.setState({
          notificationMsg: 'Neizdevās saglabāt izmaiņas',
          notificationStatus: 'error',
        });
        console.error(ex);
      });
  }

  onMainProductDelete({ listId, mainProductId }) {
    const { lists, productsToDelete, changesCount } = this.state;
    delete lists[listId].products[mainProductId];

    const list = lists[listId];
    const { shops, products } = list;

    const updatedShops = calculateShopPrices({ shops, products });
    lists[listId].shops = updatedShops;

    if (!productsToDelete[listId]) {
      productsToDelete[listId] = [mainProductId];
    } else {
      productsToDelete[listId].push(mainProductId);
    }
    this.setState({
      lists,
      productsToDelete,
      changesCount: changesCount + 1,
    }, () => {
      this.updateChanges();
    });
  }

  onQtyChange({ listId, mainProductId, qty: newQty }) {
    const { lists, qtyChanges, changesCount } = this.state;
    lists[listId].products[mainProductId].qty = newQty;

    const list = lists[listId];
    const { shops, products } = list;
    const updatedShops = calculateShopPrices({ shops, products });

    lists[listId].shops = updatedShops;

    if (!qtyChanges[listId]) {
      qtyChanges[listId] = {};
    }

    if (!qtyChanges[listId][mainProductId]) {
      qtyChanges[listId][mainProductId] = {};
    }

    if (qtyChanges[listId]) {
      if (qtyChanges[listId][mainProductId]) {
        qtyChanges[listId][mainProductId] = newQty;
      }
    }

    const selectedList = lists[listId];
    this.setState({
      lists,
      selectedList,
      qtyChanges,
      changesCount: changesCount + 1,
    }, () => {
      this.updateChanges();
    });
  }

  onShopSelect(shop) {
    this.setState({
      selectedShop: shop,
    });
  }

  onAltDelete({ listId, mainProductId, altProductId }) {
    const { lists } = this.state;
    let { products, shops } = lists[listId];
    const {
      alternatives, product, qty, suggestions,
    } = products[mainProductId];

    // atrod produktu kuru update;
    // const listProduct = lists[listId].products[mainProductId];

    const updatedAlternatives = alternatives.filter((alt) => alt?._id !== altProductId);
    // listProduct.alternatives = alternatives;
    products[mainProductId].alternatives = updatedAlternatives;
    // const { product } = listProduct;
    const { shops: productShops } = product;
    // pa jaunam piemeklē best product
    const updatedProductShops = productShops.map((shop) => {
      const isMainProductInShop = productShops
        .some(({ shop: productShopId }) => String(productShopId) === String(shop._id));

      const hasSuggestions = suggestions?.some((suggestion) => {
        const { shops: suggestionShops } = suggestion;

        if (suggestionShops.some((each) => String(each.shop) === String(shop._id))) {
          return true;
        }
        return false;
      });

      let bestProduct = null;
      if (isMainProductInShop) {
        const bestProductShops = productShops.map((each) => ({
          name: each.name,
          lastPrice: each.lastPrice,
          lastPriceDate: each.lastPriceDate,
          shop: each.shop,
          isOutdated: each.isOutdated,
          daysSinceUpdate: each.daysSinceUpdate,
        }));
        bestProduct = {
          _id: product._id,
          name: product.name,
          image: product.image,
          shops: bestProductShops,
        };
      } else if (hasSuggestions) {
        bestProduct = findBestProduct({ shop, products: suggestions });
        if (bestProduct) {
          bestProduct.isSuggestion = true;
        }
      } else {
      // ja main produkts šajā veikalā neeksistē, tad meklēt altos
        bestProduct = findBestProduct({ shop, products: updatedAlternatives });
        if (bestProduct) {
          bestProduct.isAlternative = true;
        }
      }
      return { ...shop, bestProduct, qty };
    });
    product.shops = updatedProductShops;

    shops = calculateShopPrices({
      shops: lists[listId].shops,
      products: lists[listId].products,
    });

    this.setState({
      lists: {
        ...lists,
        [listId]: {
          ...lists[listId],
          products,
          shops,
        },
      },
      selectedList: {
        ...lists[listId],
        products,
        shops,
      },
    });

    this.handleAltDelete({ listId, mainProductId, altProductId });
  }

  onNewListSubmit({ e, name }) {
    e.preventDefault();
    this.setState({
      newListLoading: true,
    });
    fetch('/api/lists', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        const {
          status, message, data, listsPerUserExceeded,
        } = res;
        if (status) {
          let { lists } = this.state;
          delete lists.newList;
          // lists[data._id] = data;
          lists = Object.assign(lists, data);
          this.setState({
            lists,
            selectedList: data[Object.keys(data)[0]],
            newListLoading: false,
            listsPerUserExceeded,
          });
        } else {
          this.setState({
            newListLoading: false,
            notificationMsg: 'Neizdevās saglabāt izmaiņas',
            notificationStatus: 'error',
          });
          console.error(message);
        }
      }).catch((ex) => {
        this.setState({
          newListLoading: false,
          notificationMsg: 'Neizdevās saglabāt izmaiņas',
          notificationStatus: 'error',
        });
        console.error(ex);
      });
  }

  onListSelect({ value }) {
    const { lists } = this.state;
    const selectedList = lists[value];
    // šeit sakārto tā lai pirmais būtu veikals ar visvairāk produktiem un viszemāko cenu
    const [bestShop] = selectedList.shops.sort((a, b) => {
      if (a.missingProducts === b.missingProducts) return a.price - b.price;
      return a.missingProducts - b.missingProducts;
    });
    this.setState({
      selectedList,
      selectedShop: bestShop,
    });
  }

  getLists({ selectedListId }) {
    fetch('/api/lists/')
      .then((res) => res.json())
      .then((res) => {
        const {
          status, message, data, listsPerUserExceeded,
          settings,
        } = res;
        if (status) {
          let selectedList = data[Object.keys(data)[0]];
          if (selectedListId) {
            selectedList = data[selectedListId];
          }

          // šeit sakārto tā lai pirmais būtu veikals ar visvairāk produktiem un viszemāko cenu
          const [bestShop] = selectedList.shops.sort((a, b) => {
            if (a.missingProducts === b.missingProducts) return a.price - b.price;
            return a.missingProducts - b.missingProducts;
          });

          this.setState({
            lists: data,
            selectedList,
            selectedShop: bestShop,
            loading: false,
            listsPerUserExceeded,
            settings,
          });
        } else {
          this.setState({
            loading: false,
            message: 'Neizdevās ielādēt sarakstus',
            // redirectToHome: true,
          });
          console.log(message);
        }
      }).catch((ex) => {
        console.log(ex);
        this.setState({
          message: 'Neizdevās ielādēt sarakstus',
          loading: false,
        });
      });
  }

  updateAltProduct({ listId, listProductId, altProductId }) {
    fetch('/api/lists/alternative', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        listId,
        listProductId,
        altProductId,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        const {
          message, status,
        } = res;
        if (!status) {
          this.setState({
            notificationMsg: 'Neizdevās saglabāt izmaiņas',
            notificationStatus: 'error',
          });
          console.error(message);
        }
      }).catch((ex) => {
        this.setState({
          notificationMsg: 'Neizdevās saglabāt izmaiņas',
          notificationStatus: 'error',
        });
        console.error(ex);
      });
    this.setState({
      loading: false,
    });
  }

  updateChanges() {
    let { timeout, changesCount } = this.state;
    clearTimeout(timeout);
    // ja ir 20 izmaiņas sakrājušās, tad sūtam bez gaidīšanas
    if (changesCount > 20) {
      this.handleChanges();
    } else {
      timeout = setTimeout(() => {
        this.handleChanges();
      }, 10000);
    }
    this.setState({ timeout });
  }

  updateDeletedProducts() {
    const { productsToDelete } = this.state;
    fetch('/api/lists/product', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        productsToDelete,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        const {
          status, message, settings,
        } = res;
        if (status) {
          this.setState({
            settings,
          });
        }
        if (!status) {
          this.setState({
            notificationMsg: 'Neizdevās saglabāt izmaiņas',
            notificationStatus: 'error',
          });
          console.error(message);
        }
      }).catch((ex) => {
        this.setState({
          notificationMsg: 'Neizdevās saglabāt izmaiņas',
          notificationStatus: 'error',
        });
        console.error(ex);
      });
  }

  editList({ name, listId }) {
    const { lists } = this.state;
    lists[listId].name = name;

    this.setState({ lists });

    fetch(`/api/lists/${listId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        const {
          status, message,
        } = res;
        if (!status) {
          this.setState({
            notificationMsg: 'Neizdevās saglabāt izmaiņas',
            notificationStatus: 'error',
          });
          console.error(message);
        }
      }).catch((ex) => {
        this.setState({
          notificationMsg: 'Neizdevās saglabāt izmaiņas',
          notificationStatus: 'error',
        });
        console.error(ex);
      });
  }

  deleteList({ listId }) {
    const { lists } = this.state;
    delete lists[listId];
    const selectedList = lists[Object.keys(lists)[0]];
    this.setState({ lists, selectedList });

    fetch(`/api/lists/${listId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((res) => {
        const {
          status, message, listsPerUserExceeded,
        } = res;
        if (status) {
          this.setState({
            listsPerUserExceeded,
          });
        }
        if (!status) {
          this.setState({
            notificationMsg: 'Neizdevās saglabāt izmaiņas',
            notificationStatus: 'error',
          });
          console.error(message);
        }
      }).catch((ex) => {
        this.setState({
          notificationMsg: 'Neizdevās saglabāt izmaiņas',
          notificationStatus: 'error',
        });
        console.error(ex);
      });
  }

  addNewList() {
    const { lists } = this.state;
    lists.newList = { name: '', _id: Object.keys(lists).length };
    this.setState({ lists });
  }

  removeNewList() {
    const { lists } = this.state;
    delete lists.newList;
    this.setState({ lists });
  }

  addAlternative({ listId, listProductId, altProduct }) {
    const { lists } = this.state;
    let { products, shops } = lists[listId];
    const {
      alternatives, product, qty, suggestions,
    } = products[listProductId];

    if (!alternatives.find((each) => each._id === altProduct._id)) {
      alternatives.push(altProduct);
      products[listProductId].alternatives = alternatives;

      const { shops: productShops } = product;
      const updatedProductShops = productShops.map((shop) => {
        const isMainProductInShop = productShops
          .find(({ shop: productShopId }) => String(productShopId) === String(shop._id));
        const hasSuggestions = suggestions?.some((suggestion) => {
          const { shops: suggestionShops } = suggestion;

          if (suggestionShops.some((each) => String(each.shop) === String(shop._id))) {
            return true;
          }
          return false;
        });
        // šeit ja pie bestProduct piesien mainProduct izveidojas rekursija ar bestProduct;
        let bestProduct = null;
        if (isMainProductInShop) {
          const bestProductShops = productShops.map((each) => ({
            name: each.name,
            lastPrice: each.lastPrice,
            lastPriceDate: each.lastPriceDate,
            shop: each.shop,
            isOutdated: each.isOutdated,
            daysSinceUpdate: each.daysSinceUpdate,
          }));
          bestProduct = {
            _id: product._id,
            name: product.name,
            image: product.image,
            shops: bestProductShops,
          };
        } else if (hasSuggestions) {
          bestProduct = findBestProduct({ shop, products: suggestions });
          if (bestProduct) {
            bestProduct.isSuggestion = true;
          }
        } else {
          bestProduct = findBestProduct({ shop, products: alternatives });
          if (bestProduct) {
            bestProduct.isAlternative = true;
          }
        }

        return { ...shop, bestProduct, qty };
      });
      product.shops = updatedProductShops;
      shops = calculateShopPrices({
        shops: lists[listId].shops,
        products: lists[listId].products,
      });
    }
    this.setState({
      lists: {
        ...lists,
        [listId]: {
          ...lists[listId],
          products,
          shops,
        },
      },
      selectedList: {
        ...lists[listId],
        products,
        shops,
      },
    });
    this.updateAltProduct({ listId, listProductId, altProductId: altProduct._id });
  }

  render() {
    const {
      lists,
      loading,
      selectedList,
      selectedShop,
      newListLoading,
      changesCount,
      updateLoading,
      message,
      notificationMsg,
      notificationStatus,
      // redirectToHome,
      listsPerUserExceeded,
      settings,
    } = this.state;

    const hasMultipleLists = Object.keys(lists).filter((key) => key !== 'newList').length > 1;

    if (loading) return <CenteredSpinner />;

    // if (redirectToHome) return <Redirect to="/" />;

    if (message) {
      return (
        <Alert />
      );
    }
    return (
      <>
        {notificationMsg
          ? (
            <Floaty
              status={notificationStatus}
              message={notificationMsg}
              onClose={() => this.setState({ notificationMsg: '' })}
            />
          )
          : null}
        <div className="mt-3 mb-5 me-0 ms-0 d-flex">
          {changesCount ? (
            <>
              <button
                className="btn btn-success position-fixed end-0 bottom-0 mb-1 me-1 d-sm-block d-none fadeIn"
                type="button"
                onClick={this.handleChanges}
                disabled={updateLoading}
                style={{
                  zIndex: 9999,
                }}
              >
                {updateLoading ? (
                  <div className="mx-4 spinner-border spinner-border-sm" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <span className="small fw-bold">
                    saglabāt
                    <i className="ms-2 fa fa-check" aria-hidden="true" />
                  </span>
                )}
              </button>
              <div
                className="position-fixed end-0 bottom-0 mb-4 me-1 d-sm-none d-block"
                style={{
                  zIndex: 1,
                }}
              >
                <button
                  className="btn btn-success mb-5"
                  type="button"
                  onClick={this.handleChanges}
                  disabled={updateLoading}
                >
                  {updateLoading ? (
                    <div className="mx-4 spinner-border spinner-border-sm" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    <span className="small fw-bold">
                      saglabāt
                      <i className="ms-2 fa fa-check" aria-hidden="true" />
                    </span>
                  )}
                </button>
              </div>
            </>
          ) : null}
          <div className="container d-flex flex-lg-row flex-column">
            <div className="d-lg-block d-none me-4" style={{ width: 250 }}>
              <div className="col-12 border bg-white mt-5">
                <ListsFilter
                  data={lists}
                  renderKey="name"
                  onChange={this.onListSelect}
                  selectedItem={selectedList?._id}
                  addNewList={this.addNewList}
                  removeNewList={this.removeNewList}
                  onNewListSubmit={this.onNewListSubmit}
                  loading={newListLoading}
                  listsPerUserExceeded={listsPerUserExceeded}
                />
              </div>
            </div>
            <div className="accordion d-lg-none mb-4" id="accordionBasic">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button shadow-none bg-white text-dark collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    Saraksti
                  </button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionBasic">
                  <div className="accordion-body">
                    <div className="col-lg-3 justify-content-end d-flex">
                      <div className="col-xxl-6 col-lg-8 col-12 p-1">
                        <ListsFilter
                          data={lists}
                          renderKey="name"
                          onChange={this.onListSelect}
                          selectedItem={selectedList?._id}
                          addNewList={this.addNewList}
                          removeNewList={this.removeNewList}
                          onNewListSubmit={this.onNewListSubmit}
                          loading={newListLoading}
                          listsPerUserExceeded={listsPerUserExceeded}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ width: 'inherit' }}>
              <List
                list={selectedList}
                selectedShop={selectedShop}
                onShopSelect={this.onShopSelect}
                addAlternative={this.addAlternative}
                onQtyChange={this.onQtyChange}
                // onProductChange={this.onProductChange}
                onMainProductDelete={this.onMainProductDelete}
                onAltDelete={this.onAltDelete}
                deleteList={this.deleteList}
                editList={this.editList}
                settings={settings}
                hasMultipleLists={hasMultipleLists}
              />
            </div>
          </div>
          <ShopFooter
            shops={selectedList?.shops}
            selectedShop={selectedShop}
            onShopSelect={this.onShopSelect}
          />
        </div>
      </>
    );
  }
}

export default Lists;
