import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-moment';
import PropTypes from 'prop-types';
import Spinner from '../../../components/spinner';

class PriceChart extends Component {
  constructor(props) {
    super(props);
    const { period } = this.props;
    this.state = ({
      loading: true,
      period,
      disabledPeriods: null,
      autoSelectedPeriod: '',
      status: true,
      dataLoading: true,
    });
    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', () => {
    });
    this.getData();
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedShop, refreshPriceChart } = this.props;
    const { period } = this.state;
    if (period !== prevState.period) {
      this.getData();
    }

    if (selectedShop !== prevProps.selectedShop) {
      this.setState({
        autoSelectedPeriod: '',
        disabledPeriods: null,
        period: 'week',
        loading: true,
      }, () => {
        this.getData();
      });
    }

    if (refreshPriceChart && prevProps.refreshPriceChart !== refreshPriceChart) {
      this.setState({
        autoSelectedPeriod: '',
        disabledPeriods: null,
        period: 'week',
      }, () => {
        this.getData();
      });
    }
  }

  getData() {
    this.setState({
      dataLoading: true,
      message: '',
    });
    const {
      baseProductId, selectedShop, shopProductId, additionalShopProductId,
      minimalistic, hideSuggestions, additionalBaseProductId,
    } = this.props;
    const { period, disabledPeriods: savedDisabledPeriods } = this.state;
    fetch(`/api/history?baseProductId=${baseProductId}&shopProductId=${shopProductId || ''}&period=${period}&shopId=${selectedShop?._id || ''}&additionalShopProductId=${additionalShopProductId || ''}&minimalistic=${minimalistic || ''}&hideSuggestions=${hideSuggestions || ''}&additionalBaseProductId=${additionalBaseProductId || ''}`)
      .then((res) => res.json())
      .then((res) => {
        const {
          data, autoSelectedPeriod, disabledPeriods, status,
        } = res;
        if (status) {
          this.setState({
            data,
            loading: false,
            autoSelectedPeriod,
            disabledPeriods: !savedDisabledPeriods ? disabledPeriods : savedDisabledPeriods,
            dataLoading: false,
            status,
          });
        } else {
          this.setState({
            data: {},
            message: 'Šajā periodā nav pieejama cenu vēsture',
            loading: false,
            dataLoading: false,
            status,
          });
        }
      }).catch((ex) => {
        console.log(ex);
        this.setState({
          message: 'Neizdevās atrast cenu vēsturi',
          loading: false,
          dataLoading: false,
          status: false,
        });
      });
  }

  render() {
    const {
      period, loading,
      message,
      //  maxTicksLimit,
      // align,
      disabledPeriods,
      autoSelectedPeriod,
      status,
      dataLoading,
      data,
    } = this.state;

    let formattedData = data;

    // if dataLoading set datasets to grey
    if (dataLoading && data) {
      const formattedDatasets = data.datasets.map((dataset) => ({
        ...dataset,
        backgroundColor: 'rgba(0,0,0,0.1)',
        borderColor: 'rgba(0,0,0,0.1)',
      }));
      formattedData.datasets = formattedDatasets;
    } else if (data) {
      formattedData = data;
    }

    const { hideButtons, minimalistic } = this.props;

    const selectedPeriod = autoSelectedPeriod || period;

    const options = {
      responsive: true,
      maintainAspectRatio: true,
      elements: {
        point: {
          // radius: period === 'week' ? 3 : 0,
          radius: minimalistic ? 1 : 3,
        },
      },
      plugins: {
        legend: {
          display: !minimalistic,
          position: 'bottom',
        },
        tooltip: {
          // enabled: !minimalistic,
          mode: 'index',
          callbacks: {
            label(val) {
              return `${val.dataset.label} ${val?.formattedValue}€`;
            },
          },
        },
      },
      scales: {
        x: {
          type: 'time',
          time: {
            unit: period === 'all' ? 'week' : 'day',
            displayFormats: {
              day: '  DD/MM  ',
              year: 'DD/MM/YYYY',
            },
            tooltipFormat: 'DD/MM/YYYY',
          },
          ticks: {
            source: 'data',
            maxTicksLimit: period === 'all' ? 4 : 6,
            align: 'center',
            autoSkip: true,
            maxRotation: 0,
            minRotation: 0,
            display: !minimalistic,
          },
          grid: {
            display: !minimalistic,
          },

        },
        y: {
          grace: minimalistic ? '0%' : '5%',
          min: 0,
          ticks: {
            callback(val) {
              return `${val.toFixed(2)}€`;
            },
            maxTicksLimit: minimalistic ? 3 : 8,
            autoSkip: true,
            beginAtZero: true,
            // font: {
            // size: 12,
            // },
            // display: !minimalistic,
          },
          grid: {
            display: !minimalistic,
          },

        },
      },
    };

    if (loading) {
      return (
        <div className="d-flex justify-content-center my-5">
          <Spinner />
        </div>
      );
    }

    return (
      <>
        <div className={`${hideButtons || minimalistic ? 'd-none' : 'd-flex'} flex-wrap justify-content-center mb-2`}>
          <button
            type="button"
            className={`btn btn-outline-secondary mt-1 p-1 pe-2 ps-2 me-1 ms-1 mb-lg-0 mb-1 shadow-none ${selectedPeriod === 'week' ? 'fw-bold bg-secondary text-light border-2 border-dark' : ''}`}
            onClick={() => {
              this.setState({ period: 'week' });
            }}
            disabled={disabledPeriods?.week || selectedPeriod === 'week' || dataLoading}
          >
            nedēļa
          </button>
          <button
            type="button"
            className={`btn btn-outline-secondary mt-1 p-1 pe-2 ps-2 me-1 ms-1 mb-lg-0 mb-1 shadow-none ${selectedPeriod === 'month' ? 'fw-bold bg-secondary text-light border-2 border-dark' : ''}`}
            onClick={() => {
              this.setState({ period: 'month' });
            }}
            disabled={disabledPeriods?.month || selectedPeriod === 'month' || dataLoading}
          >
            mēnesis
          </button>
          <button
            type="button"
            className={`btn btn-outline-secondary mt-1 p-1 pe-2 ps-2 me-1 ms-1 mb-lg-0 mb-1 shadow-none ${selectedPeriod === '3months' ? 'fw-bold bg-secondary text-light border-2 border-dark' : ''}`}
            onClick={() => {
              this.setState({ period: '3months' });
            }}
            disabled={disabledPeriods?.['3months'] || selectedPeriod === '3months' || dataLoading}
          >
            3 mēneši
          </button>
          <button
            type="button"
            className={`btn btn-outline-secondary mt-1 p-1 pe-2 ps-2 me-1 ms-1 mb-lg-0 mb-1 shadow-none ${selectedPeriod === '6months' ? 'fw-bold bg-secondary text-light border-2 border-dark' : ''}`}
            onClick={() => {
              this.setState({ period: '6months' });
            }}
            disabled={disabledPeriods?.['6months'] || selectedPeriod === '6months' || dataLoading}
          >
            6 mēneši
          </button>
          <button
            type="button"
            className={`btn btn-outline-secondary mt-1 p-1 pe-2 ps-2 me-1 ms-1 mb-lg-0 mb-1 shadow-none ${selectedPeriod === 'all' ? 'fw-bold bg-secondary text-light border-2 border-dark' : ''}`}
            onClick={() => {
              this.setState({ period: 'all' });
            }}
            disabled={disabledPeriods?.all || selectedPeriod === 'all' || dataLoading}
          >
            Visa vēsture
          </button>
        </div>
        {!status ? (
          <div
            className="alert fade show mt-4 alert-warning"
            role="alert"
          >
            <span>
              {message}
            </span>
          </div>
        )
          : null}

        {status ? (
          <Line
            data={formattedData}
            options={options}
          />
        ) : null}
      </>
    );
  }
}

PriceChart.defaultProps = {
  hideButtons: false,
  selectedShop: null,
  shopProductId: '',
  refreshPriceChart: false,
  period: 'week',
  minimalistic: false,
  additionalShopProductId: '',
  hideSuggestions: false,
  additionalBaseProductId: '',
};

PriceChart.propTypes = {
  hideButtons: PropTypes.bool,
  selectedShop: PropTypes.object,
  baseProductId: PropTypes.string.isRequired,
  shopProductId: PropTypes.string,
  refreshPriceChart: PropTypes.bool,
  period: PropTypes.string,
  minimalistic: PropTypes.bool,
  hideSuggestions: PropTypes.bool,
  additionalShopProductId: PropTypes.string,
  additionalBaseProductId: PropTypes.string,
};

export default PriceChart;
