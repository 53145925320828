import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import CenteredSpinner from '../../components/centeredSpinner';

class UserActivation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      message: null,
      status: null,
      returnToHomePage: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    const { location } = this.props;

    const queryParameters = new URLSearchParams(location.search);
    const token = queryParameters.get('token');
    const {
      password,
      verifyPassword,
    } = this.state;

    if (password !== verifyPassword) {
      this.setState({
        message: 'Paroles nesakrīt',
        status: 'error',
      });
      return;
    }

    fetch('/api/auth/activate', {
      method: 'POST',
      body: JSON.stringify({
        token,
        password,
        verifyPassword,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((res) => {
        const {
          status, message,
          data,
        } = res;
        if (status === 'success') {
          const { user } = data;
          localStorage.setItem('username', user.username || '');
          localStorage.setItem('userId', user._id || '');
          this.setState({
            loading: false,
            message: 'konts gatavs',
            returnToHomePage: true,
          });
        } else {
          this.setState({
            loading: false,
            message,
            status,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          loading: false,
          message: 'Neizdevās jūs piereģistrēt :(',
        });
      });
  }

  render() {
    const {
      user, loading, message, status,
      returnToHomePage,
    } = this.state;

    if (returnToHomePage) {
      return <Redirect to="/" />;
    }

    if (loading) return <CenteredSpinner />;

    return (
      <div
        className="container d-flex justify-content-center align-items-center"
        style={{ height: '100vh' }}
      >
        {/* LOGIN PUSE */}
        <form
          style={{
            backgroundColor: '#d0d0d7',
            padding: '20px',
            borderRadius: '10px',
            width: '300px',
          }}
          onSubmit={this.onSubmit}
        >
          {message ? (
            <div
              className={`alert fade show mt-4  ${status === 'error' ? 'alert-danger' : 'alert-success'}`}
              role="alert"
            >
              <span
                className={!status ? 'shake' : ''}
              >
                {message}
              </span>
            </div>
          ) : null}
          {Object.keys(user).length ? (
            <div className="d-flex flex-column align-items-center">
              <span style={{ marginBottom: '15px', fontWeight: 'bold' }}>
                {user?.name}
              </span>
              <button
                type="button"
                onClick={this.onSignOut}
                className="bg-white border py-1 px-2 fw-bold rounded-3"
              >
                Iziet
              </button>
            </div>
          ) : (
            <div>
              <div className="d-flex flex-column align-items-center">
                <div className="form-floating mt-2">
                  <input
                    type="password"
                    name="password"
                    id="password"
                    className="form-control border shadow-none rounded-3 px-2 fs-5"
                    onChange={this.onChange}
                    autoComplete="new-password"
                    required
                    placeholder="parole:"
                  />
                  <label
                    htmlFor="password"
                    className="text-grey small"
                  >
                    parole:
                  </label>
                </div>
                <div className="form-floating mt-2">
                  <input
                    type="password"
                    name="verifyPassword"
                    id="verifyPassword"
                    className="form-control border shadow-none rounded-3 px-2 fs-5"
                    onChange={this.onChange}
                    required
                    placeholder="atkārtota parole:"
                  />
                  <label
                    htmlFor="verifyPassword"
                    className="text-grey small"
                  >
                    atkārtota parole:
                  </label>
                </div>
                <button
                  type="submit"
                  className="text-light border-0 px-3 py-2 fw-bold rounded-3 mt-3"
                  style={{
                    backgroundColor: '#ffa536',
                    fontSize: '14px',
                  }}
                >
                  IZVEIDOT KONTU
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    );
  }
}
UserActivation.propTypes = {
  location: PropTypes.object.isRequired,
};
export default UserActivation;
