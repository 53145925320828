import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

class SearchAllNavlink extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      isSelected,
      products,
      search,
      selectedShop,
      productCount,
      exceedsLimit,
      onClick,
    } = this.props;

    if (productCount === 1) return null;

    return (
      <div
        role="button"
        tabIndex={-1}
        onClick={onClick}
      >
        <NavLink
          tabIndex={0}
          role="button"
          className={`text-decoration-none ${!products || !products.length ? 'd-none' : 'show'}`}
          to={`/products/?search=${search}${selectedShop ? `&shop=${selectedShop?._id}` : ''}`}
          target="_blank"
          // rel="noopener noreferrer"
        >
          <div
            className={`rounded-1 m-1 p-1 ps-2 text-dark hover-light ${isSelected ? 'bg-darker' : ''}`}
          >
            Rādīt visus
            (
            {productCount}
            {exceedsLimit ? '+' : null}
            )
          </div>
        </NavLink>
      </div>
    );
  }
}

SearchAllNavlink.defaultProps = {
  search: '',
  selectedShop: null,
  isSelected: false,
  products: [],
  productCount: 0,
  onClick: () => {},
  exceedsLimit: false,
};

SearchAllNavlink.propTypes = {
  isSelected: PropTypes.bool,
  exceedsLimit: PropTypes.bool,
  products: PropTypes.array,
  search: PropTypes.string,
  selectedShop: PropTypes.object,
  productCount: PropTypes.number,
  onClick: PropTypes.func,
};

export default SearchAllNavlink;
