import React, { Component } from 'react';

class Alert extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <div className="d-flex justify-content-center mb-5 mt-3">
        <div
          className="alert fade show mt-4 alert-warning col-md-6 col-10 position-relative"
          role="alert"
        >
          <span>
            <strong>Sveiki!</strong>
            <i className="fa fa-gear fs-2 position-absolute end-0 top-0 pt-1 pe-1" />
            <br />
            Mēs ar prieku vēlamies jums paziņot,
            ka mūsu mājaslapa tiek uzlabota, lai
            tā būtu vēl labāka un ērtāk lietojama Jums.
            <br />
            Paldies jums par uzticību mūsu mājaslapai, un
            mēs esam priecīgi Jums piedāvāt šīs uzlabojumu iespējas!
          </span>
        </div>
      </div>
    );
  }
}

export default Alert;
