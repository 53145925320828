import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Product from './product';
import AddAltProductModal from './addAltProductModal';
import DeleteListModal from './deleteListModal';

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedListProduct: null,
      openAddAltProductModal: false,
      openDeleteListModal: false,
      editName: false,
      editedName: null,
    };
    this.toggleAddAltProductModal = this.toggleAddAltProductModal.bind(this);
    this.toggleDeleteListModal = this.toggleDeleteListModal.bind(this);
    this.updateProductName = this.updateProductName.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { list } = this.props;
    if (prevProps?.list?._id !== list?._id) {
      this.updateProductName();
    }
  }

  updateProductName() {
    const { list } = this.props;
    const { editedName } = list;
    this.setState({
      editedName: editedName || null,
    });
  }

  toggleAddAltProductModal({ listProduct }) {
    const { openAddAltProductModal } = this.state;
    if (listProduct) {
      this.setState({
        selectedListProduct: listProduct,
      });
    }
    this.setState({
      openAddAltProductModal: !openAddAltProductModal,
    });
  }

  toggleDeleteListModal() {
    const { openDeleteListModal } = this.state;
    this.setState({
      openDeleteListModal: !openDeleteListModal,
    });
  }

  render() {
    const {
      selectedListProduct, openAddAltProductModal, openDeleteListModal, editName,
      editedName,
    } = this.state;
    const {
      list, selectedShop,
      onShopSelect, onQtyChange,
      addAlternative,
      // onProductChange,
      onMainProductDelete,
      onAltDelete,
      deleteList,
      editList,
      settings,
      hasMultipleLists,
    } = this.props;

    const productsPerListLimit = settings?.['Lists.productsPerList']?.value;

    const allowListNameEdit = settings?.['Lists.allowListNameEdit']?.enabled;
    const allowDeleteList = settings?.['Lists.allowDeleteList']?.enabled;

    const { name, shops, products } = list;

    return (
      <div>
        <div>
          <form
            className="fs-2 pb-1 border-bottom button-on-hover d-flex"
            onSubmit={(e) => {
              e.preventDefault();
              editList({ name: editedName, listId: list._id });
              this.setState({ editName: false });
            }}
          >
            <input
              className={`rounded text-dark col-9 ${editName ? 'bg-white border-1' : 'bg-transparent border-0'}`}
              value={editName ? editedName : name}
              disabled={!editName}
              // onChange={({ target }) =>
              //  editList({ name: target.value, listId: list._id })}
              onChange={({ target }) => {
                this.setState({ editedName: target.value });
              }}
              required
              maxLength={26}
            />
            <div className="d-flex flex-column align-items-end col-3">
              <div className="d-flex">
                {!editName && allowListNameEdit ? (
                  <div
                    onClick={() => {
                      this.setState({ editName: !editName, editedName: name });
                    }}
                    role="button"
                    className="btn shadow-none hover-success p-1"
                    tabIndex={-1}
                    label="Mainīt nosaukumu"
                  >
                    <i
                      className="fa fa-pen"
                      aria-hidden="true"
                    />
                  </div>
                ) : null}
                {editName && allowListNameEdit ? (
                  <div
                    onClick={() => {
                      editList({ name: editedName, listId: list._id });
                      this.setState({ editName: !editName });
                    }}
                    role="button"
                    className="text-light-success fs-5 top-0 end-0 shadow-none p-1"
                    tabIndex={-1}
                    label="Apstiprināt"
                  >
                    <i
                      className="fa fa-check"
                      aria-hidden="true"
                    />
                  </div>
                ) : null}
                {hasMultipleLists && allowDeleteList ? (
                  <div
                    onClick={this.toggleDeleteListModal}
                    role="button"
                    className="btn hover-danger shadow-none p-1"
                    tabIndex={-1}
                    label="Dzēst sarakstu"
                  >
                    <i
                      className="fa fa-trash"
                      aria-hidden="true"
                    />
                  </div>
                ) : null}
              </div>
              <span className="fs-7 text-grey fw-sbold">
                {`${Object.keys(products)?.length} / ${productsPerListLimit}`}
              </span>
            </div>
          </form>
          <div className="d-none d-sm-flex mt-2 table-responsive">
            {shops?.length ? shops.map((shop) => (
              <div
                key={Math.random(1000)}
                role="button"
                tabIndex={0}
                onClick={() => onShopSelect(shop)}
                className={`hover-light d-flex justify-content-between flex-column px-2 rounded-3 position-relative pt-3 pb-2 ${selectedShop._id === shop._id ? 'bg-darker' : ''}`}
                style={{ maxWidth: 90 }}
              >
                <div
                  style={{ height: 40 }}
                  className="d-flex align-items-center"
                >
                  {shop.missingProducts ? (
                    <h4
                      className="text-danger position-absolute top-0 end-0 pe-1"
                      title={`nav ${shop?.missingProducts > 1 ? 'pieejami' : 'pieejams'} ${shop.missingProducts} ${shop?.missingProducts > 1 ? 'produkti' : 'produkts'}`}
                    >
                      !
                    </h4>
                  ) : null}
                  {shop.hasSuggestion ? (
                    <h4
                      className="text-danger position-absolute top-0 end-0 pe-1"
                      title="šeit ir Jūsu pievienots ieteikums"
                    >
                      !
                    </h4>
                  ) : null}
                  <img
                    src={shop.image}
                    alt={shop.name}
                    className="img-fluid"
                  />
                </div>
                <div className="d-flex justify-content-center mt-1">
                  <span>
                    {Number(shop.price)?.toFixed(2)}
                    €
                  </span>
                </div>
              </div>
            )) : null}
          </div>
          <div className="mt-2">
            {Object?.keys(products)?.length ? Object.keys(products).map((key) => {
              const product = products[key];
              return (
                <Product
                  key={product._id}
                  product={product}
                  selectedShop={selectedShop}
                  toggleAddAltProductModal={this.toggleAddAltProductModal}
                  listId={list._id}
                  onQtyChange={onQtyChange}
                  // onProductChange={onProductChange}
                  onMainProductDelete={onMainProductDelete}
                  onAltDelete={onAltDelete}
                />
              );
            }) : (
              <div>
                Nav produkti :(
              </div>
            )}
          </div>
        </div>
        {openAddAltProductModal ? (
          <AddAltProductModal
            product={selectedListProduct}
            toggleAddAltProductModal={this.toggleAddAltProductModal}
            addAlternative={addAlternative}
            isOpen={openAddAltProductModal}
            listId={list._id}
            selectedShop={selectedShop || {}}
          />
        ) : null}
        {openDeleteListModal ? (
          <DeleteListModal
            list={list}
            toggleDeleteListModal={this.toggleDeleteListModal}
            deleteList={deleteList}
            isOpen={openDeleteListModal}
            listId={list._id}
            selectedShop={selectedShop}
          />
        ) : null}
        <div
          className={`fade ${openDeleteListModal ? 'show modal-backdrop' : ''}`}
        />
      </div>
    );
  }
}
List.defaultProps = {
  hasMultipleLists: false,
  // productsPerListLimitExceeded: false,
};

List.propTypes = {
  list: PropTypes.object.isRequired,
  selectedShop: PropTypes.object.isRequired,
  onShopSelect: PropTypes.func.isRequired,
  onQtyChange: PropTypes.func.isRequired,
  addAlternative: PropTypes.func.isRequired,
  onMainProductDelete: PropTypes.func.isRequired,
  onAltDelete: PropTypes.func.isRequired,
  deleteList: PropTypes.func.isRequired,
  editList: PropTypes.func.isRequired,
  settings: PropTypes.object.isRequired,
  hasMultipleLists: PropTypes.bool,
};

export default List;
