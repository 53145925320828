import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import ProductCard from '../../../components/productCard';

class CategoriesHome extends Component {
  constructor() {
    super();
    this.state = ({});
  }

  render() {
    const {
      category, ads, banners, index,
      lists, handleProductChanges,
      qtyLoading,
    } = this.props;
    const { products, hidden } = category;
    return (
      <div>
        {banners && banners.map((banner) => {
          if (banner.column === index + 1) {
            return (
              <div
                className="mt-5 mb-5 pt-5 pb-5 banner"
                key={banner._id}
              >
                <img src={banner.image} alt="banner" title="banner" className="w-100" />
              </div>
            );
          }
          return null;
        })}
        {ads && ads.map((ad) => {
          if (ad?.column === index + 1 && ad?.homePage) {
            return (
              <div
                className="container d-flex justify-content-center"
                key={ad?._id}
              >
                <NavLink
                  to={ad?.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={ad?.image} alt="banner" title="banner" className="w-100" />

                </NavLink>
              </div>
            );
          }
          return null;
        })}
        {products?.length ? (
          <div className="container-lg d-flex flex-column mt-4 mb-4">
            <div className="d-flex justify-content-between pb-1 border-bottom align-items-end">
              <NavLink
                className="d-flex flex-wrap align-items-end justify-content-start mb-2 text-decoration-none font-niramit"
                to={`${!hidden ? `/category/${category?.slug}` : '#'}`}
              >
                {/* desktop */}
                {category?.image ? (
                  <img
                    className={`ms-3 me-2 ${hidden ? 'disabled' : null} d-none d-md-block`}
                    src={category.image}
                    alt={category.name}
                    title={category.name}
                    style={{
                      maxHeight: '60px',
                      maxWidth: '60px',
                    }}
                  />
                ) : null}
                {/* mobile */}
                {category?.image ? (
                  <img
                    className={`ms-3 me-2 ${hidden ? 'disabled' : null} d-block d-md-none`}
                    src={category.image}
                    alt={category.name}
                    title={category.name}
                    style={{
                      maxHeight: '40px',
                      maxWidth: '40px',
                    }}
                  />
                ) : null}
                {/* desktop */}
                <h2 className={`fs-4 text-dark-grey fw-bold mb-0 me-3 d-none d-md-block ${hidden ? 'disabled' : null}`}>
                  {category.name}
                </h2>
                {/* mobile */}
                <h2 className={`fs-5 text-dark-grey fw-bold mb-0 me-3 d-block d-md-none ${hidden ? 'disabled' : null}`}>
                  {category.name}
                </h2>
                {hidden ? (
                  <i className="ms-sm-3 text-orange fs-5">
                    (Drīzumā būs pieejams)
                  </i>
                ) : null}
              </NavLink>
              <NavLink
                to={`/category/${category.slug}`}
                className={`custom-link move-right font-niramit ${hidden ? 'd-none' : null}`}
              >
                Rādīt visus
              </NavLink>
            </div>
            <div className="row d-none d-xl-flex mt-4 row-cols-xxl-5 row-cols-lg-5 row-cols-sm-3 row-cols-2 pe-lg-0 ps-lg-0 ps-3 pe-3">
              {products.map((product, _index) => {
                if (_index < 5) {
                  return (
                    <ProductCard
                      key={product._id}
                      product={product}
                      redirect={!hidden}
                      hideShop
                      disabled={hidden}
                      lists={lists}
                      allowAddToList={!hidden}
                      handleProductChanges={handleProductChanges}
                      qtyLoading={qtyLoading}
                    />
                  );
                }
                return null;
              })}
            </div>
            <div
              className="row-cols-lg-4 row-cols-md-4 row-cols-sm-3 row-cols-2 d-flex flex-row d-xl-none mt-4 ps-md-3 pe-3"
              style={{ overflowX: 'scroll' }}
            >
              {products.map((product, _index) => {
                if (_index < 5) {
                  return (
                    <ProductCard
                      key={product._id}
                      product={product}
                      redirect={!hidden}
                      disabled={hidden}
                      hideShop
                      className="mx-1"
                      lists={lists}
                      allowAddToList={!hidden}
                      handleProductChanges={handleProductChanges}
                      qtyLoading={qtyLoading}
                    />
                  );
                }
                return null;
              })}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

CategoriesHome.defaultProps = {
  lists: {},
  handleProductChanges: () => {},
  qtyLoading: false,
};

CategoriesHome.propTypes = {
  category: PropTypes.object.isRequired,
  banners: PropTypes.array.isRequired,
  ads: PropTypes.array.isRequired,
  lists: PropTypes.object,
  index: PropTypes.number.isRequired,
  handleProductChanges: PropTypes.func,
  qtyLoading: PropTypes.bool,
};

export default CategoriesHome;
