/* eslint-disable react/prop-types */
/* eslint react/jsx-props-no-spreading: 0 */
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import CenteredSpinner from '../components/centeredSpinner';

export default function withAuth(ComponentToProtect) {
  return class extends Component {
    constructor() {
      super();
      this.state = {
        loading: true,
        redirect: false,
        permissions: {},
        settings: {},
        // isAdmin: false,
        user: {},
      };
      this.tokenCheckController = new AbortController();
    }

    componentDidMount() {
      this.tokenCheck();
    }

    componentDidUpdate(prevProps) {
      const { location } = this.props;
      const { pathname } = location;
      if (pathname !== prevProps.location.pathname) {
        this.tokenCheck();
      }
    }

    componentWillUnmount() {
      this.tokenCheckController.abort();
    }

    tokenCheck() {
      this.setState({ loading: true });
      const { location } = this.props;
      fetch('/api/auth/is-authorized', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          location,
        }),
        credentials: 'include',
        signal: this.tokenCheckController.signal,
      })
        .then((res) => res.json())
        .then((res) => {
          const {
            status, data, permissions, settings,
          } = res;
          // console.log({ status: res.status });
          if (status === 'success') {
            localStorage.setItem('image', data.image || '');
            localStorage.setItem('username', data.username || '');
            localStorage.setItem('userId', data._id || '');
            this.setState({
              permissions,
              settings,
              user: data,
              loading: false,
            });
          } else {
            localStorage.removeItem('image');
            localStorage.removeItem('username');
            localStorage.removeItem('userId');
            this.setState({
              loading: false,
              // redirect: true,
            });
          }
        })
        .catch((ex) => {
          if (!ex.name || ex.name !== 'AbortError') {
            console.error(ex);
            localStorage.removeItem('image');
            localStorage.removeItem('username');
            localStorage.removeItem('userId');
            this.setState({
              loading: false,
            });

            // REDIRECT IF NOT HOME PAGE
            if (location?.pathname && location?.pathname !== '/') {
              this.setState({
                redirect: true,
              });
            }
          }
        });
    }

    render() {
      const {
        loading,
        redirect, permissions, settings,
        user,
      } = this.state;

      const { location } = this.props;
      if (redirect) {
        return (
          <Redirect to={{
            pathname: '/',
            from: location.pathname,
          }}
          />
        );
      }
      if (loading) {
        return (
          <div className="vh-100">
            <CenteredSpinner />
          </div>
        );
      }
      return (
        <ComponentToProtect
          {...this.props}
          permissions={permissions}
          settings={settings}
          // isAdmin={isAdmin}
          user={user}
        />
      );
    }
  };
}
